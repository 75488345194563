import { auth, googleAuthProvider } from '../config/firebase';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import axios from 'axios';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../config/firebase';

const CALENDAR_API_URL = 'https://www.googleapis.com/calendar/v3/calendars/primary/events';

interface TokenData {
    accessToken: string;
    refreshToken?: string;
    expirationTime: number;
}

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const GOOGLE_CLIENT_SECRET = process.env.REACT_APP_GOOGLE_CLIENT_SECRET;

if (!GOOGLE_CLIENT_ID || !GOOGLE_CLIENT_SECRET) {
    throw new Error('Missing OAuth credentials in environment variables. Please ensure REACT_APP_GOOGLE_CLIENT_ID and REACT_APP_GOOGLE_CLIENT_SECRET are set.');
}

export class CalendarService {
    private static tokenData: TokenData | null = null;

    public static async getAccessToken(forceNew: boolean = false): Promise<string> {
        try {
            if (!forceNew && this.tokenData && this.tokenData.expirationTime > Date.now()) {
                return this.tokenData.accessToken;
            }

            const refreshToken = await this.getStoredRefreshToken();
            if (refreshToken) {
                try {
                    const response = await axios.post(
                        'https://oauth2.googleapis.com/token',
                        {
                            client_id: GOOGLE_CLIENT_ID,
                            client_secret: GOOGLE_CLIENT_SECRET,
                            refresh_token: refreshToken,
                            grant_type: 'refresh_token',
                        }
                    );

                    if (response.data.access_token) {
                        this.tokenData = {
                            accessToken: response.data.access_token,
                            refreshToken,
                            expirationTime: Date.now() + (response.data.expires_in * 1000)
                        };
                        return response.data.access_token;
                    }
                } catch (error) {
                    console.error('Error refreshing token:', error);
                }
            }

            const result = await signInWithPopup(auth, googleAuthProvider);
            const credential = GoogleAuthProvider.credentialFromResult(result);
            
            if (!credential?.accessToken) {
                throw new Error('No access token available');
            }

            const refreshTokenFromAuth = result.user.refreshToken;
            
            this.tokenData = {
                accessToken: credential.accessToken,
                refreshToken: refreshTokenFromAuth,
                expirationTime: Date.now() + 3500000
            };

            if (refreshTokenFromAuth) {
                await this.storeRefreshToken(refreshTokenFromAuth);
            }

            return credential.accessToken;
        } catch (error) {
            console.error('Error getting access token:', error);
            throw error;
        }
    }

    private static async makeRequest<T>(
        method: 'GET' | 'POST' | 'PATCH' | 'DELETE',
        endpoint: string,
        data?: any
    ): Promise<T> {
        try {
            const accessToken = await this.getAccessToken();
            
            const response = await axios({
                method,
                url: `${CALENDAR_API_URL}${endpoint}`,
                data,
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });

            return response.data;
        } catch (error: any) {
            if (error.response?.status === 401) {
                const accessToken = await this.getAccessToken(true);
                
                const response = await axios({
                    method,
                    url: `${CALENDAR_API_URL}${endpoint}`,
                    data,
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                });

                return response.data;
            }
            throw error;
        }
    }

    static async createEvent({
        title,
        description,
        startTime,
        endTime,
        attendees = [],
        timeZone = 'Asia/Kolkata'
    }: {
        title: string;
        description?: string;
        startTime: string;
        endTime: string;
        attendees?: string[];
        timeZone?: string;
    }) {
        const eventData = {
            summary: title,
            description,
            start: {
                dateTime: startTime,
                timeZone: 'Asia/Kolkata'
            },
            end: {
                dateTime: endTime,
                timeZone: 'Asia/Kolkata'
            },
            attendees: attendees.map(email => ({ email })),
            conferenceData: {
                createRequest: {
                    requestId: `${Date.now()}-${auth.currentUser?.uid}`,
                    conferenceSolutionKey: { type: 'hangoutsMeet' }
                }
            },
            reminders: {
                useDefault: true
            }
        };

        const response = await this.makeRequest<any>(
            'POST',
            '?conferenceDataVersion=1&sendUpdates=all',
            eventData
        );

        return {
            success: true,
            eventId: response.id,
            meetLink: response.hangoutLink,
            calendarLink: response.htmlLink,
            event: response
        };
    }

    static async updateEvent(eventId: string, updates: {
        title?: string;
        description?: string;
        startTime?: string;
        endTime?: string;
        attendees?: string[];
        timeZone?: string;
    }) {
        const updateData: any = {};
        if (updates.title) updateData.summary = updates.title;
        if (updates.description) updateData.description = updates.description;
        if (updates.startTime) {
            updateData.start = {
                dateTime: updates.startTime,
                timeZone: 'Asia/Kolkata'
            };
        }
        if (updates.endTime) {
            updateData.end = {
                dateTime: updates.endTime,
                timeZone: 'Asia/Kolkata'
            };
        }
        if (updates.attendees) {
            updateData.attendees = updates.attendees.map(email => ({ email }));
        }

        const response = await this.makeRequest<any>(
            'PATCH',
            `/${eventId}?sendUpdates=all`,
            updateData
        );

        return {
            success: true,
            eventId: response.id,
            meetLink: response.hangoutLink,
            calendarLink: response.htmlLink,
            event: response
        };
    }

    static async deleteEvent(eventId: string, notifyAttendees: boolean = true) {
        await this.makeRequest<void>(
            'DELETE',
            `/${eventId}?sendUpdates=${notifyAttendees ? 'all' : 'none'}`
        );

        return { success: true };
    }

    static async getEvent(eventId: string) {
        const response = await this.makeRequest<any>('GET', `/${eventId}`);

        return {
            success: true,
            event: response
        };
    }

    static async addAttendees(eventId: string, newAttendees: string[]) {
        const { event } = await this.getEvent(eventId);
        
        const existingEmails = new Set(event.attendees?.map((a: any) => a.email) || []);
        const uniqueNewAttendees = newAttendees.filter(email => !existingEmails.has(email));
        
        if (uniqueNewAttendees.length === 0) {
            return { success: true, event };
        }

        const updatedAttendees = [
            ...(event.attendees || []),
            ...uniqueNewAttendees.map(email => ({ email }))
        ];

        return await this.updateEvent(eventId, { 
            attendees: updatedAttendees.map(a => a.email) 
        });
    }

    private static async storeRefreshToken(refreshToken: string) {
        const currentUser = auth.currentUser;
        if (!currentUser) return;

        try {
            await setDoc(doc(db, 'userTokens', currentUser.uid), {
                refreshToken,
                lastUpdated: new Date().toISOString()
            }, { merge: true });
        } catch (error) {
            console.error('Error storing refresh token:', error);
        }
    }

    private static async getStoredRefreshToken(): Promise<string | null> {
        const currentUser = auth.currentUser;
        if (!currentUser) return null;

        try {
            const tokenDoc = await getDoc(doc(db, 'userTokens', currentUser.uid));
            if (tokenDoc.exists()) {
                return tokenDoc.data().refreshToken;
            }
        } catch (error) {
            console.error('Error getting stored refresh token:', error);
        }
        return null;
    }
}