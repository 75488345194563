import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from './components/common/ProtectedRoute';
import { DynamicTitle } from './components/DynamicTitle';
import Layout from './components/layout/Layout'; 
import LandingPage from './pages/LandingPage';
import FeaturesPage from './pages/FeaturesPage';
import HowItWorksPage from './pages/HowItWorksPage';
import PricingPage from './pages/PricingPage';
import ContactPage from './pages/ContactPage';
import { LoginPage } from './pages/auth/LoginPage';
import { SignupPage } from './pages/auth/SignupPage';
import { OrganizerDashboardPage } from './pages/organizer/DashboardPage';
import { CreateEventPage } from './pages/organizer/CreateEventPage';
import ResponsiveHomeLayout from './pages/attendee/ResponsiveHomeLayout';
import { EventDetailPage } from './pages/attendee/eventDetailPage/EventDetailPage';
import { OrganizerEventDetailsPage } from './pages/organizer/OrganizerEventDetailsPage'; 
import { ManageAttendeesPageWrapper } from './pages/organizer/ManageAttendeesPageWrapper'; 
import { FirstSignInScreen } from './pages/FirstSignInScreen';
import { UserProfilePage } from './pages/UserProfile'; 
import { EditProfilePage } from './pages/EditProfilePage'; 
import { RegistrationScreen } from './pages/attendee/RegistrationScreen'; 
import { RegistrationConfirmationScreen } from './pages/attendee/RegistrationConfirmationScreen'; 
import { SendUpdateScreen } from './components/organizer/SendUpdateScreen'; 
import FeedbackForm from './pages/FeedbackForm'; 
import PrivacyPolicyPage from './pages/PrivacyPolicyPage'; 
import { EditEventForm } from './components/organizer/EditEventForm';
import { OrganizerAccountPage } from './pages/organizer/OrganizerAccountPage';
import { OrganizerPaymentsPage } from './pages/organizer/OrganizerPaymentsPage';
import { TermsPage } from './pages/legal/TermsPage';
import { RefundPolicyPage } from './pages/legal/RefundPolicyPage';

const AppRouter: React.FC = () => {
  return (
    <>
      <DynamicTitle />
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<LandingPage />} />
          <Route path="/features" element={<FeaturesPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/attendee/home" element={<ResponsiveHomeLayout />} />
          <Route path="/event/:id" element={<EventDetailPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} /> {/* Add this new route */}
          <Route path="/how-it-works" element={<HowItWorksPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/contact" element={<ContactPage />} />

          {/* Protected Routes */}
          <Route path="/first-signin" element={<ProtectedRoute><FirstSignInScreen /></ProtectedRoute>} />
          <Route path="/organizer/dashboard" element={<ProtectedRoute><OrganizerDashboardPage /></ProtectedRoute>} />
          <Route path="/organizer/create-event" element={<ProtectedRoute><CreateEventPage /></ProtectedRoute>} />
          <Route path="/organizer/events/:id" element={<ProtectedRoute><OrganizerEventDetailsPage /></ProtectedRoute>} />
          <Route path="/organizer/events/:id/send-update" element={<ProtectedRoute><SendUpdateScreen /></ProtectedRoute>} /> {/* Add this new route */}
          <Route path="/organizer/events/:id/manage-attendees" element={<ProtectedRoute><ManageAttendeesPageWrapper /></ProtectedRoute>} />
          <Route path="/event/:id/register" element={<ProtectedRoute><RegistrationScreen /></ProtectedRoute>} />
          <Route path="/registration-confirmation" element={<ProtectedRoute><RegistrationConfirmationScreen /></ProtectedRoute>} />
          <Route path="/profile" element={<ProtectedRoute><UserProfilePage /></ProtectedRoute>} />
          <Route path="/profile/edit" element={<ProtectedRoute><EditProfilePage /></ProtectedRoute>} />
          <Route path="/feedback" element={<ProtectedRoute><FeedbackForm /></ProtectedRoute>} /> {/* Add this route */}
          <Route path="/organizer/events/:eventId/edit" element={<ProtectedRoute><EditEventForm /></ProtectedRoute>} />

          {/* New Organizer Payment Routes */}
          <Route 
            path="/organizer/account" 
            element={
              <ProtectedRoute requiresOrganizer>
                <OrganizerAccountPage />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/organizer/payments" 
            element={
              <ProtectedRoute requiresOrganizer>
                <OrganizerPaymentsPage />
              </ProtectedRoute>
            } 
          />

          {/* Add new legal routes */}
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/refund-policy" element={<RefundPolicyPage />} />
        </Route>
      </Routes>
    </>
  );
};

export default AppRouter;
