import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-transparent py-8">
      <div className="container mx-auto px-4">
        <div className="mt-8 pt-8 border-t border-[rgb(124,58,237)] text-center">
          <p className="text-sm text-black">
            © {currentYear} FEYNTECH OPTIMIZATION SOLUTIONS PRIVATE LIMITED. All rights reserved. |{" "}
            <Link to="/privacy-policy" className="text-[rgb(124,58,237)] hover:underline">
              Privacy Policy
            </Link>{" "}|{" "}
            <Link to="/terms" className="text-[rgb(124,58,237)] hover:underline">
              Terms
            </Link>{" "}|{" "}
            <Link to="/refund-policy" className="text-[rgb(124,58,237)] hover:underline">
              Refund Policy
            </Link>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
