import React from 'react';
import { Rating } from '../../../components/ui/Rating/Rating';

interface EventRatingProps {
  rating: number;
  totalRatings: number;
  hasAttended: boolean;
  onRate?: (rating: number) => Promise<void>;
}

export const EventRating: React.FC<EventRatingProps> = ({
  rating,
  totalRatings,
  hasAttended,
  onRate
}) => {
  return (
    <div className="bg-purple-50 p-6 rounded-lg">
      <h3 className="text-lg font-semibold mb-4">Event Rating</h3>
      <div className="flex items-center gap-4">
        <Rating 
          rating={rating} 
          size="lg"
          isInteractive={hasAttended}
          onRate={onRate}
          disabled={!hasAttended}
        />
        <div className="text-gray-600">
          <span className="font-semibold">{rating.toFixed(1)}</span>
          <span className="text-sm ml-1">
            ({totalRatings} {totalRatings === 1 ? 'rating' : 'ratings'})
          </span>
        </div>
      </div>
      {!hasAttended && (
        <p className="text-sm text-gray-500 mt-2 italic">
          You can rate this event after attending
        </p>
      )}
    </div>
  );
};
