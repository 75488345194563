// src/config/firebase.ts

import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, setPersistence, browserLocalPersistence, inMemoryPersistence, indexedDBLocalPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN?.split(',')[0],
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);

// Initialize services
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

// Update persistence settings to use the strongest available persistence
const initializeAuth = async () => {
  try {
    // Try to set the strongest persistence available
    // Order: indexedDB (strongest) -> localStorage -> inMemory (weakest)
    try {
      await setPersistence(auth, indexedDBLocalPersistence);
    } catch {
      try {
        await setPersistence(auth, browserLocalPersistence);
      } catch {
        await setPersistence(auth, inMemoryPersistence);
      }
    }
  } catch (error) {
    console.error('Error setting auth persistence:', error);
  }
};

// Initialize auth persistence
initializeAuth();

// Configure Google Auth Provider
export const googleAuthProvider = new GoogleAuthProvider();

// Add required scopes
googleAuthProvider.addScope('https://www.googleapis.com/auth/calendar.events');
googleAuthProvider.addScope('https://www.googleapis.com/auth/meetings.space.created');

// Set custom parameters for maximum persistence
googleAuthProvider.setCustomParameters({
    access_type: 'offline',         // Get refresh token
    prompt: 'consent select_account', // Force consent screen and account selection
    include_granted_scopes: 'true',  // Include previously granted scopes
});

// Set language for authentication
auth.useDeviceLanguage();

export { app };