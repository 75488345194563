import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/Card";
import { Button } from "../../components/ui/Button";
import { Badge } from "../../components/ui/Badge";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../components/ui/Table";
import { IndianRupeeIcon, DownloadIcon, FilterIcon } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { razorpayService } from '../../services/razorpayService';
import { useToast } from '../../hooks/useToast';
import { RazorpayPayment } from '../../types/razorpay';
import { formatCurrency } from '../../utils/formatters';

export const OrganizerPaymentsPage: React.FC = () => {
  const { user } = useAuth();
  const { showToast } = useToast();
  const [payments, setPayments] = useState<RazorpayPayment[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [pendingPayouts, setPendingPayouts] = useState(0);

  useEffect(() => {
    fetchPayments();
  }, []);

  const fetchPayments = async () => {
    try {
      setIsLoading(true);
      const earnings = await razorpayService.getEarnings(user!.uid);
      setPayments(earnings.payments);
      setTotalEarnings(earnings.totalEarnings);
      setPendingPayouts(earnings.pendingPayouts);
    } catch (error) {
      console.error('Error fetching payments:', error);
      showToast({
        title: "Error",
        description: "Failed to fetch payment details",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadStatement = async () => {
    try {
      // Implement download functionality
      await razorpayService.downloadStatement(user!.uid);
      showToast({
        title: "Success",
        description: "Statement downloaded successfully",
      });
    } catch (error) {
      showToast({
        title: "Error",
        description: "Failed to download statement",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 space-y-8">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold text-purple-800">Payments & Earnings</h1>
        <Button onClick={handleDownloadStatement}>
          <DownloadIcon className="w-4 h-4 mr-2" />
          Download Statement
        </Button>
      </div>

      <div className="grid gap-6 md:grid-cols-3">
        <Card>
          <CardHeader>
            <CardTitle>Total Earnings</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-3xl font-bold text-purple-600 flex items-center">
              <IndianRupeeIcon className="w-6 h-6 mr-2" />
              {formatCurrency(totalEarnings)}
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Pending Payouts</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-3xl font-bold text-orange-500 flex items-center">
              <IndianRupeeIcon className="w-6 h-6 mr-2" />
              {formatCurrency(pendingPayouts)}
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Platform Fee</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-3xl font-bold text-gray-600">
              5%
              <span className="text-sm font-normal ml-2">per transaction</span>
            </div>
          </CardContent>
        </Card>
      </div>

      <Card>
        <CardHeader>
          <div className="flex justify-between items-center">
            <CardTitle>Payment History</CardTitle>
            <Button variant="outline" size="sm">
              <FilterIcon className="w-4 h-4 mr-2" />
              Filter
            </Button>
          </div>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Date</TableHead>
                <TableHead>Event</TableHead>
                <TableHead>Amount</TableHead>
                <TableHead>Platform Fee</TableHead>
                <TableHead>Net Amount</TableHead>
                <TableHead>Status</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={6} className="text-center py-4">
                    Loading...
                  </TableCell>
                </TableRow>
              ) : payments.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} className="text-center py-4">
                    No payments found
                  </TableCell>
                </TableRow>
              ) : (
                payments.map((payment) => (
                  <TableRow key={payment.id}>
                    <TableCell>{payment.date}</TableCell>
                    <TableCell>{payment.eventName}</TableCell>
                    <TableCell>{formatCurrency(payment.amount)}</TableCell>
                    <TableCell>{formatCurrency(payment.platformFee)}</TableCell>
                    <TableCell>{formatCurrency(payment.netAmount)}</TableCell>
                    <TableCell>
                      <Badge
                        variant={
                            payment.status === 'completed' ? 'success' :
                            payment.status === 'pending' ? 'warning' : 'destructive'
                          }
                      >
                        {payment.status}
                      </Badge>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
};
