import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronRightIcon, ChevronLeftIcon, UserIcon, UsersIcon, CheckCircleIcon } from 'lucide-react';
import { Button } from '../components/ui/Button';
import { RadioGroup, RadioGroupItem } from '../components/ui/RadioGroup';
import { Label } from '../components/ui/Label';
import { Textarea } from '../components/ui/Textarea';
import { Card, CardContent } from '../components/ui/Card';

const questions = {
  organizer: [
    { type: 'rating', question: 'On a scale of 1 to 5, how satisfied are you with your experience using EventEase as an organizer?' },
    { type: 'choice', question: 'How easy was it to create an event using the app?', options: ['Very Easy', 'Easy', 'Neutral', 'Difficult', 'Very Difficult'] },
    { type: 'text', question: 'Do you have any suggestions to improve the event creation process?' },
    { type: 'choice', question: 'How effective are the event management tools provided?', options: ['Very Effective', 'Effective', 'Neutral', 'Ineffective', 'Very Ineffective'] },
    { type: 'text', question: 'What additional features would enhance your event management experience?' },
    { type: 'choice', question: 'How convenient is the organizer QR code for accessing management features?', options: ['Very Convenient', 'Convenient', 'Neutral', 'Inconvenient', 'Very Inconvenient'] },
    { type: 'choice', question: 'How easy is it to view and manage attendee registrations?', options: ['Very Easy', 'Easy', 'Neutral', 'Difficult', 'Very Difficult'] },
    { type: 'choice', question: 'How would you rate the check-in process using the app?', options: ['Very Efficient', 'Efficient', 'Neutral', 'Inefficient', 'Very Inefficient'] },
    { type: 'boolean', question: 'Did the offline check-in capability meet your needs?' },
    { type: 'text', question: 'If no, please explain:' },
    { type: 'choice', question: 'How satisfied are you with the payment processing features?', options: ['Very Satisfied', 'Satisfied', 'Neutral', 'Dissatisfied', 'Very Dissatisfied'] },
    { type: 'boolean', question: 'Was processing refunds straightforward?' },
    { type: 'text', question: 'If no, please describe any issues:' },
    { type: 'choice', question: 'Is the app interface user-friendly and easy to navigate?', options: ['Strongly Agree', 'Agree', 'Neutral', 'Disagree', 'Strongly Disagree'] },
    { type: 'boolean', question: 'Did you experience any problems or bugs while using the app?' },
    { type: 'text', question: 'If yes, please describe:' },
    { type: 'choice', question: 'How likely are you to recommend EventEase to other event organizers?', options: ['Definitely', 'Probably', 'Not Sure', 'Probably Not', 'Definitely Not'] },
    { type: 'text', question: 'Please share any other feedback or suggestions you have.' },
  ],
  guest: [
    { type: 'rating', question: 'On a scale of 1 to 5, how satisfied are you with your overall experience using EventEase?' },
    { type: 'choice', question: 'How easy was it to find events that interest you?', options: ['Very Easy', 'Easy', 'Neutral', 'Difficult', 'Very Difficult'] },
    { type: 'choice', question: 'How would you rate the event registration process?', options: ['Very Smooth', 'Smooth', 'Neutral', 'Complicated', 'Very Complicated'] },
    { type: 'text', question: 'Do you have any suggestions to improve the registration process?' },
    { type: 'choice', question: 'How satisfied are you with the payment process?', options: ['Very Satisfied', 'Satisfied', 'Neutral', 'Dissatisfied', 'Very Dissatisfied'] },
    { type: 'boolean', question: 'Were your preferred payment methods available?' },
    { type: 'text', question: 'If no, please specify which methods you\'d like to see:' },
    { type: 'choice', question: 'How convenient was using the digital ticket (QR code) for event entry?', options: ['Very Convenient', 'Convenient', 'Neutral', 'Inconvenient', 'Very Inconvenient'] },
    { type: 'choice', question: 'How would you rate your check-in experience at the event?', options: ['Very Quick', 'Quick', 'Neutral', 'Slow', 'Very Slow'] },
    { type: 'choice', question: 'Is the app interface user-friendly and easy to navigate?', options: ['Strongly Agree', 'Agree', 'Neutral', 'Disagree', 'Strongly Disagree'] },
    { type: 'text', question: 'Are there any features you\'d like to see added or improved?' },
    { type: 'boolean', question: 'Did you experience any problems or bugs while using the app?' },
    { type: 'text', question: 'If yes, please describe:' },
    { type: 'choice', question: 'How likely are you to recommend EventEase to others?', options: ['Definitely', 'Probably', 'Not Sure', 'Probably Not', 'Definitely Not'] },
    { type: 'text', question: 'Please share any other feedback or suggestions you have.' },
  ]
}

export default function FeedbackForm() {
  const [userType, setUserType] = useState<'organizer' | 'guest' | null>(null);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState<Record<string, string>>({});
  const [textInput, setTextInput] = useState('');
  const [showThankYou, setShowThankYou] = useState(false);
  const textInputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textInputRef.current) {
      textInputRef.current.focus();
    }
  }, [currentQuestion]);

  const handleUserTypeSelection = (type: 'organizer' | 'guest') => {
    setUserType(type);
    setCurrentQuestion(0);
  };

  const handleAnswer = (answer: string) => {
    setAnswers({ ...answers, [currentQuestion]: answer });
    if (currentQuestion < questions[userType!].length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setTextInput('');
    } else {
      setShowThankYou(true);
    }
  };

  const handleTextInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextInput(e.target.value);
  };

  const handleTextInputSubmit = () => {
    handleAnswer(textInput.trim());
  };

  const handlePrevious = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
      setTextInput(answers[currentQuestion - 1] || '');
    } else {
      setUserType(null);
    }
  };

  const renderQuestion = () => {
    const question = questions[userType!][currentQuestion];
    switch (question.type) {
      case 'rating':
        return (
          <div className="space-y-4">
            <p className="text-xl font-medium text-gray-800">{question.question}</p>
            <RadioGroup 
              value={answers[currentQuestion] || ''}
              onChange={handleAnswer} 
              className="flex justify-between"
            >
              {[1, 2, 3, 4, 5].map((value) => (
                <RadioGroupItem key={value} value={value.toString()}>
                  <Label
                    className="w-12 h-12 rounded-full flex items-center justify-center text-2xl cursor-pointer bg-white border-2 border-purple-200 hover:bg-purple-100 hover:border-purple-300 transition-colors"
                  >
                    {value}
                  </Label>
                </RadioGroupItem>
              ))}
            </RadioGroup>
          </div>
        );
      case 'choice':
        return (
          <div className="space-y-4">
            <p className="text-xl font-medium text-gray-800">{question.question}</p>
            <RadioGroup 
              value={answers[currentQuestion] || ''}
              onChange={handleAnswer} 
              className="space-y-2"
            >
              {question.options!.map((option, index) => (
                <RadioGroupItem key={index} value={option}>
                  <Label className="text-gray-700">{option}</Label>
                </RadioGroupItem>
              ))}
            </RadioGroup>
          </div>
        );
      case 'boolean':
        return (
          <div className="space-y-4">
            <p className="text-xl font-medium text-gray-800">{question.question}</p>
            <RadioGroup 
              value={answers[currentQuestion] || ''}
              onChange={handleAnswer} 
              className="flex space-x-4"
            >
              <RadioGroupItem value="Yes">
                <Label className="text-gray-700">Yes</Label>
              </RadioGroupItem>
              <RadioGroupItem value="No">
                <Label className="text-gray-700">No</Label>
              </RadioGroupItem>
            </RadioGroup>
          </div>
        );
      case 'text':
        return (
          <div className="space-y-4">
            <p className="text-xl font-medium text-gray-800">{question.question}</p>
            <Textarea
              ref={textInputRef}
              placeholder="Type your answer here..."
              value={textInput}
              onChange={handleTextInputChange}
              className="w-full p-2 border-2 border-purple-200 rounded-md focus:border-purple-400 focus:ring focus:ring-purple-200 focus:ring-opacity-50"
            />
          </div>
        );
      default:
        return null;
    }
  };

  const backgroundStyle = {
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2V6h4V4H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
    backgroundColor: '#f0e6ff',
  };

  if (!userType) {
    return (
      <div className="min-h-screen flex items-center justify-center" style={backgroundStyle}>
        <Card className="w-full max-w-4xl bg-white bg-opacity-95 shadow-2xl">
          <CardContent className="p-12">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
              <div className="space-y-6">
                <h1 className="text-4xl font-bold text-purple-800 leading-tight">
                  EventEase <br /> Feedback
                </h1>
                <p className="text-xl text-gray-600">
                  Your opinion is valuable to us. Help shape the future of EventEase by sharing your experience.
                </p>
              </div>
              <div className="space-y-6">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">Select your user type:</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <Button
                    onClick={() => handleUserTypeSelection('organizer')}
                    className="py-6 flex flex-col items-center justify-center bg-purple-600 hover:bg-purple-700 text-white transition-all duration-300 ease-in-out transform hover:scale-105"
                  >
                    <UserIcon className="w-12 h-12 mb-2" />
                    <span className="text-lg font-medium">Organizer</span>
                  </Button>
                  <Button
                    onClick={() => handleUserTypeSelection('guest')}
                    className="py-6 flex flex-col items-center justify-center bg-indigo-600 hover:bg-indigo-700 text-white transition-all duration-300 ease-in-out transform hover:scale-105"
                  >
                    <UsersIcon className="w-12 h-12 mb-2" />
                    <span className="text-lg font-medium">Guest</span>
                  </Button>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

  if (showThankYou) {
    return (
      <div className="min-h-screen flex items-center justify-center" style={backgroundStyle}>
        <Card className="w-full max-w-md bg-white bg-opacity-95 shadow-2xl">
          <CardContent className="p-8 text-center">
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{
                type: "spring",
                stiffness: 260,
                damping: 20
              }}
            >
              <CheckCircleIcon className="w-24 h-24 mx-auto mb-6 text-green-500" />
            </motion.div>
            <motion.h2
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              className="text-3xl font-bold mb-4 text-purple-800"
            >
              Thank You!
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
              className="text-lg text-gray-600"
            >
              We appreciate your feedback. It helps us improve EventEase for everyone.
            </motion.p>
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center" style={backgroundStyle}>
      <AnimatePresence mode="wait">
        <motion.div
          key={currentQuestion}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          transition={{ duration: 0.5 }}
          className="w-full max-w-md"
        >
          <Card className="bg-white bg-opacity-95 shadow-2xl">
            <CardContent className="p-8">
              <div className="mb-8">
                <div className="h-2 w-full bg-purple-100 rounded-full">
                  <div
                    className="h-2 bg-purple-600 rounded-full transition-all duration-500 ease-out"
                    style={{ width: `${((currentQuestion + 1) / questions[userType].length) * 100}%` }}
                  ></div>
                </div>
              </div>
              {renderQuestion()}
              <div className="mt-8 flex justify-between">
                <Button onClick={handlePrevious} variant="outline" className="text-purple-600 border-purple-600 hover:bg-purple-50">
                  <ChevronLeftIcon className="mr-2 h-4 w-4" /> Back
                </Button>
                {questions[userType][currentQuestion].type === 'text' ? (
                  <Button onClick={handleTextInputSubmit} className="bg-purple-600 hover:bg-purple-700 text-white">
                    Next <ChevronRightIcon className="ml-2 h-4 w-4" />
                  </Button>
                ) : currentQuestion < questions[userType].length - 1 ? (
                  <Button onClick={() => setCurrentQuestion(currentQuestion + 1)} className="bg-purple-600 hover:bg-purple-700 text-white">
                    Next <ChevronRightIcon className="ml-2 h-4 w-4" />
                  </Button>
                ) : (
                  <Button onClick={() => setShowThankYou(true)} className="bg-purple-600 hover:bg-purple-700 text-white">Submit</Button>
                )}
              </div>
            </CardContent>
          </Card>
        </motion.div>
      </AnimatePresence>
    </div>
  );
}