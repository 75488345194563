import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/Card";

export const TermsPage = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <Card>
        <CardHeader>
          <CardTitle>Terms and Conditions</CardTitle>
        </CardHeader>
        <CardContent className="prose max-w-none">
          <h2>1. Introduction and Acceptance</h2>
          <p>Welcome to EventEase ("Platform"), operated by FEYNTECH OPTIMIZATION SOLUTIONS PRIVATE LIMITED ("Company," "we," "our," or "us"). By accessing or using the Platform, including during testing phases, you agree to be bound by these Terms and Conditions ("Terms").</p>

          <h2>2. Testing Environment</h2>
          <h3>2.1 Nature of Testing</h3>
          <ul>
            <li>The Platform may be in various stages of testing, including alpha, beta, or production testing</li>
            <li>Features and functionality may be incomplete or subject to change</li>
            <li>Users acknowledge that they are accessing a testing environment</li>
          </ul>

          <h3>2.2 Test Data</h3>
          <ul>
            <li>Users agree to use only test data during testing phases</li>
            <li>No sensitive or production data should be entered into the system</li>
            <li>Test accounts and events created during testing may be deleted without notice</li>
          </ul>

          <h3>2.3 Feedback and Reporting</h3>
          <ul>
            <li>Users are encouraged to report bugs, issues, or security vulnerabilities</li>
            <li>All feedback becomes the property of the Company</li>
            <li>Users agree not to publicly disclose security vulnerabilities before notifying the Company</li>
          </ul>

          <h2>3. User Responsibilities</h2>
          <h3>3.1 Account Security</h3>
          <ul>
            <li>Users must maintain the confidentiality of their account credentials</li>
            <li>Immediate notification is required for any unauthorized account access</li>
            <li>Strong passwords must be used and regularly updated</li>
          </ul>

          <h3>3.2 Prohibited Activities</h3>
          <p>Users agree not to:</p>
          <ul>
            <li>Attempt to bypass security measures or access unauthorized areas</li>
            <li>Introduce malware, viruses, or harmful code</li>
            <li>Conduct performance testing without explicit permission</li>
            <li>Scrape or harvest data from the Platform</li>
            <li>Share access credentials with unauthorized parties</li>
            <li>Manipulate or forge any authorization header or identifier</li>
          </ul>

          <h2>4. Platform Protection</h2>
          <h3>4.1 Rate Limiting</h3>
          <ul>
            <li>The Platform implements rate limiting to prevent abuse</li>
            <li>Excessive requests may result in temporary or permanent blocking</li>
            <li>Users agree not to circumvent rate limiting mechanisms</li>
          </ul>

          <h2>5. Intellectual Property</h2>
          <h3>5.1 Ownership</h3>
          <ul>
            <li>All Platform content and code remain the property of the Company</li>
            <li>Users retain ownership of their feedback and suggestions</li>
            <li>Test data may be used for Platform improvement</li>
          </ul>

          <h2>6. Liability and Indemnification</h2>
          <h3>6.1 Disclaimer</h3>
          <ul>
            <li>The Platform is provided "AS IS" during testing</li>
            <li>No warranties or guarantees of performance are made</li>
            <li>The Company is not liable for data loss or system unavailability</li>
          </ul>

          <h2>7. Data Privacy and Security</h2>
          <h3>7.1 Data Protection</h3>
          <ul>
            <li>Industry-standard encryption is used</li>
            <li>Regular security audits are conducted</li>
            <li>Access to test data is restricted and logged</li>
          </ul>

          <h2>8. Governing Law</h2>
          <ul>
            <li>These Terms are governed by Indian law</li>
            <li>Disputes will be resolved in courts of Mumbai, Maharashtra</li>
            <li>Users agree to jurisdiction and venue in Mumbai</li>
          </ul>

          <h2>9. Contact Information</h2>
          <p>For questions or concerns about these Terms, contact:</p>
          <ul>
            <li>Email: support@eventease.com</li>
            <li>Address: FEYNTECH OPTIMIZATION SOLUTIONS PRIVATE LIMITED</li>
            <li>Phone: +91 [Company Phone]</li>
          </ul>

          <p className="mt-4">Last Updated: {new Date().toLocaleDateString()}</p>

          <p className="mt-4">By using the Platform, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.</p>
        </CardContent>
      </Card>
    </div>
  );
};
