import { Event } from '../types/Event';
import { db } from '../config/firebase';
import {
  doc,
  setDoc,
  Timestamp,
  collection,
  runTransaction,
  arrayUnion,
  increment,
  getDoc,
  getDocs,
  query,
  where,
  limit,
} from 'firebase/firestore';
import { FirebaseError } from 'firebase/app';
import { addEventToUserRegistrations } from './userService';
import { Attendee } from '../types/Attendee';
import { User } from '../types/User';
import { EventRegistration } from '../types/EventRegistration';

export const addEvent = async (event: Event): Promise<string> => {
  try {
    const eventsRef = doc(db, 'events', event.id);
    
    // Prepare the event data for Firestore
    const eventData: Event = {
      ...event,
      date: event.date, // Already a Timestamp
      fromDate: event.fromDate, // Already a Timestamp or undefined
      toDate: event.toDate, // Already a Timestamp or undefined
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now()
    };

    // Log the event data before adding to Firestore
    console.log('Event data to be added:', JSON.stringify(eventData, null, 2));

    // Add the event to Firestore using setDoc
    await setDoc(eventsRef, eventData);
    
    console.log('Event added with ID:', event.id);
    return event.id;
  } catch (error) {
    if (error instanceof FirebaseError) {
      console.error('Firebase Error Code:', error.code);
      console.error('Firebase Error Message:', error.message);
    } else {
      console.error('Unknown error:', error);
    }
    throw error;
  }
};

export const addEventAndUpdateUser = async (event: Event, userId: string) => {
  const eventRef = doc(collection(db, 'events'), event.id);
  const userRef = doc(db, 'users', userId);

  try {
    await runTransaction(db, async (transaction) => {
      transaction.set(eventRef, event);
      transaction.update(userRef, {
        eventsCreated: arrayUnion(event.id)
      });
    });

    return event.id;
  } catch (error) {
    console.error('Error in addEventAndUpdateUser:', error);
    throw error;
  }
};

export const registerUserForEvent = async (eventId: string, userId: string): Promise<void> => {
  const eventRef = doc(db, 'events', eventId);

  try {
    await runTransaction(db, async (transaction) => {
      const eventDoc = await transaction.get(eventRef);
      if (!eventDoc.exists()) {
        throw new Error("Event not found.");
      }

      const eventData = eventDoc.data() as Event;
      if (eventData.attendeeCount >= eventData.maxCapacity) {
        throw new Error("Event is at full capacity.");
      }

      if (eventData.attendees.some((attendee) => attendee.userId === userId)) {
        throw new Error("User already registered for this event.");
      }

      transaction.update(eventRef, {
        attendees: arrayUnion({ userId, registrationType: 'regular', checkedIn: false }),
        attendeeCount: increment(1),
        updatedAt: Timestamp.now(),
      });

      const eventRegistration: EventRegistration = {
        eventId,
        userId,
        registrationDate: Timestamp.now(),
        status: 'confirmed',
      };

      const eventRegistrationRef = doc(collection(db, 'eventRegistrations'));
      transaction.set(eventRegistrationRef, eventRegistration);
    });

    await addEventToUserRegistrations(userId, eventId);

    console.log(`User ${userId} successfully registered for event ${eventId}`);
  } catch (error) {
    throw error;
  }
};

export const getEventAttendees = async (eventId: string): Promise<Attendee[]> => {
  try {
    const eventRef = doc(db, 'events', eventId);
    const eventSnap = await getDoc(eventRef);

    if (!eventSnap.exists()) {
      throw new Error('Event not found');
    }

    const eventData = eventSnap.data() as Event;
    const attendeeEntries = eventData.attendees || [];

    const attendeePromises = attendeeEntries.map(async (attendeeEntry) => {
      const userRef = doc(db, 'users', attendeeEntry.userId);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const userData = userSnap.data() as User;
        return {
          ...userData,
          id: attendeeEntry.userId,
          registrationType: attendeeEntry.registrationType,
          checkedIn: attendeeEntry.checkedIn || false,
        } as Attendee;
      } else {
        return null;
      }
    });

    const attendees = await Promise.all(attendeePromises);
    return attendees.filter((attendee): attendee is Attendee => attendee !== null);
  } catch (error) {
    console.error('Error fetching attendees:', error);
    throw error;
  }
};

export const updateAttendeeCheckInStatus = async (
  eventId: string,
  userId: string
): Promise<void> => {
  const eventRef = doc(db, 'events', eventId);
  const eventRegistrationsRef = collection(db, 'eventRegistrations');

  try {
    await runTransaction(db, async (transaction) => {
      const eventDoc = await transaction.get(eventRef);

      if (!eventDoc.exists()) {
        throw new Error('Event not found');
      }

      const eventData = eventDoc.data() as Event;
      const attendees = eventData.attendees || [];

      const attendeeIndex = attendees.findIndex((attendee) => attendee.userId === userId);

      if (attendeeIndex === -1) {
        throw new Error('Attendee not found');
      }

      // Toggle the checkedIn status
      const newCheckedInStatus = !attendees[attendeeIndex].checkedIn;
      attendees[attendeeIndex].checkedIn = newCheckedInStatus;

      transaction.update(eventRef, {
        attendees: attendees,
        updatedAt: Timestamp.now(),
      });

      const eventRegistrationQuery = query(
        eventRegistrationsRef,
        where('eventId', '==', eventId),
        where('userId', '==', userId),
        limit(1)
      );

      const eventRegistrationSnapshot = await getDocs(eventRegistrationQuery);

      if (!eventRegistrationSnapshot.empty) {
        const eventRegistrationDoc = eventRegistrationSnapshot.docs[0];
        transaction.update(eventRegistrationDoc.ref, {
          checkedIn: newCheckedInStatus,
          checkInTime: newCheckedInStatus ? Timestamp.now() : null,
        });
      }
    });

    console.log(`Check-in status updated for user ${userId} in event ${eventId}`);
  } catch (error) {
    console.error('Error updating check-in status:', error);
    throw error;
  }
};