import React from 'react';
import { Label } from "./ui/Label";
import { Input } from "./ui/Input";
import { Event } from '../types/Event';
import { format } from 'date-fns';

interface EventDailySchedulesProps {
  event: Partial<Event>;
  onChange: (field: string, value: any) => void;
}

export const EventDailySchedules: React.FC<EventDailySchedulesProps> = ({ event, onChange }) => {
  if (event.isSingleDay) return null;

  const isLongEvent = event.fromDate && event.toDate && 
    Math.ceil((event.toDate.toDate().getTime() - event.fromDate.toDate().getTime()) / (1000 * 60 * 60 * 24)) > 7;

  if (isLongEvent) {
    return (
      <div className="space-y-4">
        <Label>Daily Schedule</Label>
        <div className="flex items-center gap-4 p-2 border rounded">
          <div className="flex gap-2 items-center">
            <Input
              type="time"
              value={event.startTime || '09:00'}
              onChange={(e) => onChange('startTime', e.target.value)}
              className="w-32"
            />
            <span>to</span>
            <Input
              type="time"
              value={event.endTime || '17:00'}
              onChange={(e) => onChange('endTime', e.target.value)}
              className="w-32"
            />
          </div>
        </div>
      </div>
    );
  }

  if (!event.dailySchedules) return null;

  return (
    <div className="space-y-4">
      <Label>Daily Schedules</Label>
      {event.dailySchedules.map((schedule, index) => (
        <div key={index} className="flex items-center gap-4 p-2 border rounded">
          <span className="min-w-[100px]">
            {format(schedule.date.toDate(), 'MMM dd, yyyy')}
          </span>
          <div className="flex gap-2 items-center">
            <Input
              type="time"
              value={schedule.startTime}
              onChange={(e) => {
                const updatedSchedules = [...event.dailySchedules!];
                updatedSchedules[index] = {
                  ...updatedSchedules[index],
                  startTime: e.target.value
                };
                onChange('dailySchedules', updatedSchedules);
              }}
              className="w-32"
            />
            <span>to</span>
            <Input
              type="time"
              value={schedule.endTime}
              onChange={(e) => {
                const updatedSchedules = [...event.dailySchedules!];
                updatedSchedules[index] = {
                  ...updatedSchedules[index],
                  endTime: e.target.value
                };
                onChange('dailySchedules', updatedSchedules);
              }}
              className="w-32"
            />
          </div>
        </div>
      ))}
    </div>
  );
}; 