import React, { useState } from 'react';

interface AccordionProps {
  type: 'single' | 'multiple';
  collapsible?: boolean;
  className?: string;
  children: React.ReactNode;
}

export function Accordion({ children, className = '' }: AccordionProps) {
  return (
    <div className={`divide-y divide-gray-200 ${className}`}>
      {children}
    </div>
  );
}

export function AccordionItem({ children, value }: { value: string; children: React.ReactNode }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="py-2">
      {React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child as React.ReactElement<any>, {
            isOpen,
            setIsOpen
          });
        }
        return child;
      })}
    </div>
  );
}

export function AccordionTrigger({ children, onClick, ...props }: any) {
  return (
    <button
      className="flex justify-between items-center w-full py-2 text-left"
      onClick={() => {
        props.setIsOpen(!props.isOpen);
        onClick?.();
      }}
    >
      {children}
      <span className={`transform transition-transform ${props.isOpen ? 'rotate-180' : ''}`}>
        ▼
      </span>
    </button>
  );
}

export function AccordionContent({ children, ...props }: any) {
  if (!props.isOpen) return null;

  return (
    <div className="py-2 px-4">
      {children}
    </div>
  );
}
