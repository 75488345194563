import React, { useState } from 'react';
import { Card, CardContent } from "../../../components/ui/Card";
import { CalendarIcon } from "lucide-react";
import { Event, DailySchedule } from '../../../types/Event';
import { formatDate, formatDay } from '../../../utils/dateUtils';
import { format } from 'date-fns';

interface EventDateInfoProps {
  event: Event;
}

const formatTime = (time: string): string => {
  // Handle empty or invalid time strings
  if (!time || !time.includes(':')) return time;

  const [hours, minutes] = time.split(':');
  const hour = parseInt(hours, 10);
  
  if (isNaN(hour)) return time;
  
  const period = hour >= 12 ? 'PM' : 'AM';
  const displayHour = hour % 12 || 12; // Convert 0 to 12 for 12 AM
  
  return `${displayHour}:${minutes} ${period}`;
};

export const EventDateInfo: React.FC<EventDateInfoProps> = ({ event }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  let dateContent: React.ReactNode = null;

  console.log('Event data:', {
    isRepeatedEvent: event.isRepeatedEvent,
    repeatedDaysOfWeek: event.repeatedDaysOfWeek,
    isSingleDay: event.isSingleDay,
    date: event.date,
    fromDate: event.fromDate,
    toDate: event.toDate,
    dailySchedules: event.dailySchedules
  });

  if (event.isSingleDay && event.date) {
    const eventDate = event.date.toDate();
    dateContent = (
      <div>
        <p className="text-sm text-gray-600">
          {formatDay(eventDate)}, {formatDate(eventDate)}
        </p>
        {event.startTime && event.endTime && (
          <p className="text-sm text-gray-600 mt-2">
            Time: {formatTime(event.startTime)} - {formatTime(event.endTime)}
          </p>
        )}
      </div>
    );
  } else if (event.fromDate && event.toDate) {
    dateContent = (
      <div>
        <div className="mb-2">
          <p className="text-sm text-gray-600">From: {formatDate(event.fromDate.toDate())}</p>
          <p className="text-sm text-gray-600 mt-1">To: {formatDate(event.toDate.toDate())}</p>
          {event.startTime && event.endTime && (
            <p className="text-sm text-gray-600 mt-2">
              Daily Time: {formatTime(event.startTime)} - {formatTime(event.endTime)}
            </p>
          )}
        </div>

        {event.isRepeatedEvent && event.repeatedDaysOfWeek && event.repeatedDaysOfWeek.length > 0 && (
          <p className="text-sm text-gray-600 mt-2 pt-2 border-t">
            Every {event.repeatedDaysOfWeek?.join(', ')}
          </p>
        )}
        
        {event.dailySchedules && event.dailySchedules.length > 0 && event.dailySchedules.length <= 7 && (
          <>
            <button 
              onClick={() => setIsExpanded(!isExpanded)}
              className="text-sm text-purple-600 hover:text-purple-800 underline mt-2"
            >
              {isExpanded ? 'Show less' : 'View daily schedule'}
            </button>

            {isExpanded && (
              <div className="space-y-3 mt-3 pt-3 border-t">
                {event.dailySchedules.map((schedule: DailySchedule, index: number) => {
                  const date = schedule.date.toDate();
                  return (
                    <div key={index} className="border-b last:border-b-0 pb-2">
                      <p className="text-sm font-medium text-purple-700">
                        {format(date, 'EEEE, MMMM d, yyyy')}
                      </p>
                      <p className="text-sm text-gray-600 mt-1">
                        {formatTime(schedule.startTime)} - {formatTime(schedule.endTime)}
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
      </div>
    );
  }

  return (
    <Card className="h-full">
      <CardContent className="p-4">
        <div className="flex items-center mb-2">
          <CalendarIcon className="w-6 h-6 text-purple-600 mr-2" />
          <h3 className="text-lg font-semibold text-purple-800">Date & Time</h3>
        </div>
        {dateContent}
      </CardContent>
    </Card>
  );
};