import React from 'react';

interface SeparatorProps {
  className?: string;
  orientation?: 'horizontal' | 'vertical';
}

export const Separator: React.FC<SeparatorProps> = ({ 
  className = '', 
  orientation = 'horizontal' 
}) => {
  const baseClasses = 'bg-gray-200';
  const orientationClasses = orientation === 'horizontal' 
    ? 'w-full h-px' 
    : 'h-full w-px';

  return (
    <div 
      role="separator"
      className={`${baseClasses} ${orientationClasses} ${className}`}
    />
  );
};
