import { useState, useEffect } from 'react';
import { useSearchContext } from '../../../contexts/SearchContext';
import { useUserCity } from '../../../hooks/useUserCity';

export const useSearchBarLogic = () => {
  const { state, setState, dialogState, setDialogState } = useSearchContext();
  const { city, loading: cityLoading, error: cityError } = useUserCity();
  const [tempLocations, setTempLocations] = useState<string[]>([]);
  const [tempCategories, setTempCategories] = useState<string[]>([]);
  const [userInteractedWithLocation, setUserInteractedWithLocation] = useState(() => {
    return sessionStorage.getItem('userInteractedWithLocation') === 'true';
  });
  const [userInteractedWithCategory, setUserInteractedWithCategory] = useState(false);

  useEffect(() => {
    if (city && !userInteractedWithLocation && state.selectedLocations.length === 0) {
      setState(prev => ({
        ...prev,
        selectedLocations: [city],
      }));
    }
  }, [city, state.selectedLocations, setState, userInteractedWithLocation]);

  useEffect(() => {
    if (userInteractedWithLocation) {
      sessionStorage.setItem('userInteractedWithLocation', 'true');
    }
  }, [userInteractedWithLocation]);

  useEffect(() => {
    if (sessionStorage.getItem('userInteractedWithLocation') === 'true') {
      setState(prev => ({
        ...prev,
        selectedLocations: [],
      }));
    }
  }, [setState]);

  return {
    state,
    setState,
    dialogState,
    setDialogState,
    city,
    cityLoading,
    cityError,
    tempLocations,
    setTempLocations,
    tempCategories,
    setTempCategories,
    userInteractedWithLocation,
    setUserInteractedWithLocation,
    userInteractedWithCategory,
    setUserInteractedWithCategory,
  };
}; 