import React from 'react';
import { Helmet } from 'react-helmet';

interface SEOProps {
  title?: string;
  description?: string;
}

const defaultTitle = 'EventAtEase';
const defaultDescription = 'Simplify your event planning and management';

export const SEO: React.FC<SEOProps> = ({ 
  title = defaultTitle, 
  description = defaultDescription 
}) => {
  const fullTitle = title === defaultTitle ? title : `${title} | ${defaultTitle}`;

  return (
    <Helmet>
      <title>{fullTitle}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
};
