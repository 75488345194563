import React from 'react';

const PrivacyPolicyPage: React.FC = () => {
  const currentDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <div className="container mx-auto px-4 py-8 max-w-3xl">
      <h1 className="text-3xl font-bold text-gray-800 mb-4">EventAtEase Privacy Policy</h1>
      <p className="text-sm text-gray-600 mb-6">Last updated: {currentDate}</p>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">1. Introduction</h2>
        <p className="mb-4">
          Welcome to EventAtEase. We are committed to protecting your personal information and your right to privacy. This Privacy Policy describes how we collect, use, and share your personal information when you use our website and services.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">2. Information We Collect</h2>
        <p className="mb-4">We collect personal information that you provide to us when you register for an account, create or modify your profile, set up preferences, sign up for or purchase services. This information may include:</p>
        <ul className="list-disc pl-6 mb-4">
          <li>Name</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Billing and payment information</li>
          <li>Event details (titles, descriptions, dates, locations)</li>
          <li>User-generated content (reviews, comments, messages)</li>
        </ul>
        <p className="mb-4">We also automatically collect certain information when you use our services, including:</p>
        <ul className="list-disc pl-6 mb-4">
          <li>Log and usage data</li>
          <li>Device information</li>
          <li>Location information</li>
          <li>Cookies and similar technologies</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">3. How We Use Your Information</h2>
        <p className="mb-4">We use your personal information for these purposes:</p>
        <ul className="list-disc pl-6 mb-4">
          <li>To provide and maintain our services</li>
          <li>To process transactions and send transactional messages</li>
          <li>To manage user accounts</li>
          <li>To send administrative information</li>
          <li>To respond to inquiries and offer support</li>
          <li>To send marketing and promotional communications</li>
          <li>To improve our services and develop new features</li>
          <li>To enforce our terms, conditions, and policies</li>
          <li>To respond to legal requests and prevent harm</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">4. How We Share Your Information</h2>
        <p className="mb-4">We may share your information with:</p>
        <ul className="list-disc pl-6 mb-4">
          <li>Service providers and business partners</li>
          <li>Event organizers (for events you register for)</li>
          <li>Other users (as part of public profile information)</li>
          <li>Advertisers and advertising networks</li>
          <li>Analytics providers</li>
          <li>Law enforcement or regulatory agencies when required</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">5. Your Privacy Rights</h2>
        <p className="mb-4">Depending on your location, you may have the following rights:</p>
        <ul className="list-disc pl-6 mb-4">
          <li>Right to access personal information we hold about you</li>
          <li>Right to request correction of your personal information</li>
          <li>Right to request deletion of your personal information</li>
          <li>Right to object to processing of your personal information</li>
          <li>Right to data portability</li>
          <li>Right to withdraw consent</li>
        </ul>
        <p className="mb-4">To exercise these rights, please contact us using the information provided in the "Contact Us" section.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">6. Data Retention</h2>
        <p className="mb-4">We keep your personal information for as long as it is necessary for the purposes set out in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">7. Security of Your Information</h2>
        <p className="mb-4">We use appropriate technical and organizational measures to protect the personal information that we collect and process about you. However, no security system is impenetrable and we cannot guarantee the security of our systems 100%.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">8. Children's Privacy</h2>
        <p className="mb-4">Our services are not directed to children under 13, and we do not knowingly collect personal information from children under 13. If we learn we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that information.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">9. Changes to This Privacy Policy</h2>
        <p className="mb-4">We may update this Privacy Policy from time to time. The updated version will be indicated by an updated "Last updated" date and the updated version will be effective as soon as it is accessible.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">10. Contact Us</h2>
        <p className="mb-4">If you have questions or comments about this Privacy Policy, you may email us at privacy@eventatease.com or by post to:</p>
        <p>
          EventAtEase, Inc.<br />
          [Your Company Address]<br />
          [City, State ZIP Code]<br />
          [Country]
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicyPage;