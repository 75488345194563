import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/Card";
import { Button } from "../../components/ui/Button";
import { Input } from "../../components/ui/Input";
import { ScrollArea } from "../../components/ui/ScrollArea";
import { PlusIcon, SearchIcon, Settings } from "lucide-react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Event } from '../../models/Event';
import { CalendarIcon, MapPinIcon, UsersIcon, ClockIcon } from '../../utils/icons';
import { Badge } from "../../components/ui/Badge";
import { Timestamp } from 'firebase/firestore';
import { collection, query, getDocs } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { format } from 'date-fns';
import { getImageUrl } from '../../utils/firebaseUtils';
import defaultEventImage from '../../assets/images/default-event-image.png';

// ... mockEvents and mockChartData remain the same ...

const EventCard: React.FC<{ event: Event; onClick: () => void }> = ({ event, onClick }) => {
  const [imageUrl, setImageUrl] = useState<string>(defaultEventImage);

  useEffect(() => {
    if (event.imageUrl) {
      getImageUrl(event.imageUrl)
        .then(url => setImageUrl(url))
        .catch(() => setImageUrl(defaultEventImage));
    }
  }, [event.imageUrl]);

  const formatDate = (timestamp: Timestamp | null | undefined): string => {
    if (timestamp instanceof Timestamp) {
      return format(timestamp.toDate(), 'dd/MM/yyyy');
    }
    return 'No date';
  };

  const handleImageError = () => {
    setImageUrl(defaultEventImage);
  };

  return (
    <Card className="overflow-hidden transition-all duration-300 hover:shadow-lg cursor-pointer" onClick={onClick}>
      <div className="relative">
        <img 
          src={imageUrl} 
          alt={event.title} 
          className="w-full h-32 object-cover"
          onError={handleImageError}
        />
        <Badge 
          variant={event.status === 'completed' ? 'default' : event.status === 'cancelled' ? 'secondary' : 'outline'}
          className="absolute top-2 right-2"
        >
          {event.status}
        </Badge>
      </div>
      <CardContent className="p-4">
        <h3 className="font-semibold text-lg text-purple-800 mb-2 truncate">{event.title}</h3>
        <div className="flex items-center text-sm text-gray-600 mb-1">
          <CalendarIcon className="w-4 h-4 mr-1" />
          {formatDate(event.date)}
        </div>
        <div className="flex items-center text-sm text-gray-600 mb-1">
          <ClockIcon className="w-4 h-4 mr-1" />
          {event.startTime || 'N/A'} - {event.endTime || 'N/A'}
        </div>
        <div className="flex items-center text-sm text-gray-600 mb-1">
          <MapPinIcon className="w-4 h-4 mr-1" />
          {event.location || 'No location'}
        </div>
        <div className="flex items-center text-sm text-gray-600">
          <UsersIcon className="w-4 h-4 mr-1" />
          {event.attendeeCount || 0} attendees
        </div>
      </CardContent>
    </Card>
  );
}

const mockChartData = [
  { name: 'Jan', events: 4 },
  { name: 'Feb', events: 3 },
  { name: 'Mar', events: 5 },
  { name: 'Apr', events: 7 },
  { name: 'May', events: 6 },
  { name: 'Jun', events: 8 },
]

export const OrganizerDashboardPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [events, setEvents] = useState<Event[]>([]);

  useEffect(() => {
    const fetchEvents = async () => {
      const eventsCollection = collection(db, 'events');
      const eventsQuery = query(eventsCollection);
      const querySnapshot = await getDocs(eventsQuery);
      const fetchedEvents = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          date: data.date instanceof Timestamp ? data.date : null,
          fromDate: data.fromDate instanceof Timestamp ? data.fromDate : null,
          toDate: data.toDate instanceof Timestamp ? data.toDate : null,
          createdAt: data.createdAt instanceof Timestamp ? data.createdAt : null,
          updatedAt: data.updatedAt instanceof Timestamp ? data.updatedAt : null,
        } as Event;
      });
      setEvents(fetchedEvents);
    };

    fetchEvents();
  }, []);

  const filteredEvents = events.filter((event) =>
    event.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (event.location?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false) ||
    formatDate(event.date).includes(searchTerm)
  );

  const handleEventClick = (eventId: string) => {
    navigate(`/organizer/events/${eventId}`);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold text-purple-800">Organizer Dashboard</h1>
        <div className="flex space-x-4">
          <Button 
            className="bg-purple-600 hover:bg-purple-700 text-white" 
            onClick={() => navigate('/organizer/create-event')}
          >
            <PlusIcon className="w-4 h-4 mr-2" />
            Create Event
          </Button>
          <Button 
            variant="outline" 
            className="border-purple-600 text-purple-600 hover:bg-purple-50"
            onClick={() => navigate('/organizer/account')}
          >
            <Settings className="w-4 h-4 mr-2" />
            Account Settings
          </Button>
        </div>
      </div>

      <div className="grid gap-6 mb-8 md:grid-cols-2">
        <Card>
          <CardHeader>
            <CardTitle>Event Frequency</CardTitle>
          </CardHeader>
          <CardContent>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={mockChartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="events" stroke="#8884d8" activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Upcoming Events</CardTitle>
          </CardHeader>
          <CardContent>
            <ScrollArea className="h-[300px]">
              <div className="grid gap-4 md:grid-cols-2">
                {events.filter(event => event.status === 'upcoming').map(event => (
                  <EventCard key={event.id} event={event} onClick={() => handleEventClick(event.id)} />
                ))}
              </div>
            </ScrollArea>
          </CardContent>
        </Card>
      </div>

      <Card className="mb-8">
        <CardHeader>
          <CardTitle>All Events</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="mb-4 flex items-center">
            <SearchIcon className="w-4 h-4 mr-2 text-gray-500" />
            <Input
              type="text"
              placeholder="Search events..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="max-w-sm"
            />
          </div>
          <ScrollArea className="h-[400px]">
            <div className="grid gap-4 md:grid-cols-3 lg:grid-cols-4">
              {filteredEvents.map(event => (
                <EventCard key={event.id} event={event} onClick={() => handleEventClick(event.id)} />
              ))}
            </div>
          </ScrollArea>
        </CardContent>
      </Card>

      {/* ... existing footer buttons ... */}
    </div>
  );
};

const formatDate = (timestamp: Timestamp | null | undefined): string => {
  if (timestamp instanceof Timestamp) {
    return format(timestamp.toDate(), 'dd/MM/yyyy');
  }
  return 'No date';
};
