import { Together } from 'together-ai';

const togetherApiKey = (window as any)._env_?.REACT_APP_TOGETHER_API_KEY || process.env.REACT_APP_TOGETHER_API_KEY;

if (!togetherApiKey) {
  console.error('REACT_APP_TOGETHER_API_KEY is not set in the environment');
}

// Initialize Together API client
const together = new Together({ apiKey: togetherApiKey || '' });

export async function enhanceTextWithAI(title: string, description: string): Promise<string> {
  if (!togetherApiKey) {
    console.error('Together API client is not initialized. Unable to enhance description.');
    return description;
  }

  const prompt = `
    Given the following event details:
    Title: "${title}"
    Description: "${description}"

    Enhance the event description to make it more engaging and informative. 
    Keep the core information intact but improve the language, add more details if necessary, 
    and make it more appealing to potential attendees. 
    The enhanced description should be concise (max 100 words) and maintain the original tone.

    Respond with only the enhanced description, without any quotation marks.
  `;

  try {
    const response = await together.chat.completions.create({
      model: "meta-llama/Llama-Vision-Free",
      messages: [
        { role: "system", content: "You are an AI assistant that enhances event descriptions to make them more engaging. Do not use quotation marks in your response." },
        { role: "user", content: prompt }
      ],
      max_tokens: 200,
      temperature: 0.7
    });

    let enhancedDescription = response.choices[0]?.message?.content?.trim() || description;
    
    // Remove any remaining quotation marks from the start and end of the description
    enhancedDescription = enhancedDescription.replace(/^["']|["']$/g, '');

    return enhancedDescription;
  } catch (error) {
    console.error("Error enhancing description:", error);
    return description; // Return original description if enhancement fails
  }
}

export async function generateImagePrompt(title: string, description: string): Promise<string> {
  if (!togetherApiKey) {
    console.error('Together API client is not initialized. Unable to generate image prompt.');
    return '';
  }

  const prompt = `
  Given the following event details:
  Title: "${title}"
  Description: "${description}"

  Create a detailed prompt for generating an elegant and intuitive event poster image. The prompt should describe:
  1. The main visual elements that represent the event's theme
  2. The overall style and mood of the image
  3. Any specific colors or lighting that would be appropriate

  Focus solely on the visual aspects without mentioning any text or typographic elements. The prompt should be concise (max 40 words) but descriptive enough to generate a compelling event poster image.

  Respond with only the image generation prompt.
`;

  try {
    const response = await together.chat.completions.create({
      model: "meta-llama/Llama-Vision-Free",
      messages: [
        { role: "system", content: "You are an AI assistant that creates prompts for generating event poster images. Do not use quotation marks in your response." },
        { role: "user", content: prompt }
      ],
      max_tokens: 100,
      temperature: 0.7
    });

    let imagePrompt = response.choices[0]?.message?.content?.trim() || '';
    
    // Remove any remaining quotation marks from the start and end of the prompt
    imagePrompt = imagePrompt.replace(/^["']|["']$/g, '');

    console.log("Generated image prompt:", imagePrompt);
    return imagePrompt;
  } catch (error) {
    console.error("Error generating image prompt:", error);
    return ''; // Return empty string if prompt generation fails
  }
}

export async function generateEventImage(prompt: string): Promise<string | null> {
  if (!togetherApiKey) {
    console.error('Together API client is not initialized. Unable to generate image.');
    return null;
  }

  try {
    const response = await together.images.create({
      model: "black-forest-labs/FLUX.1-schnell-Free",
      prompt: prompt,
      width: 1024,
      height: 768,
      steps: 1,
      n: 1,
      // @ts-ignore
      response_format: "b64_json"
    });

    console.log("Image generation response:", response);

    // Check if the response contains the expected data
    if (response.data && response.data.length > 0) {
      const imageData = response.data[0];
      if (typeof imageData === 'object' && 'b64_json' in imageData) {
        return imageData.b64_json as string;
      }
    }
    
    console.error("Unexpected response format from image generation API");
    return null;
  } catch (error) {
    console.error("Error generating image:", error);
    return null;
  }
}