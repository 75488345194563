import React from 'react';
import { Button } from "./ui/Button";
import { Label } from "./ui/Label";
import { Event } from '../types/Event';

interface EventFrequencySelectionProps {
  event: Partial<Event>;
  onChange: (field: string, value: any) => void;
}

export const EventFrequencySelection: React.FC<EventFrequencySelectionProps> = ({ event, onChange }) => {
  return (
    <>
      <div className="space-y-2">
        <Label>Event Frequency</Label>
        <div className="flex items-center space-x-2">
          <Button
            type="button"
            variant={!event.isRepeatedEvent ? "default" : "outline"}
            onClick={() => onChange('isRepeatedEvent', false)}
            className={!event.isRepeatedEvent ? "bg-purple-600 text-white" : "border-purple-600 text-purple-600"}
          >
            One-time
          </Button>
          <Button
            type="button"
            variant={event.isRepeatedEvent ? "default" : "outline"}
            onClick={() => onChange('isRepeatedEvent', true)}
            className={event.isRepeatedEvent ? "bg-purple-600 text-white" : "border-purple-600 text-purple-600"}
          >
            Repeated
          </Button>
        </div>
      </div>

      {event.isRepeatedEvent && (
        <div className="space-y-2">
          <Label htmlFor="repeatedDaysOfWeek">Repeat on</Label>
          <div className="flex flex-wrap gap-2">
            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
              <Button
                key={day}
                type="button"
                variant={event.repeatedDaysOfWeek?.includes(day) ? "default" : "outline"}
                onClick={() => {
                  const updatedDays = event.repeatedDaysOfWeek?.includes(day)
                    ? event.repeatedDaysOfWeek?.filter(d => d !== day)
                    : [...(event.repeatedDaysOfWeek || []), day];
                  onChange('repeatedDaysOfWeek', updatedDays);
                }}
                className={event.repeatedDaysOfWeek?.includes(day) ? "bg-purple-600 text-white" : "border-purple-600 text-purple-600"}
              >
                {day.slice(0, 3)}
              </Button>
            ))}
          </div>
        </div>
      )}
    </>
  );
};