import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const routeTitles: { [key: string]: string } = {
  '/': 'EventAtEase - Simplify Event Management',
  '/features': 'Features - EventAtEase',
  '/login': 'Login - EventAtEase',
  '/signup': 'Sign Up - EventAtEase',
  '/attendee/home': 'Discover Events - EventAtEase',
  '/organizer/dashboard': 'Organizer Dashboard - EventAtEase',
  '/organizer/create-event': 'Create Event - EventAtEase',
  '/first-signin': 'Complete Your Profile - EventAtEase',
  '/profile': 'User Profile - EventAtEase',
  '/profile/edit': 'Edit Profile - EventAtEase',
  '/registration-confirmation': 'Registration Confirmed - EventAtEase',
};

export const DynamicTitle: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;

    // Check if the path starts with '/event/' or '/organizer/events/'
    if (path.startsWith('/event/')) {
      document.title = 'Event Details - EventAtEase';
    } else if (path.startsWith('/organizer/events/')) {
      if (path.endsWith('/manage-attendees')) {
        document.title = 'Manage Attendees - EventAtEase';
      } else {
        document.title = 'Event Details - EventAtEase';
      }
    } else if (path.endsWith('/register')) {
      document.title = 'Event Registration - EventAtEase';
    } else {
      // For other routes, use the predefined titles or a default
      document.title = routeTitles[path] || 'EventAtEase';
    }
  }, [location]);

  return null; // This component doesn't render anything
};
