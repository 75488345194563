import React, { useEffect, useState } from 'react';
import { format, isValid } from 'date-fns';
import { CalendarIcon, MapPinIcon, UsersIcon, RepeatIcon, GlobeIcon, ClockIcon, BookmarkIcon, AlertTriangleIcon, ShareIcon } from "../utils/icons";
import { FaWhatsapp, FaFacebookF, FaTwitter } from 'react-icons/fa';
import { Event } from '../types/Event';
import { getImageUrl } from '../utils/firebaseUtils';
import { Timestamp } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import defaultEventImage from '../assets/images/default-event-image.png';
import { Rating } from './ui/Rating/Rating';

const formatDate = (timestamp: Timestamp | null | undefined) => {
  if (!timestamp) return 'Date not available';
  const date = timestamp.toDate();
  return isValid(date) ? format(date, "do MMMM yyyy") : 'Invalid date';
};

const formatDay = (timestamp: Timestamp | null | undefined) => {
  if (!timestamp) return 'Day not available';
  const date = timestamp.toDate();
  return isValid(date) ? format(date, 'EEEE') : 'Invalid date';
};

interface EventCardProps {
  event: Event;
  onBookmarkToggle: (eventId: string, isBookmarked: boolean) => void;
  isBookmarked: boolean;
  linkTo: string;
  userId: string;
  hasAttended?: boolean;
  onRate?: (rating: number) => Promise<void>;
}

interface Feedback {
  userId: string;
  rating: number;
  comment: string;
  createdAt: Timestamp;
}

export const EventCard: React.FC<EventCardProps> = ({ event, onBookmarkToggle, isBookmarked, linkTo, userId, hasAttended = false, onRate }) => {
  const [imageUrl, setImageUrl] = React.useState<string>(defaultEventImage);
  const [showShareModal, setShowShareModal] = useState(false);

  const {
    title,
    date,
    location,
    locationLink,
    skill,
    imageUrl: eventImageUrl,
    fromDate,
    toDate,
    isRepeatedEvent,
    repeatedDaysOfWeek,
    isFree,
    startTime,
    endTime,
    isOnline,
    isCancelled,
  } = event;

  useEffect(() => {
    if (eventImageUrl) {
      getImageUrl(eventImageUrl)
        .then(url => setImageUrl(url))
        .catch(() => setImageUrl(defaultEventImage));
    }
  }, [eventImageUrl]);

  const handleBookmarkToggle = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onBookmarkToggle(event.id, !isBookmarked);
  };

  const handleShare = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const shareData = {
      title: event.title,
      text: `Check out this event: ${event.title}`,
      url: `${window.location.origin}/event/${event.id}`,
    };

    if (navigator.share) {
      try {
        await navigator.share(shareData);
      } catch (err) {
        console.error('Error sharing:', err);
      }
    } else {
      setShowShareModal(true);
    }
  };

  const renderDateInfo = () => {
    if (isRepeatedEvent) {
      return (
        <div className="flex items-center text-purple-700">
        <RepeatIcon className="w-4 h-4 mr-2" />
        <span>Every {repeatedDaysOfWeek?.join(', ')}</span>
      </div>
      );
    } else if (fromDate && toDate && !fromDate.isEqual(toDate)) {
      return (
        <div className="flex items-center text-purple-700">
          <CalendarIcon className="w-4 h-4 mr-2" />
          <span>{formatDate(fromDate)} - {formatDate(toDate)}</span>
        </div>
      );
    } else if (date) {
      return (
        <div className="flex items-center text-purple-700">
          <CalendarIcon className="w-4 h-4 mr-2" />
          <span>{formatDay(date)}, {formatDate(date)}</span>
        </div>
      );
    }
  };

  const isEventPast = (eventDate: Timestamp | null) => {
    if (!eventDate) return false;
    return eventDate.toDate() < new Date();
  };

  const calculateRating = () => {
    const feedbackArray = event.feedback as Feedback[] | undefined;
    if (!feedbackArray || feedbackArray.length === 0) return 0;
    
    const sum = feedbackArray.reduce((acc: number, curr: Feedback) => acc + curr.rating, 0);
    return sum / feedbackArray.length;
  };

  const rating = calculateRating();

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden h-full">
      <div className="block h-full">
        <Link to={linkTo} className="block h-full flex flex-col">
          <div className="bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300 hover:shadow-lg h-full flex flex-col relative">
            {event.isCancelled && (
              <div className="absolute top-0 left-0 right-0 bg-red-500 text-white py-1 px-2 flex items-center justify-center z-10">
                <AlertTriangleIcon className="w-4 h-4 mr-2" />
                <span className="text-sm font-semibold">Event Cancelled</span>
              </div>
            )}
            <div className="relative">
              <img
                src={imageUrl}
                alt={title}
                className={`w-full h-48 object-cover ${event.isCancelled ? 'opacity-50' : ''}`}
                onError={() => setImageUrl(defaultEventImage)}
              />
              <div className="absolute top-2 right-2 flex space-x-2">
                <button
                  className={`p-2 rounded-full transition-colors duration-300 ${
                    isBookmarked 
                      ? 'bg-purple-500 text-white' 
                      : 'bg-white text-purple-500 hover:bg-purple-100'
                  }`}
                  onClick={handleBookmarkToggle}
                >
                  <BookmarkIcon className={`w-6 h-6 ${isBookmarked ? 'fill-current' : 'stroke-current'}`} />
                  <span className="sr-only">{isBookmarked ? 'Remove bookmark' : 'Bookmark event'}</span>
                </button>
                <button
                  className="p-2 rounded-full bg-white text-purple-500 hover:bg-purple-100 transition-colors duration-300"
                  onClick={handleShare}
                >
                  <ShareIcon className="w-6 h-6" />
                  <span className="sr-only">Share event</span>
                </button>
              </div>
              <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-purple-900 to-transparent p-4">
                <h3 className="text-xl font-bold text-white">{title}</h3>
              </div>
            </div>
            <div className="p-4 flex-grow flex flex-col justify-between">
              <div className="space-y-2 text-sm">
                {renderDateInfo()}
                <div className="flex items-center text-gray-600">
                  <ClockIcon className="w-4 h-4 mr-2" />
                  <span>{startTime} - {endTime}</span>
                </div>
                <div className="flex items-center text-gray-600">
                  {isOnline ? (
                    <GlobeIcon className="w-4 h-4 mr-2" />
                  ) : (
                    <MapPinIcon className="w-4 h-4 mr-2" />
                  )}
                  <a href={locationLink} target="_blank" rel="noopener noreferrer" className="hover:underline">
                    {location}
                  </a>
                </div>
                <div className="flex items-center text-gray-600">
                  <UsersIcon className="w-4 h-4 mr-2" />
                  <span>{skill}</span>
                </div>
                <div className="flex items-center justify-between mt-2">
                  <div className="flex items-center gap-2">
                    <Rating 
                      rating={rating} 
                      isInteractive={hasAttended}
                      onRate={rating => onRate?.(rating)}
                      size="sm"
                      disabled={!hasAttended}
                    />
                    {rating > 0 && (
                      <span className="text-sm text-gray-600">
                        ({rating.toFixed(1)})
                      </span>
                    )}
                  </div>
                  {!hasAttended && isEventPast(event.date) && (
                    <span className="text-xs text-gray-500 italic">
                      Rate after attending
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="bg-purple-50 p-4 flex flex-wrap justify-between items-center gap-2 mt-auto">
              <span className={`px-2 py-1 rounded-full text-xs font-semibold ${isFree ? 'bg-purple-100 text-purple-800' : 'bg-purple-500 text-white'}`}>
                {isFree ? 'Free' : 'Paid'}
              </span>
              <span className="px-2 py-1 rounded-full text-xs font-semibold border border-purple-200 text-purple-800">
                {isOnline ? 'Online' : 'In-person'}
              </span>
              {event.isCancelled && (
                <span className="px-2 py-1 rounded-full text-xs font-semibold bg-red-100 text-red-800">
                  Cancelled
                </span>
              )}
            </div>
          </div>
        </Link>
      </div>
      {showShareModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-sm w-full">
            <h3 className="text-lg font-semibold mb-4">Share this event</h3>
            <div className="flex justify-around mb-6">
              <a 
                href={`https://wa.me/?text=${encodeURIComponent(`Check out this event: ${event.title} ${window.location.origin}/event/${event.id}`)}`} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-green-500 hover:text-green-600 transition-colors duration-200"
              >
                <FaWhatsapp size={24} />
                <span className="sr-only">Share on WhatsApp</span>
              </a>
              <a 
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(`${window.location.origin}/event/${event.id}`)}`} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-blue-600 hover:text-blue-700 transition-colors duration-200"
              >
                <FaFacebookF size={24} />
                <span className="sr-only">Share on Facebook</span>
              </a>
              <a 
                href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(`Check out this event: ${event.title}`)}&url=${encodeURIComponent(`${window.location.origin}/event/${event.id}`)}`} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-blue-400 hover:text-blue-500 transition-colors duration-200"
              >
                <FaTwitter size={24} />
                <span className="sr-only">Share on Twitter</span>
              </a>
            </div>
            <button 
              onClick={() => setShowShareModal(false)} 
              className="w-full bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600 transition-colors duration-200"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventCard;
