import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";
import { Input } from "./ui/Input";
import { ScrollArea } from "./ui/ScrollArea";
import { SearchIcon } from "lucide-react";
import { Event } from '../types/Event';
import EventCard from './EventCard';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';

interface EventHistoryProps {
  eventIds?: string[];
  events?: Event[];
  title?: string;
  showSearch?: boolean;
  maxHeight?: string;
  userId: string;
}

export const EventHistory: React.FC<EventHistoryProps> = React.memo(({ 
  eventIds = [],
  events = [],
  title = "Event History",
  showSearch = true,
  maxHeight = "400px",
  userId
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [loadedEvents, setLoadedEvents] = useState<Event[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  // Create a stable eventIds reference
  const eventIdsString = useMemo(() => eventIds.join(','), [eventIds]);
  const eventsString = useMemo(() => events.map(e => e.id).join(','), [events]);

  // Memoize the fetch function
  const fetchEvents = useCallback(async () => {
    setIsLoading(true);
    try {
      if (eventIds.length > 0) {
        const eventPromises = eventIds.map(async (eventId) => {
          const eventDoc = await getDoc(doc(db, 'events', eventId));
          if (eventDoc.exists()) {
            return {
              id: eventDoc.id,
              ...eventDoc.data()
            } as Event;
          }
          return null;
        });
        const fetchedEvents = (await Promise.all(eventPromises))
          .filter((event): event is Event => event !== null);
        setLoadedEvents(fetchedEvents);
      } else {
        setLoadedEvents(events);
      }
    } catch (error) {
      console.error('Error fetching events:', error);
      setLoadedEvents([]);
    } finally {
      setIsLoading(false);
    }
  }, [eventIdsString, eventsString]); // Use stable string references

  // Use the memoized fetch function in useEffect
  useEffect(() => {
    let mounted = true;

    const doFetch = async () => {
      if (mounted) {
        await fetchEvents();
      }
    };

    doFetch();

    return () => {
      mounted = false;
    };
  }, [fetchEvents]);

  // Memoize filtered events
  const filteredEvents = useMemo(() => {
    return loadedEvents.filter((event) =>
      event.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (event.location?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false)
    );
  }, [loadedEvents, searchTerm]);

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>{title}</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="text-center py-8">Loading events...</div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      <CardContent>
        {showSearch && (
          <div className="mb-4 flex items-center">
            <SearchIcon className="w-4 h-4 mr-2 text-gray-500" />
            <Input
              type="text"
              placeholder="Search events..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="max-w-sm"
            />
          </div>
        )}
        <ScrollArea className={`h-[${maxHeight}]`}>
          <div className="grid gap-4 md:grid-cols-3 lg:grid-cols-4">
            {filteredEvents.map(event => (
              <EventCard 
                key={event.id} 
                event={event}
                onBookmarkToggle={() => {}}
                isBookmarked={false}
                linkTo={`/events/${event.id}`}
                userId={userId}
                hasAttended={eventIds.includes(event.id)}
              />
            ))}
          </div>
          {filteredEvents.length === 0 && (
            <div className="text-center py-8 text-gray-500">
              No events found
            </div>
          )}
        </ScrollArea>
      </CardContent>
    </Card>
  );
});
