import React from 'react';
import { Input } from "./ui/Input";
import { Label } from "./ui/Label";
import { Event } from '../types/Event';

interface EventSkillInputProps {
  event: Partial<Event>;
  onChange: (field: string, value: any) => void;
}

export const EventSkillInput: React.FC<EventSkillInputProps> = ({ event, onChange }) => {
  return (
    <div className="space-y-2">
      <Label htmlFor="skill">Skill Level</Label>
      <Input
        id="skill"
        name="skill"
        value={event.skill || ''}
        onChange={(e) => onChange('skill', e.target.value)}
        placeholder="Enter skill level (e.g., Beginner, Intermediate, Advanced)"
        required
        className="border-purple-300 focus:border-purple-500 focus:ring-purple-500"
      />
    </div>
  );
};