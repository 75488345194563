// src/models/BaseModel.ts
import { db } from '../config/firebase';
import { collection, doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore';

export abstract class BaseModel {
  protected static collectionName: string;

  constructor(public id: string) {}

  // Get the Firestore collection reference
  protected static getCollectionRef() {
    return collection(db, this.collectionName);
  }

  // Find a document by its ID
  static async findById(id: string): Promise<any | null> {
    console.log(`Attempting to find document with id: ${id} in collection: ${this.collectionName}`);
    const docRef = doc(this.getCollectionRef(), id);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      console.log(`Document with id: ${id} not found in collection: ${this.collectionName}`);
      return null;
    }
    console.log(`Document found:`, docSnap.data());
    return { id: docSnap.id, ...docSnap.data() };
  }

  // Save or update a document
  async save(): Promise<void> {
    const docRef = doc(BaseModel.getCollectionRef(), this.id);
    await setDoc(docRef, this.toJSON());
  }

  // Delete a document
  async delete(): Promise<void> {
    const docRef = doc(BaseModel.getCollectionRef(), this.id);
    await deleteDoc(docRef);
  }

  // Convert the class to JSON (for saving to Firestore)
  public toJSON(): any {
    return {
      id: this.id,
    };
  }
}
