import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "../../components/ui/Card";
import { Button } from "../../components/ui/Button";
import { Separator } from "../../components/ui/Separator";
import { CheckCircle, Calendar, MapPin, Clock, Info, User, DollarSign } from "lucide-react";
import { registerUserForEvent } from '../../services/eventService';
import { useAuth } from '../../contexts/AuthContext';
import { getUserName } from '../../services/userService';
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/Alert";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { CalendarService } from '../../services/calendarService';

interface EventState {
  eventTitle: string;
  eventDate: string;
  eventTime: string;
  eventLocation: string;
  eventDescription: string;
  organizer: string;
  isFree: boolean;
  price: number;
  eventImage: string; 
  attendeeCount: number;
}

interface OrderRequestData {
  amount: number;
  eventId: string;
  eventName: string;
}

export const RegistrationScreen: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const { user, customUser } = useAuth(); // You can access both Firebase user and custom user data
  const [isRegistering, setIsRegistering] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const event = location.state as EventState;
  const [organizerName, setOrganizerName] = useState<string>('');

  useEffect(() => {
    const fetchOrganizerName = async () => {
      if (event && event.organizer) {
        try {
          const name = await getUserName(event.organizer);
          setOrganizerName(name);
        } catch (error) {
          console.error('Error fetching organizer name:', error);
        }
      }
    };

    fetchOrganizerName();
  }, [event]);

  const handlePayment = async (orderId: string, amount: number) => {
    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID!,
      amount: Math.round(amount * 100),
      currency: "INR",
      name: "EventAtEase",
      description: `Registration for ${event.eventTitle}`,
      image: "https://i.ibb.co/pwKFZxJ/Screenshot-2024-11-01-at-4-29-35-PM.png",
      order_id: orderId,
      handler: function (response: {
        razorpay_payment_id: string;
        razorpay_order_id: string;
        razorpay_signature: string;
      }) {
        console.log("Payment ID: ", response.razorpay_payment_id);
        console.log("Order ID: ", response.razorpay_order_id);
        console.log("Signature: ", response.razorpay_signature);
        
        navigate('/registration-confirmation', {
          state: {
            eventTitle: event.eventTitle,
            eventDate: event.eventDate,
            eventImage: event.eventImage,
            attendeeCount: event.attendeeCount + 1,
            eventId: id
          }
        });
      },
      prefill: {
        name: customUser?.displayName || '',
        email: user?.email || '',
        contact: customUser?.phoneNumber || ''
      },
      notes: {
        eventId: id || '',
        eventName: event.eventTitle || '',
        userId: user?.uid || ''
      },
      theme: {
        color: "#7C3AED"
      }
    };

    try {
      const rzp = new (window as any).Razorpay(options);
      
      rzp.on('payment.failed', function (response: any) {
        alert(`Payment Failed: ${response.error.description}`);
        console.error('Payment Failed:', {
          code: response.error.code,
          description: response.error.description,
          source: response.error.source,
          step: response.error.step,
          reason: response.error.reason,
          orderId: response.error.metadata.order_id,
          paymentId: response.error.metadata.payment_id
        });
      });

      rzp.open();
    } catch (error) {
      console.error('Razorpay initialization failed:', error);
      setError('Failed to initialize payment. Please try again.');
    }
  };

  const handleRegister = async () => {
    if (!user || !id) return;
    
    setIsRegistering(true);
    setError(null);
    
    try {
      const eventDoc = await getDoc(doc(db, 'events', id));
      const eventData = eventDoc.data();
      const googleCalendarEventId = eventData?.googleCalendarEventId;

      if (googleCalendarEventId && user.email) {
        try {
          await CalendarService.addAttendees(googleCalendarEventId, [user.email]);
        } catch (calendarError) {
          console.error('Initial calendar update failed:', calendarError);
          
          try {
            await CalendarService.getAccessToken(true);
            await CalendarService.addAttendees(googleCalendarEventId, [user.email]);
          } catch (retryError) {
            console.error('Failed to add to calendar after retry:', retryError);
          }
        }
      }

      if (!event.isFree) {
        const functions = getFunctions();
        const createOrder = httpsCallable<OrderRequestData, { orderId: string }>(functions, 'createRazorpayOrder');
        
        const price = Number(event.price);
        if (isNaN(price)) {
          throw new Error('Invalid price value');
        }

        const response = await createOrder({
          amount: price,
          eventId: id,
          eventName: event.eventTitle
        });

        const { orderId } = response.data;
        await handlePayment(orderId, price);
      } else {
        await registerUserForEvent(id, user.uid);
        setSuccess('Registration successful!');
        
        navigate('/registration-confirmation', {
          state: {
            eventTitle: event.eventTitle,
            eventDate: event.eventDate,
            eventImage: event.eventImage,
            attendeeCount: event.attendeeCount + 1,
            eventId: id
          }
        });
      }
    } catch (error) {
      console.error('Registration error:', error);
      setError('Failed to process registration');
    } finally {
      setIsRegistering(false);
    }
  };

  // Add Razorpay script to the page
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (!event) {
    return <div>Error: Event details not found</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-2xl">
      <Card className="w-full">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-center text-purple-800">Registration Details</CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          {error && (
            <Alert variant="destructive">
              <AlertTitle>Registration Failed</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
          {success && (
            <Alert variant="default">
              <AlertTitle>Registration Successful</AlertTitle>
              <AlertDescription>{success}</AlertDescription>
            </Alert>
          )}
          <div>
            <h2 className="text-xl font-semibold text-purple-700 mb-2">{event.eventTitle}</h2>
            <p className="text-gray-600 text-sm">{event.eventDescription}</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="space-y-2 text-gray-600">
              <div className="flex items-center">
                <Calendar className="w-5 h-5 mr-2 text-purple-600" />
                <span>{event.eventDate}</span>
              </div>
              {event.eventTime && (
                <div className="flex items-center">
                  <Clock className="w-5 h-5 mr-2 text-purple-600" />
                  <span>{event.eventTime}</span>
                </div>
              )}
              <div className="flex items-center">
                <MapPin className="w-5 h-5 mr-2 text-purple-600" />
                <span>{event.eventLocation}</span>
              </div>
            </div>
            <div className="space-y-2 text-gray-600">
              <div className="flex items-center">
                <User className="w-5 h-5 mr-2 text-purple-600" />
                <span>Organized by: {organizerName || 'Loading...'}</span>
              </div>
              <div className="flex items-center">
                <DollarSign className="w-5 h-5 mr-2 text-purple-600" />
                <span>
                  {event.isFree 
                    ? 'Free Event' 
                    : `Price: ₹${Number(event.price).toFixed(2)}`}
                </span>
              </div>
            </div>
          </div>
          <Separator />
          <div className="bg-purple-50 p-4 rounded-lg">
            <div className="flex items-center mb-2">
              <Info className="w-5 h-5 mr-2 text-purple-600" />
              <span className="font-semibold text-purple-800">Registration Summary</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-lg">Total Amount:</span>
              <span className="text-2xl font-bold text-purple-800">
                ₹{event.isFree ? '0.00' : Number(event.price).toFixed(2)}
              </span>
            </div>
            {event.isFree && (
              <div className="flex items-center text-green-600 mt-2">
                <CheckCircle className="w-5 h-5 mr-2" />
                <span>This is a free event</span>
              </div>
            )}
          </div>
        </CardContent>
        <CardFooter className="flex flex-col items-center">
          <Button 
            className="w-full bg-purple-600 hover:bg-purple-700 text-white py-6 text-lg"
            onClick={handleRegister}
            disabled={isRegistering || !!success}
          >
            {isRegistering 
              ? 'Processing...' 
              : success
                ? 'Registration Complete'
                : event.isFree 
                  ? 'Complete Registration' 
                  : 'Pay and Register'}
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};
