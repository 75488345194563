import { useState, useCallback } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../config/firebase';

export const useBookmarks = (user: any) => {
  const [bookmarkedEvents, setBookmarkedEvents] = useState<Set<string>>(new Set());

  const fetchBookmarkedEvents = useCallback(async () => {
    if (!user) return;
    try {
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);
      const userData = userDoc.data();
      const bookmarkArray = (userData?.bookmarkedEvents || []) as string[];
      setBookmarkedEvents(new Set<string>(bookmarkArray));
    } catch (error) {
      console.error('Error fetching bookmarked events:', error);
    }
  }, [user]);

  const onBookmarkToggle = useCallback(async (eventId: string, isBookmarked: boolean) => {
    if (!user) return;
    
    try {
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      const userData = userDoc.data();
      const existingBookmarks = (userData?.bookmarkedEvents || []) as string[];
      const currentBookmarks = new Set<string>(existingBookmarks);
      
      if (isBookmarked) {
        currentBookmarks.add(eventId);
      } else {
        currentBookmarks.delete(eventId);
      }
      
      const bookmarksArray = Array.from(currentBookmarks);
      await updateDoc(userRef, {
        bookmarkedEvents: bookmarksArray
      });
      
      setBookmarkedEvents(new Set<string>(bookmarksArray));
    } catch (error) {
      console.error('Error toggling bookmark:', error);
    }
  }, [user]);

  return { bookmarkedEvents, fetchBookmarkedEvents, onBookmarkToggle };
}; 