import React, { useState } from 'react';
import { Input } from "./ui/Input";
import { Textarea } from "./ui/Textarea";
import { Label } from "./ui/Label";
import { Button } from "./ui/Button";
import { Event } from '../types/Event';
import { enhanceTextWithAI } from '../utils/aiUtils';

interface EventBasicInfoProps {
  event: Partial<Event>;
  onChange: (field: string, value: any) => void;
}

export const EventBasicInfo: React.FC<EventBasicInfoProps> = React.memo(({ event, onChange }) => {
  const [enhancesLeft, setEnhancesLeft] = useState(3);
  const [isEnhancing, setIsEnhancing] = useState(false);
  const togetherApiKey = (window as any)._env_?.REACT_APP_TOGETHER_API_KEY || process.env.REACT_APP_TOGETHER_API_KEY;

  const handleEnhanceDescription = async () => {
    if (enhancesLeft > 0 && event.title && event.description) {
      setIsEnhancing(true);
      try {
        const enhancedDescription = await enhanceTextWithAI(event.title, event.description);
        onChange('description', enhancedDescription);
        setEnhancesLeft(prevEnhances => prevEnhances - 1);
      } catch (error) {
        console.error("Error enhancing description:", error);
      } finally {
        setIsEnhancing(false);
      }
    }
  };

  return (
    <>
      <div className="space-y-2">
        <Label htmlFor="title">Event Title</Label>
        <Input
          id="title"
          name="title"
          value={event.title || ''}
          onChange={(e) => onChange('title', e.target.value)}
          placeholder="Enter event title"
          required
          className="border-purple-300 focus:border-purple-500 focus:ring-purple-500"
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="description">Event Description</Label>
        <div className="space-y-2">
          <Textarea
            id="description"
            name="description"
            value={event.description || ''}
            onChange={(e) => onChange('description', e.target.value)}
            placeholder="Describe your event (max 100 words)"
            rows={4}
            maxLength={500}
            required
            className="border-purple-300 focus:border-purple-500 focus:ring-purple-500 w-full"
          />
          <div className="flex justify-end items-center space-x-2">
            <span className="text-sm text-gray-600">
              Enhancements left: {enhancesLeft}
            </span>
            <Button
              type="button"
              onClick={handleEnhanceDescription}
              className="bg-purple-500 hover:bg-purple-600 text-white px-4 py-2 rounded"
              disabled={enhancesLeft === 0 || isEnhancing || !event.title || !event.description}
            >
              {isEnhancing ? 'Enhancing...' : 'Enhance Description'}
            </Button>
          </div>
          {!togetherApiKey && (
            <p className="text-red-500 text-sm mt-2">
              API key not set. Please add REACT_APP_TOGETHER_API_KEY to your .env file.
            </p>
          )}
        </div>
      </div>
    </>
  );
});
