import React from 'react';

const AnimatedBackground: React.FC = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full -z-10 overflow-hidden">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid slice"
        className="w-full h-full"
      >
        <defs>
          <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{stopColor:'#8e24aa', stopOpacity:1}}>
              <animate attributeName="stop-color" values="#8e24aa; #3f51b5; #8e24aa" dur="10s" repeatCount="indefinite" />
            </stop>
            <stop offset="100%" style={{stopColor:'#3f51b5', stopOpacity:1}}>
              <animate attributeName="stop-color" values="#3f51b5; #8e24aa; #3f51b5" dur="10s" repeatCount="indefinite" />
            </stop>
          </linearGradient>
          <linearGradient id="grad2" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{stopColor:'#ffffff', stopOpacity:0.1}} />
            <stop offset="100%" style={{stopColor:'#ffffff', stopOpacity:0}} />
          </linearGradient>
        </defs>
        
        <rect width="100%" height="100%" fill="url(#grad1)"/>
        
        <g fill="url(#grad2)">
          <circle cx="10%" cy="10%" r="8%">
            <animate attributeName="cy" values="10%;12%;10%" dur="7s" repeatCount="indefinite" />
          </circle>
          <circle cx="90%" cy="15%" r="12%">
            <animate attributeName="cx" values="90%;92%;90%" dur="8s" repeatCount="indefinite" />
          </circle>
          <circle cx="20%" cy="80%" r="15%">
            <animate attributeName="r" values="15%;17%;15%" dur="6s" repeatCount="indefinite" />
          </circle>
          <circle cx="80%" cy="80%" r="10%">
            <animate attributeName="cy" values="80%;78%;80%" dur="5s" repeatCount="indefinite" />
          </circle>
          <circle cx="50%" cy="50%" r="20%">
            <animate attributeName="r" values="20%;22%;20%" dur="10s" repeatCount="indefinite" />
          </circle>
        </g>
        
        <path d="M0,25 Q25,10 50,25 T100,25" fill="none" stroke="url(#grad2)" strokeWidth="0.2%">
          <animate attributeName="d" 
                   values="M0,25 Q25,10 50,25 T100,25;
                           M0,25 Q25,30 50,25 T100,25;
                           M0,25 Q25,10 50,25 T100,25"
                   dur="20s" repeatCount="indefinite" />
        </path>
        <path d="M0,50 Q25,35 50,50 T100,50" fill="none" stroke="url(#grad2)" strokeWidth="0.2%">
          <animate attributeName="d" 
                   values="M0,50 Q25,35 50,50 T100,50;
                           M0,50 Q25,55 50,50 T100,50;
                           M0,50 Q25,35 50,50 T100,50"
                   dur="25s" repeatCount="indefinite" />
        </path>
        <path d="M0,75 Q25,60 50,75 T100,75" fill="none" stroke="url(#grad2)" strokeWidth="0.2%">
          <animate attributeName="d" 
                   values="M0,75 Q25,60 50,75 T100,75;
                           M0,75 Q25,80 50,75 T100,75;
                           M0,75 Q25,60 50,75 T100,75"
                   dur="30s" repeatCount="indefinite" />
        </path>
      </svg>
    </div>
  );
};

export default AnimatedBackground;