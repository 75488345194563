import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import confetti from 'canvas-confetti';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "../../components/ui/Card";
import { Button } from "../../components/ui/Button";
import { Badge } from "../../components/ui/Badge";
import { Avatar, AvatarFallback } from "../../components/ui/Avatar";
import { CalendarCheck, Star, Share2, Users, ArrowRight } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { getImageUrl } from '../../utils/firebaseUtils';
import defaultEventImage from '../../assets/images/default-event-image.png';

interface LocationState {
  eventTitle: string;
  eventDate: string;
  eventImage: string;
  attendeeCount: number;
  eventId: string;
}

export const RegistrationConfirmationScreen: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, customUser } = useAuth();
  const state = location.state as LocationState;
  const [imageUrl, setImageUrl] = useState<string>(defaultEventImage);

  useEffect(() => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });

    if (state?.eventImage) {
      getImageUrl(state.eventImage)
        .then(url => setImageUrl(url))
        .catch(() => setImageUrl(defaultEventImage));
    }
  }, [state?.eventImage]);

  if (!state || !user) {
    navigate('/attendee/home');
    return null;
  }

  const { eventTitle, eventDate, attendeeCount, eventId } = state;
  const userName = customUser?.displayName || user.displayName || 'Attendee';

  const handleImageError = () => {
    setImageUrl(defaultEventImage);
  };

  return (
    <div className="container mx-auto px-4 py-8 flex items-center justify-center min-h-screen bg-gradient-to-b from-purple-50 to-white">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md"
      >
        <Card className="overflow-hidden">
          <div className="relative h-40">
            <img 
              src={imageUrl} 
              alt={eventTitle} 
              className="w-full h-full object-cover"
              onError={handleImageError}
            />
            <div className="absolute inset-0 bg-gradient-to-t from-purple-900 to-transparent" />
            <div className="absolute bottom-4 left-4 right-4 flex justify-between items-end">
              <h1 className="text-2xl font-bold text-white">You're In!</h1>
              <Badge variant="secondary" className="bg-purple-100 text-purple-800">
                Registered
              </Badge>
            </div>
          </div>
          <CardContent className="pt-6 px-6 pb-4">
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ type: "spring", stiffness: 260, damping: 20, delay: 0.2 }}
              className="flex justify-center mb-6"
            >
              <Avatar className="w-24 h-24 border-4 border-purple-100">
                <AvatarFallback className="bg-purple-200 text-purple-800 text-3xl font-bold">
                  {userName.split(' ').map(n => n[0]).join('')}
                </AvatarFallback>
              </Avatar>
            </motion.div>
            <h2 className="text-xl font-semibold text-center mb-4">
              Welcome aboard, {userName}!
            </h2>
            <p className="text-center text-gray-600 mb-6">
              You're officially registered for <span className="font-semibold text-purple-800">{eventTitle}</span>
            </p>
            <div className="flex justify-center items-center mb-6">
              <CalendarCheck className="w-5 h-5 text-purple-600 mr-2" />
              <span className="text-sm text-gray-600">{eventDate}</span>
            </div>
            <div className="flex justify-center space-x-2 mb-6">
              {[1, 2, 3, 4, 5].map((star) => (
                <Star key={star} className="w-6 h-6 text-yellow-400 fill-current" />
              ))}
            </div>
            <div className="flex justify-center items-center mb-6">
              <Users className="w-5 h-5 text-purple-600 mr-2" />
              <span className="text-sm text-gray-600">Joining {attendeeCount} other attendees</span>
            </div>
            <p className="text-center text-sm text-gray-600 mb-6">
              You've joined an exclusive group of attendees. Get ready for an amazing experience!
            </p>
          </CardContent>
          <CardFooter className="flex flex-col space-y-4 bg-purple-50 p-6">
            <Button className="w-full bg-purple-600 hover:bg-purple-700 text-white">
              <Share2 className="w-4 h-4 mr-2" />
              Share Your Excitement
            </Button>
            <Button variant="outline" className="w-full" onClick={() => navigate(`/event/${eventId}`)}>
              View Event Details
              <ArrowRight className="w-4 h-4 ml-2" />
            </Button>
          </CardFooter>
        </Card>
      </motion.div>
    </div>
  );
};
