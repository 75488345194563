import { useState, useCallback, useEffect, useMemo } from 'react';
import { collection, query, where, getDocs, Timestamp, Query, DocumentData } from 'firebase/firestore';
import { db } from '../../../config/firebase';
import { Event } from '../../../types/Event';
import { SearchCriteria } from '../../../types/search';

export const useEvents = (
  searchCriteria: SearchCriteria,
  bookmarkedEvents: Set<string>,
  user: any
) => {
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [baseEvents, setBaseEvents] = useState<Event[]>([]);

  // Fetch base events only once or when categories/prices change
  const fetchBaseEvents = useCallback(async () => {
    setLoading(true);
    try {
      const eventsRef = collection(db, 'events');
      const now = Timestamp.now();

      const baseQueries: Query<DocumentData>[] = [];

      // Single day events query
      let singleDayQuery = query(
        eventsRef,
        where('isPublic', '==', true),
        where('isSingleDay', '==', true),
        where('date', '>=', now)
      );

      // Multi-day events query
      let multiDayQuery = query(
        eventsRef,
        where('isPublic', '==', true),
        where('isSingleDay', '==', false),
        where('toDate', '>=', now)
      );

      // Apply category filter if selected
      if (searchCriteria.selectedCategories.length > 0) {
        singleDayQuery = query(singleDayQuery, 
          where('categories', 'array-contains-any', searchCriteria.selectedCategories)
        );
        multiDayQuery = query(multiDayQuery, 
          where('categories', 'array-contains-any', searchCriteria.selectedCategories)
        );
      }

      // Apply price filter if selected
      if (searchCriteria.selectedPrices.length > 0) {
        if (searchCriteria.selectedPrices.includes('free') && 
            !searchCriteria.selectedPrices.includes('paid')) {
          singleDayQuery = query(singleDayQuery, where('isFree', '==', true));
          multiDayQuery = query(multiDayQuery, where('isFree', '==', true));
        } else if (!searchCriteria.selectedPrices.includes('free') && 
                   searchCriteria.selectedPrices.includes('paid')) {
          singleDayQuery = query(singleDayQuery, where('isFree', '==', false));
          multiDayQuery = query(multiDayQuery, where('isFree', '==', false));
        }
      }

      baseQueries.push(singleDayQuery, multiDayQuery);

      const snapshots = await Promise.all(baseQueries.map(q => getDocs(q)));
      const allEvents = snapshots.flatMap(snapshot => 
        snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        } as Event))
      );

      setBaseEvents(allEvents);
    } catch (error) {
      console.error('Error fetching base events:', error);
      setBaseEvents([]);
    } finally {
      setLoading(false);
    }
  }, [searchCriteria.selectedCategories, searchCriteria.selectedPrices]);

  // Apply client-side filters
  const filteredEvents = useMemo(() => {
    let filtered = [...baseEvents];

    // Apply date range filter
    if (searchCriteria.dateRange) {
      const { start, end } = searchCriteria.dateRange;
      filtered = filtered.filter(event => {
        const eventDate = event.isSingleDay ? 
          event.date?.toDate() : 
          event.fromDate?.toDate();
        return eventDate && eventDate >= start && eventDate <= end;
      });
    }

    // Apply location filter
    if (searchCriteria.selectedLocations.length > 0) {
      filtered = filtered.filter(event => 
        event.location && searchCriteria.selectedLocations.includes(event.location)
      );
    }

    // Apply bookmarks filter
    if (searchCriteria.showBookmarked && user) {
      filtered = filtered.filter(event => bookmarkedEvents.has(event.id));
    }

    return filtered;
  }, [baseEvents, searchCriteria.dateRange, searchCriteria.selectedLocations, 
      searchCriteria.showBookmarked, bookmarkedEvents, user]);

  // Update events when filters change
  useEffect(() => {
    setEvents(filteredEvents);
  }, [filteredEvents]);

  // Fetch base events on mount and when major filters change
  useEffect(() => {
    fetchBaseEvents();
  }, [fetchBaseEvents]);

  return { 
    events, 
    loading, 
    fetchEvents: fetchBaseEvents 
  };
}; 