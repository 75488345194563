import React from 'react';
import { CreateEventForm } from '../../components/organizer/CreateEventForm';

export const CreateEventPage: React.FC = () => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-8 text-center text-purple-800">Create Event</h1>
      <CreateEventForm />
    </div>
  );
};