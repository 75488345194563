import React from 'react';

interface ConfirmationModalProps {
  message: string;
  onClose: () => void;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ message, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-4 rounded-lg shadow-lg">
        <p>{message}</p>
        <button onClick={onClose} className="mt-4 bg-primary text-white p-2 rounded-lg">
          Close
        </button>
      </div>
    </div>
  );
};
