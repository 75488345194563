import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Event } from '../../types/Event';
import { useAuth } from '../../contexts/AuthContext';
import { Button } from "../../components/ui/Button";
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/Card";
import { ref, uploadBytesResumable, getDownloadURL, uploadString } from 'firebase/storage';
import { storage } from '../../config/firebase';
import { FirebaseError } from 'firebase/app';
import { Timestamp, updateDoc, doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';

import { EventBasicInfo } from '../EventBasicInfo';
import { EventTypeSelection } from '../EventTypeSelection';
import { EventVisibilitySelection } from '../EventVisibilitySelection';
import { EventLocationInput } from '../EventLocationInput';
import { EventDurationSelection } from '../EventDurationSelection';
import { EventDateTimeInputs } from '../EventDateTimeInputs';
import { EventFrequencySelection } from '../EventFrequencySelection';
import { EventPriceSelection } from '../EventPriceSelection';
import { EventSkillInput } from '../EventSkillInput';
import { EventImageUpload } from '../EventImageUpload';
import { useEventValidation } from '../../hooks/useEventValidation';
import { useModalMessage } from '../../hooks/useModalMessage';
import { ConfirmationModal } from '../common/ConfirmationModal';
import { Textarea } from "../ui/Textarea";

type UpdatedEvent = Event & { imageData?: string };

export const EditEventForm: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const [event, setEvent] = useState<Partial<UpdatedEvent>>({});
  const [loading, setLoading] = useState(true);
  const [attendeeMessage, setAttendeeMessage] = useState('');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const navigate = useNavigate();
  const { validateEvent } = useEventValidation();
  const { modalMessage, isModalOpen, showModal, closeModal } = useModalMessage();
  const { user: currentUser } = useAuth();

  useEffect(() => {
    const fetchEvent = async () => {
      if (!eventId) {
        console.log('No eventId provided');
        return;
      }
      try {
        console.log('Fetching event with ID:', eventId);
        setLoading(true);
        const eventDoc = await getDoc(doc(db, 'events', eventId));
        if (eventDoc.exists()) {
          console.log('Event data fetched:', eventDoc.data());
          setEvent(eventDoc.data() as UpdatedEvent);
        } else {
          console.log('Event not found');
          showModal('Event not found.');
        }
      } catch (error) {
        console.error('Error fetching event:', error);
        showModal('Failed to fetch event details.');
      } finally {
        console.log('Setting loading to false');
        setLoading(false);
      }
    };

    fetchEvent();

    // Add a timeout to set loading to false after 10 seconds
    const timeoutId = setTimeout(() => {
      setLoading(false);
      console.log('Loading timeout reached');
    }, 10000);

    return () => clearTimeout(timeoutId);
  }, [eventId]); // Add eventId as a dependency

  const handleChange = useCallback((field: string, value: any) => {
    if (field === 'imageFile') {
      setImageFile(value);
    } else {
      setEvent((prevEvent) => ({ ...prevEvent, [field]: value }));
    }
  }, []);

  const handleImageUpload = useCallback((fileOrData: File | string) => {
    if (typeof fileOrData === 'string') {
      setEvent(prevEvent => ({ ...prevEvent, imageData: fileOrData }));
    } else {
      setImageFile(fileOrData);
    }
  }, []);

  const onSubmit = async () => {
    try {
      setLoading(true);
      
      if (!currentUser) {
        showModal('You must be logged in to edit an event.');
        return;
      }

      if (!eventId) {
        showModal('Event ID is missing.');
        return;
      }

      let imageUrl = event.imageUrl;
      if (imageFile) {
        imageUrl = await uploadImage(imageFile);
      } else if (event.imageData) {
        imageUrl = await uploadImage(event.imageData);
      }

      const now = Timestamp.now();

      const updatedEvent: Partial<UpdatedEvent> = {
        ...event,
        updatedAt: now,
        imageUrl,
      };

      if (!validateEvent(updatedEvent)) {
        showModal('Invalid event data. Please check all fields.');
        return;
      }

      await updateDoc(doc(db, 'events', eventId), updatedEvent);

      if (attendeeMessage) {
        // Here you would implement the logic to send the message to attendees
        console.log('Sending message to attendees:', attendeeMessage);
      }

      showModal('Event updated successfully!');
      navigate('/organizer/dashboard');
    } catch (error) {
      console.error("Error in onSubmit:", error);
      if (error instanceof FirebaseError) {
        showModal(`Failed to update event: ${error.message}`);
      } else if (error instanceof Error) {
        showModal(`Failed to update event: ${error.message}`);
      } else {
        showModal('Failed to update event. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const uploadImage = async (fileOrData: File | string): Promise<string> => {
    const uniqueId = event.id || '';
    const storageRef = ref(storage, `images/${uniqueId}.jpg`);
    
    if (typeof fileOrData === 'string') {
      await uploadString(storageRef, fileOrData, 'base64', { contentType: 'image/jpeg' });
    } else {
      await uploadBytesResumable(storageRef, fileOrData);
    }
    
    return getDownloadURL(storageRef);
  };

  const eventBasicInfoProps = useMemo(() => ({
    event,
    onChange: handleChange
  }), [event, handleChange]);

  console.log('EditEventForm rendered');
  console.log('Current loading state:', loading);
  console.log('Current event state:', event);

  if (loading) {
    return <div className="flex items-center justify-center h-screen">Loading event details...</div>;
  }

  if (!event.id) {
    return <div className="flex items-center justify-center h-screen">Event not found</div>;
  }

  return (
    <Card className="max-w-2xl mx-auto bg-purple-50">
      <CardHeader>
        <CardTitle className="text-2xl font-bold text-center text-purple-800">
          Edit Event: {event.title}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <form onSubmit={(e) => { e.preventDefault(); onSubmit(); }} className="space-y-6">
          <EventBasicInfo {...eventBasicInfoProps} />
          <EventTypeSelection event={event} onChange={handleChange} />
          <EventVisibilitySelection event={event} onChange={handleChange} />
          {!event.isOnline && <EventLocationInput event={event} onChange={handleChange} />}
          <EventDurationSelection event={event} onChange={handleChange} />
          <EventDateTimeInputs event={event} onChange={handleChange} />
          <EventFrequencySelection event={event} onChange={handleChange} />
          <EventPriceSelection event={event} onChange={handleChange} />
          <EventSkillInput event={event} onChange={handleChange} />
          <EventImageUpload 
            onUpload={handleImageUpload} 
            eventTitle={event.title || ''} 
            eventDescription={event.description || ''}
          />

          <div className="space-y-2">
            <label htmlFor="attendeeMessage" className="block text-sm font-medium text-gray-700">
              Message for Attendees
            </label>
            <Textarea
              id="attendeeMessage"
              value={attendeeMessage}
              onChange={(e) => setAttendeeMessage(e.target.value)}
              placeholder="Enter a message for event attendees (optional)"
              rows={4}
              className="mt-1 block w-full border-purple-300 rounded-md shadow-sm focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
            />
          </div>

          <Button type="submit" className="w-full bg-purple-600 hover:bg-purple-700 text-white">
            Update Event
          </Button>
        </form>
      </CardContent>
      {isModalOpen && modalMessage && <ConfirmationModal message={modalMessage} onClose={closeModal} />}
    </Card>
  );
};
