import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../components/ui/Button';
import { Card, CardContent } from '../components/ui/Card';
import { CheckCircleIcon, ArrowRightIcon, ChevronDownIcon, ChevronUpIcon } from 'lucide-react';

const FAQItem: React.FC<{ question: string; answer: string }> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Card className="bg-white shadow-md hover:shadow-lg transition-shadow duration-300">
      <CardContent className="p-6">
        <button
          className="w-full text-left flex justify-between items-center"
          onClick={() => setIsOpen(!isOpen)}
        >
          <h3 className="text-lg font-semibold text-[rgb(124,58,237)]">{question}</h3>
          {isOpen ? <ChevronUpIcon className="h-5 w-5" /> : <ChevronDownIcon className="h-5 w-5" />}
        </button>
        {isOpen && <p className="mt-4 text-gray-600">{answer}</p>}
      </CardContent>
    </Card>
  );
};

const PricingPage: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-1">
        <section className="w-full py-20 md:py-32 bg-purple-100 text-[#3498db]">
          <div className="container px-4 md:px-6 mx-auto text-center">
            <h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl md:text-6xl mb-6">
              It's Free to Get Started
            </h1>
            <p className="mx-auto max-w-[700px] text-xl mb-10 text-gray-600">
              No upfront costs, no hidden fees. Start creating and managing your events with ease.
            </p>
          </div>
        </section>

        <section className="w-full py-20 bg-white">
  <div className="container px-4 md:px-6 mx-auto text-center">
    <h2 className="text-3xl font-bold tracking-tight sm:text-4xl md:text-5xl mb-12 text-[rgb(124,58,237)]">
      Our Simple Pricing Model
    </h2>
    <Card className="max-w-2xl mx-auto bg-purple-50 shadow-lg hover:shadow-xl transition-shadow duration-300">
      <CardContent className="p-8">
        <h3 className="text-2xl font-semibold mb-6 text-[rgb(124,58,237)]">Pay Only When You Sell</h3>
        
        <ul className="mb-8 space-y-4">
          {[
            "We charge a small 5% fee on each ticket sold. No monthly fees, no setup costs.",
            "Create unlimited events",
            "Access to all features",
            "24/7 customer support",
            "Secure payment processing",
            "Detailed analytics and reporting"
          ].map((feature, index) => (
            <li key={index} className="flex items-center justify-center">
              <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
              <span className="text-center">{feature}</span>
            </li>
          ))}
        </ul>
        
        <div className="flex justify-center">
          <Link to="/signup">
            <Button variant="primary" className="w-full sm:w-auto">
              Get Started <ArrowRightIcon className="ml-2 h-5 w-5" />
            </Button>
          </Link>
        </div>
      </CardContent>
    </Card>
  </div>
</section>
        <section className="w-full py-20 bg-purple-100">
          <div className="container px-4 md:px-6 mx-auto text-center">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl md:text-5xl mb-6 text-[rgb(124,58,237)]">
              Frequently Asked Questions
            </h2>
            <div className="max-w-3xl mx-auto mt-8 space-y-4">
              {[
                {
                  question: "Are there any hidden fees?",
                  answer: "No, we only charge the 5% per transaction fee. There are no hidden costs or additional charges."
                },
                {
                  question: "How does the payment process work?",
                  answer: "We handle the payment processing for you. When an attendee purchases a ticket, we collect the payment, deduct our 5% fee, and transfer the remaining amount to you."
                },
                {
                  question: "Can I try EventAtEase before committing?",
                  answer: "Absolutely! You can sign up and create events for free. You only pay when you start selling tickets."
                },
                {
                  question: "Is there a minimum ticket price?",
                  answer: "No, you can set any price for your tickets, including free events. Our 5% fee only applies to paid tickets."
                }
              ].map((faq, index) => (
                <FAQItem key={index} question={faq.question} answer={faq.answer} />
              ))}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default PricingPage;