import React, { useRef } from 'react';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';

interface LocationInputProps {
  value: string;
  onChange: (address: string, placeId: string) => void;
}

const LocationInput: React.FC<LocationInputProps> = ({ value, onChange }) => {
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    libraries: ['places'],
  });

  if (loadError) return <div className="text-red-600">Error loading maps</div>;
  if (!isLoaded) return <div className="text-purple-600">Loading...</div>;

  return (
    <Autocomplete
      onLoad={(autocomplete) => {
        autocompleteRef.current = autocomplete;
        autocomplete.setFields(['formatted_address', 'place_id']);
      }}
      onPlaceChanged={() => {
        if (autocompleteRef.current) {
          const place = autocompleteRef.current.getPlace();
          if (place.formatted_address && place.place_id) {
            onChange(place.formatted_address, place.place_id);
          }
        }
      }}
    >
      <input
        type="text"
        placeholder="Enter location"
        value={value}
        onChange={(e) => onChange(e.target.value, '')}
        className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
      />
    </Autocomplete>
  );
};

export default LocationInput;