import { User as FirebaseUser } from '@firebase/auth';

// Base interface for our custom user properties
export interface CustomUser {
  gender: string;
  dateOfBirth: Date;
  eventsAttended: string[];
  eventsCreated: string[];
  bankAccountDetails: {
    accountNumber: string;
    bankName: string;
    ifscCode: string;
    isVerified: boolean;
  };
  upiDetails: string;
  upiVerified: boolean;
  subscribers: string[];
  isOrganizer: boolean;
  profilePicture: string;
  phone: string;
  phoneVerified: boolean;
  createdAt: Date;
  lastLogin: Date;
  bookmarkedEvents: string[];
  razorpayContactCreated: boolean;
  razorpayContactId?: string;
  fundAccounts: FundAccount[];
  maxFundAccounts: number;
}

// Combine Firebase User with our custom properties
export type User = FirebaseUser & CustomUser;

export interface BankAccountDetails {
  name: string;
  ifsc: string;
  account_number: string;
}

export interface VPADetails {
  address: string;
}

export interface FundAccount {
  id: string;
  type: 'bank_account' | 'vpa';
  active: boolean;
  details: BankAccountDetails | VPADetails;
}

// Add these type guard functions
export function isBankAccountDetails(
  details: BankAccountDetails | VPADetails
): details is BankAccountDetails {
  return 'account_number' in details;
}

export function isVPADetails(
  details: BankAccountDetails | VPADetails
): details is VPADetails {
  return 'address' in details;
}
