import React, { createContext, useContext } from 'react';

interface RadioGroupContextType {
  value: string;
  onChange: (value: string) => void;
}

const RadioGroupContext = createContext<RadioGroupContextType | undefined>(undefined);

interface RadioGroupProps {
  value: string;
  onChange: (value: string) => void;
  children: React.ReactNode;
  className?: string;
}

export const RadioGroup: React.FC<RadioGroupProps> = ({ value, onChange, children, className }) => {
  return (
    <RadioGroupContext.Provider value={{ value, onChange }}>
      <div className={`grid gap-2 ${className || ''}`}>{children}</div>
    </RadioGroupContext.Provider>
  );
};

interface RadioGroupItemProps {
  value: string;
  children?: React.ReactNode;
}

export const RadioGroupItem: React.FC<RadioGroupItemProps> = ({ value, children }) => {
  const context = useContext(RadioGroupContext);
  if (!context) throw new Error('RadioGroupItem must be used within a RadioGroup');

  const { value: groupValue, onChange } = context;
  const checked = value === groupValue;

  return (
    <label className="flex items-center cursor-pointer">
      <input
        type="radio"
        value={value}
        checked={checked}
        onChange={() => onChange(value)}
        className="sr-only"
      />
      <div className={`w-4 h-4 rounded-full border border-purple-500 mr-2 ${checked ? 'bg-purple-500' : ''}`} />
      {children}
    </label>
  );
};