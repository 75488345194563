import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../components/ui/Button';
import { Card, CardContent } from '../components/ui/Card';
import { CalendarIcon, MapPinIcon, CreditCardIcon, UsersIcon, SearchIcon, BarChartIcon, RepeatIcon, ClipboardIcon, ArrowRightIcon, BookOpenIcon, CheckCircleIcon } from 'lucide-react';

const FeaturesPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState('organizers');

  const renderTabContent = (tab: string) => {
    const steps = tab === 'organizers'
      ? [
          { icon: CalendarIcon, text: "Create your event with ease - set details, pricing, and capacity." },
          { icon: MapPinIcon, text: "Add location details and sync with your calendar effortlessly." },
          { icon: CreditCardIcon, text: "Set up secure payment options for ticket sales." },
          { icon: UsersIcon, text: "Manage attendees, send updates, and track event success." }
        ]
      : [
          { icon: BookOpenIcon, text: "Discover local events that match your interests." },
          { icon: CreditCardIcon, text: "Register and pay securely using your preferred payment method." },
          { icon: MapPinIcon, text: "Get directions and add events to your calendar with one click." },
          { icon: CheckCircleIcon, text: "Enjoy the event and easily provide feedback afterwards." }
        ];

    return (
      <div className="mt-8 grid gap-6 md:grid-cols-2">
        {steps.map((step, index) => (
          <Card key={index} className="bg-white shadow-lg hover:shadow-xl transition-shadow duration-300">
            <CardContent className="p-6 flex items-start space-x-4">
              <div className="flex-shrink-0 w-12 h-12 rounded-full bg-purple-500 text-white flex items-center justify-center">
                <step.icon className="w-6 h-6" />
              </div>
              <div>
                <h3 className="font-semibold text-lg mb-2">Step {index + 1}</h3>
                <p className="text-gray-600">{step.text}</p>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    );
  };

  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-1">
        <section className="w-full py-20 md:py-32 bg-purple-100 text-[#3498db]">
          <div className="container px-4 md:px-6 mx-auto text-center">
            <h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl md:text-6xl mb-6">
              Powerful Features for Seamless Events
            </h1>
            <p className="mx-auto max-w-[700px] text-xl mb-10 text-gray-600">
              Discover how EventAtEase simplifies event management and enhances attendee experiences.
            </p>
            <div className="flex justify-center">
              <Link to="/signup">
                <Button variant="primary" className="w-full sm:w-auto">Get Started</Button>
              </Link>
            </div>
          </div>
        </section>

        <section className="w-full py-20 bg-white">
          <div className="container px-4 md:px-6 mx-auto text-center">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl md:text-5xl mb-12 text-[rgb(124,58,237)]">
              Key Features
            </h2>
            <div className="grid gap-10 sm:grid-cols-2 lg:grid-cols-4">
              {[
                { icon: CalendarIcon, title: "Event Creation", description: "Easily create and customize events." },
                { icon: SearchIcon, title: "Event Discovery", description: "Browse through a wide range of local events." },
                { icon: CreditCardIcon, title: "Secure Payments", description: "Integrated payment system for tickets." },
                { icon: MapPinIcon, title: "Location Services", description: "Built-in map integration for easy navigation." },
              ].map((feature, index) => (
                <Card key={index} className="bg-purple-50 shadow-md hover:shadow-lg transition-shadow duration-300">
                  <CardContent className="p-6 flex flex-col items-center text-center">
                    <feature.icon className="h-12 w-12 text-[rgb(124,58,237)] mb-4" />
                    <h3 className="text-xl font-semibold mb-2 text-[rgb(124,58,237)]">{feature.title}</h3>
                    <p className="text-gray-600">{feature.description}</p>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </section>

        <section className="w-full py-20 bg-purple-100">
          <div className="container px-4 md:px-6 mx-auto text-center">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl mb-8">
              How It Works
            </h2>
            <div className="w-full max-w-3xl mx-auto">
              <div className="flex justify-center mb-4">
                <button
                  className={`px-4 py-2 ${activeTab === 'organizers' ? 'bg-purple-500 text-white' : 'bg-white text-purple-500'} rounded-l-md`}
                  onClick={() => setActiveTab('organizers')}
                >
                  For Organizers
                </button>
                <button
                  className={`px-4 py-2 ${activeTab === 'attendees' ? 'bg-purple-500 text-white' : 'bg-white text-purple-500'} rounded-r-md`}
                  onClick={() => setActiveTab('attendees')}
                >
                  For Attendees
                </button>
              </div>
              {renderTabContent(activeTab)}
            </div>
          </div>
        </section>

        <section className="w-full py-20 bg-white">
          <div className="container px-4 md:px-6 mx-auto text-center">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl md:text-5xl mb-12 text-[rgb(124,58,237)]">
              Additional Features
            </h2>
            <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
              {[
                { icon: UsersIcon, title: "Attendee Management" },
                { icon: BarChartIcon, title: "Analytics & Insights" },
                { icon: RepeatIcon, title: "Recurring Events" },
                { icon: ClipboardIcon, title: "Custom Registration Forms" },
                { icon: CalendarIcon, title: "Calendar Integration" },
              ].map((item, index) => (
                <Card key={index} className="bg-purple-50 shadow-md hover:shadow-lg transition-shadow duration-300">
                  <CardContent className="p-6 flex items-center space-x-4">
                    <item.icon className="h-10 w-10 text-[rgb(124,58,237)]" />
                    <span className="text-lg font-semibold text-[rgb(124,58,237)]">{item.title}</span>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </section>

        <section className="w-full py-20 bg-purple-100">
          <div className="container px-4 md:px-6 mx-auto text-center">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl md:text-5xl mb-6 text-[rgb(124,58,237)]">
              Ready to elevate your events?
            </h2>
            <p className="mx-auto max-w-[600px] text-xl mb-10 text-gray-600">
              Join EventAtEase today and start creating memorable experiences with our powerful features.
            </p>
            <div className="flex justify-center">
              <Link to="/signup">
                <Button variant="default" className="bg-[rgb(124,58,237)] text-white hover:bg-[rgb(144,78,257)]">
                  Sign Up Now <ArrowRightIcon className="ml-2 h-5 w-5" />
                </Button>
              </Link>
            </div>
            <p className="mt-4 text-sm text-gray-600">
              By signing up, you agree to our{" "}
              <Link className="underline underline-offset-2 hover:text-[rgb(124,58,237)]" to="/terms">
                Terms & Conditions
              </Link>
            </p>
          </div>
        </section>
      </main>
    </div>
  );
};

export default FeaturesPage;