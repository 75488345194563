import React from 'react';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({ label, ...props }) => (
  <label className="flex items-center">
    <input type="checkbox" className="form-checkbox h-5 w-5 text-purple-600" {...props} />
    {label && <span className="ml-2 text-gray-700">{label}</span>}
  </label>
);