import React, { useState } from 'react';
import { BookmarkIcon, ShareIcon } from "../../../utils/icons";
import { FaWhatsapp, FaFacebookF, FaTwitter } from 'react-icons/fa';

interface EventHeaderProps {
  title: string;
  imageUrl: string;
  onImageError: () => void;
  isBookmarked?: boolean;
  onBookmarkToggle?: () => void;
}

export const EventHeader: React.FC<EventHeaderProps> = ({ 
  title, 
  imageUrl, 
  onImageError,
  isBookmarked,
  onBookmarkToggle
}) => {
  const [showShareModal, setShowShareModal] = useState(false);

  const handleShare = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const shareData = {
      title: title,
      text: `Check out this event: ${title}`,
      url: window.location.href,
    };

    if (navigator.share) {
      try {
        await navigator.share(shareData);
      } catch (err) {
        console.error('Error sharing:', err);
      }
    } else {
      setShowShareModal(true);
    }
  };

  return (
    <div className="relative">
      <img
        src={imageUrl}
        alt={title}
        className="w-full h-64 object-cover"
        onError={onImageError}
      />
      {/* Only show action buttons if bookmark handler is provided */}
      {onBookmarkToggle && (
        <div className="absolute top-4 right-4 flex space-x-2">
          <button
            className={`p-2 rounded-full transition-colors duration-300 ${
              isBookmarked 
                ? 'bg-purple-500 text-white' 
                : 'bg-white text-purple-500 hover:bg-purple-100'
            }`}
            onClick={onBookmarkToggle}
          >
            <BookmarkIcon className={`w-6 h-6 ${isBookmarked ? 'fill-current' : 'stroke-current'}`} />
            <span className="sr-only">{isBookmarked ? 'Remove bookmark' : 'Bookmark event'}</span>
          </button>
          <button
            className="p-2 rounded-full bg-white text-purple-500 hover:bg-purple-100 transition-colors duration-300"
            onClick={handleShare}
          >
            <ShareIcon className="w-6 h-6" />
            <span className="sr-only">Share event</span>
          </button>
        </div>
      )}
      <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-purple-900 to-transparent p-6">
        <h1 className="text-3xl font-bold text-white">{title}</h1>
      </div>

      {showShareModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-sm w-full">
            <h3 className="text-lg font-semibold mb-4">Share this event</h3>
            <div className="flex justify-around mb-6">
              <a 
                href={`https://wa.me/?text=${encodeURIComponent(`Check out this event: ${title} ${window.location.href}`)}`} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-green-500 hover:text-green-600 transition-colors duration-200"
              >
                <FaWhatsapp size={24} />
                <span className="sr-only">Share on WhatsApp</span>
              </a>
              <a 
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-blue-600 hover:text-blue-700 transition-colors duration-200"
              >
                <FaFacebookF size={24} />
                <span className="sr-only">Share on Facebook</span>
              </a>
              <a 
                href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(`Check out this event: ${title}`)}&url=${encodeURIComponent(window.location.href)}`} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-blue-400 hover:text-blue-500 transition-colors duration-200"
              >
                <FaTwitter size={24} />
                <span className="sr-only">Share on Twitter</span>
              </a>
            </div>
            <button 
              onClick={() => setShowShareModal(false)} 
              className="w-full bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600 transition-colors duration-200"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};