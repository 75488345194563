import React, { useRef } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Event } from '../types/Event';
import EventCard from './EventCard';

interface CategoryScrollProps {
  title: string;
  events: Event[];
  onBookmarkToggle: (eventId: string, isBookmarked: boolean) => void;
  bookmarkedEvents: Set<string>;
  userId: string;
}

const CategoryScroll: React.FC<CategoryScrollProps> = ({
  title,
  events,
  onBookmarkToggle,
  bookmarkedEvents,
  userId
}) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const scroll = (direction: 'left' | 'right') => {
    if (scrollContainerRef.current) {
      const scrollAmount = direction === 'left' ? -400 : 400;
      scrollContainerRef.current.scrollBy({
        left: scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="mb-8">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-purple-800">{title}</h2>
        <div className="flex gap-2">
          <button
            onClick={() => scroll('left')}
            className="p-2 rounded-full bg-purple-100 text-purple-600 hover:bg-purple-200 transition-colors"
          >
            <ChevronLeft className="w-6 h-6" />
          </button>
          <button
            onClick={() => scroll('right')}
            className="p-2 rounded-full bg-purple-100 text-purple-600 hover:bg-purple-200 transition-colors"
          >
            <ChevronRight className="w-6 h-6" />
          </button>
        </div>
      </div>
      <div
        ref={scrollContainerRef}
        className="flex gap-6 overflow-x-auto pb-4 hide-scrollbar"
      >
        {events.map(event => (
          <div key={event.id} className="flex-none w-72 md:w-80">
            <EventCard
              event={event}
              onBookmarkToggle={onBookmarkToggle}
              isBookmarked={bookmarkedEvents.has(event.id)}
              linkTo={`/event/${event.id}`}
              userId={userId}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryScroll;