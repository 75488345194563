import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { SearchProvider } from './contexts/SearchContext';
import { HeaderManager } from './components/HeaderManager';
import AppRoutes from './AppRoutes';
import { NotificationProvider } from './contexts/NotificationContext';
import { SEO } from './components/SEO';
import { AuthProvider } from './contexts/AuthContext';

const App: React.FC = () => {
  return (
    <AuthProvider>
    <NotificationProvider>
      <SearchProvider>
        <Router>
          <SEO />
          <HeaderManager />
          <AppRoutes />
        </Router>
      </SearchProvider>
    </NotificationProvider>
    </AuthProvider>
  );
};

export default App;
