import { useState, useCallback, useEffect } from 'react';

const GOLDEN_RATIO = 1.618;
const BASE_UNIT = 8;
const MIN_SEARCH_WIDTH = 280;
const MAX_SEARCH_WIDTH = 800;

export const useSearchBarSpacing = () => {
  const getSearchBarSpacing = useCallback(() => {
    const vw = window.innerWidth;
    const vh = window.innerHeight;
    
    // Responsive scale factor based on viewport
    const scaleFactor = Math.max(0.8, Math.min(1.2, vw / 1440));
    const baseUnit = BASE_UNIT * scaleFactor;
    
    return {
      container: {
        maxWidth: Math.min(MAX_SEARCH_WIDTH, Math.max(MIN_SEARCH_WIDTH, vw * 0.85)),
        padding: `${baseUnit * 3}px`, // 24px base
        borderRadius: `${baseUnit * 2}px`, // 16px base
      },
      searchInput: {
        height: `${baseUnit * 5.5}px`, // 44px base
        fontSize: `${baseUnit * 1.75}px`, // 14px base
        paddingLeft: `${baseUnit * 6}px`, // 48px base
        paddingRight: `${baseUnit * 5}px`, // 40px base
        iconSize: `${baseUnit * 2.5}px`, // 20px base
      },
      filterBadges: {
        gap: `${baseUnit * 2}px`, // 16px base
        marginTop: `${baseUnit * 3}px`, // 24px base
        height: `${baseUnit * 5}px`, // 40px base
        padding: `${baseUnit * 1.5}px ${baseUnit * 2}px`, // 12px 16px base
        fontSize: `${baseUnit * 1.625}px`, // 13px base
        iconSize: `${baseUnit * 2}px`, // 16px base
      },
      clearButton: {
        size: `${baseUnit * 2.5}px`, // 20px base
        offset: `${baseUnit * 0.75}px`, // 6px base
      }
    };
  }, []);

  const [spacing, setSpacing] = useState(getSearchBarSpacing());

  useEffect(() => {
    const handleResize = () => {
      setSpacing(getSearchBarSpacing());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [getSearchBarSpacing]);

  return spacing;
}; 