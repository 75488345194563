import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../Dialog";
import { Button } from "../Button";
import { Switch } from "../Switch";
import { DayPicker, DateRange } from 'react-day-picker';
import { Label } from "../Label";
import { CalendarIcon } from 'lucide-react';

// Conditionally import the CSS
if (process.env.NODE_ENV !== 'test') {
  require('react-day-picker/dist/style.css');
}

interface DateDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  onDateSelect: (dateRange: { start: Date; end: Date }) => void;
}

// Extend component types
interface ExtendedDialogContentProps extends React.ComponentProps<typeof DialogContent> {
  className?: string;
}

interface ExtendedDialogTitleProps extends React.ComponentProps<typeof DialogTitle> {
  className?: string;
}

interface ExtendedSwitchProps extends React.ComponentProps<typeof Switch> {
  className?: string;
}

export const DateDialog: React.FC<DateDialogProps> = ({ isOpen, onOpenChange, onDateSelect }) => {
  const [dateRange, setDateRange] = useState<DateRange | undefined>(undefined);
  const [isRangeSelection, setIsRangeSelection] = useState(false);

  // Extended components
  const DialogContentExtended = DialogContent as React.FC<ExtendedDialogContentProps>;
  const DialogTitleExtended = DialogTitle as React.FC<ExtendedDialogTitleProps>;
  const SwitchExtended = Switch as React.FC<ExtendedSwitchProps>;

  useEffect(() => {
    if (!isOpen) {
      setDateRange(undefined);
    }
  }, [isOpen]);

  const handleDateSelect = (selectedDate: Date | DateRange | undefined) => {
    if (selectedDate) {
      if (isRangeSelection && 'from' in selectedDate) {
        setDateRange(selectedDate);
      } else if (!isRangeSelection && selectedDate instanceof Date) {
        setDateRange({ from: selectedDate });
      }
    }
  };

  const handleApply = () => {
    if (dateRange?.from) {
      onDateSelect({
        start: dateRange.from,
        end: isRangeSelection && dateRange.to ? dateRange.to : dateRange.from
      });
      onOpenChange(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContentExtended className="sm:max-w-[400px] p-6 bg-white/95 rounded-xl overflow-hidden">
        <DialogHeader>
          <div className="flex flex-col items-center space-y-4">
            <DialogTitleExtended className="text-xl font-bold bg-gradient-to-r from-purple-600 to-purple-400 bg-clip-text text-transparent">
              Select Date{isRangeSelection ? ' Range' : ''}
            </DialogTitleExtended>
            <div className="flex items-center space-x-3 px-4 py-2 bg-purple-50 rounded-full">
              <SwitchExtended
                checked={isRangeSelection}
                onCheckedChange={(checked) => {
                  setIsRangeSelection(checked);
                  setDateRange(undefined);
                }}
                className="data-[state=checked]:bg-purple-600"
              />
              <Label htmlFor="range-mode" className="text-sm font-medium text-purple-700 cursor-pointer">
                Date Range
              </Label>
            </div>
          </div>
        </DialogHeader>

        <div className="flex flex-col items-center py-4">
          <div className="w-full max-w-[320px]">
            {isRangeSelection ? (
              <DayPicker
                mode="range"
                selected={dateRange}
                onSelect={handleDateSelect as (range: DateRange | undefined) => void}
                numberOfMonths={1}
                className="p-3"
                fromDate={new Date()}
              />
            ) : (
              <DayPicker
                mode="single"
                selected={dateRange?.from}
                onSelect={handleDateSelect as (date: Date | undefined) => void}
                numberOfMonths={1}
                className="p-3"
                fromDate={new Date()}
              />
            )}
          </div>
        </div>

        <div className="flex justify-center mt-2">
          <Button 
            onClick={handleApply} 
            disabled={!dateRange?.from}
            className="w-full bg-purple-600 text-white hover:bg-purple-700 
                     py-2.5 rounded-lg font-medium
                     transition-all duration-200 transform hover:scale-[1.02]
                     disabled:opacity-50 disabled:cursor-not-allowed
                     disabled:hover:scale-100"
          >
            <CalendarIcon className="w-4 h-4 mr-2" />
            Apply Selection
          </Button>
        </div>
      </DialogContentExtended>
    </Dialog>
  );
};

export default DateDialog;