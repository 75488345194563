import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from "../components/ui/Card";
import { Avatar, AvatarImage } from "../components/ui/Avatar";
import { Button } from "../components/ui/Button";
import { Input } from "../components/ui/Input";
import { Label } from "../components/ui/Label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/Select";
import { Switch } from "../components/ui/Switch";
import { CameraIcon, CheckCircleIcon } from "lucide-react";
import { auth, db, storage } from '../config/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { User } from '../models/User';
import { Timestamp } from 'firebase/firestore';
import { httpsCallable, getFunctions } from 'firebase/functions';

interface UserProfile {
  id: string;
  name: string;
  email: string;
  gender: string;
  dateOfBirth: string;
  eventsAttended: string[];
  eventsCreated: string[];
  bankAccountDetails: {
    accountNumber: string;
    bankName: string;
    ifscCode: string;
    isVerified: boolean;
  };
  upiDetails: string;
  upiVerified: boolean;
  subscribers: string[];
  isOrganizer: boolean;
  profilePicture: string;
  phone: string;
  phoneVerified: boolean; // Add this line
  createdAt?: Date;
  lastLogin?: Date;
  bookmarkedEvents: string[];
  razorpayContactCreated: boolean;
  razorpayContactId?: string;
}

export const EditProfilePage: React.FC = () => {
  const [editedUser, setEditedUser] = useState<UserProfile | null>(null);
  const [activeSection, setActiveSection] = useState('personal');
  const [paymentMethod, setPaymentMethod] = useState<'upi' | 'bank'>('upi');
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        try {
          const userDocRef = doc(db, 'users', currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);
          
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data() as Omit<UserProfile, 'dateOfBirth'> & { dateOfBirth: string | Date | { seconds: number; nanoseconds: number } };
            let formattedDateOfBirth = '';

            if (userData.dateOfBirth) {
              if (userData.dateOfBirth instanceof Date) {
                formattedDateOfBirth = userData.dateOfBirth.toISOString().split('T')[0];
              } else if (typeof userData.dateOfBirth === 'string') {
                formattedDateOfBirth = new Date(userData.dateOfBirth).toISOString().split('T')[0];
              } else if (typeof userData.dateOfBirth === 'object' && 'seconds' in userData.dateOfBirth) {
                formattedDateOfBirth = new Date(userData.dateOfBirth.seconds * 1000).toISOString().split('T')[0];
              }
            }

            setEditedUser({
              id: currentUser.uid,
              name: currentUser.displayName || '',
              email: currentUser.email || '',
              gender: userData.gender || '',
              dateOfBirth: formattedDateOfBirth,
              eventsAttended: userData.eventsAttended || [],
              eventsCreated: userData.eventsCreated || [],
              bankAccountDetails: userData.bankAccountDetails || { accountNumber: '', bankName: '', ifscCode: '', isVerified: false },
              upiDetails: userData.upiDetails || '',
              upiVerified: userData.upiVerified || false,
              subscribers: userData.subscribers || [],
              isOrganizer: userData.isOrganizer || false,
              profilePicture: userData.profilePicture || '',
              phone: userData.phone || '',
              phoneVerified: userData.phoneVerified || false, // Add this line
              createdAt: userData.createdAt instanceof Timestamp ? userData.createdAt.toDate() : new Date(),
              lastLogin: userData.lastLogin instanceof Timestamp ? userData.lastLogin.toDate() : new Date(),
              bookmarkedEvents: userData.bookmarkedEvents || [],
              razorpayContactCreated: userData.razorpayContactCreated || false,
              razorpayContactId: userData.razorpayContactId || '',
            });
          } else {
            console.log("No such user document!");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedUser(prev => prev ? ({ ...prev, [name]: value }) : null);
  };

  const handleBankDetailsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedUser(prev => prev ? ({
      ...prev,
      bankAccountDetails: { ...prev.bankAccountDetails, [name]: value }
    }) : null);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (editedUser && auth.currentUser) {
      try {
        let profilePictureURL = editedUser.profilePicture;

        // If a new file was selected, upload it
        if (selectedFile) {
          profilePictureURL = await uploadProfilePicture(selectedFile);
        }

        // Create Razorpay contact if becoming an organizer for the first time
        try {
          if (editedUser.isOrganizer && !editedUser.razorpayContactCreated) {
            const createContact = httpsCallable(getFunctions(), 'createRazorpayContact');
            const result = await createContact();
            console.log('Razorpay contact created:', result.data);
          
            // Update the editedUser with Razorpay contact info
            editedUser.razorpayContactCreated = true;
            if (result.data && (result.data as any).contactId) {
              editedUser.razorpayContactId = (result.data as any).contactId;
            }
          }
        } catch (error) {
          console.error("Error creating Razorpay contact:", error);
        }


        const userToUpdate = new User(
          auth.currentUser.uid,
          editedUser.gender,
          new Date(editedUser.dateOfBirth),
          editedUser.eventsAttended,
          editedUser.eventsCreated,
          editedUser.bankAccountDetails,
          editedUser.upiDetails,
          editedUser.upiVerified,
          editedUser.subscribers,
          editedUser.isOrganizer,
          profilePictureURL,
          editedUser.phone,
          editedUser.phoneVerified,
          editedUser.createdAt instanceof Date ? editedUser.createdAt : new Date(editedUser.createdAt || Date.now()),
          new Date(),
          editedUser.bookmarkedEvents,
          editedUser.razorpayContactCreated,
          editedUser.razorpayContactId
        );

        await userToUpdate.save();

        console.log('User profile updated successfully');
        navigate('/profile');
      } catch (error) {
        console.error("Error updating user profile:", error);
        // Optionally show error to user via toast/alert
      }
    }
  };

  const handleVerifyUPI = () => {
    setEditedUser(prev => prev ? ({ ...prev, upiVerified: true }) : null);
  };

  const handleVerifyBank = () => {
    setEditedUser(prev => prev ? ({
      ...prev,
      bankAccountDetails: { ...prev.bankAccountDetails, isVerified: true }
    }) : null);
  };

  const handleGenderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setEditedUser(prev => prev ? { ...prev, gender: value } : null);
  };

  const handlePictureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      // Show a preview of the selected image
      const reader = new FileReader();
      reader.onload = (e) => {
        setEditedUser(prev => prev ? { ...prev, profilePicture: e.target?.result as string } : null);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadProfilePicture = async (file: File): Promise<string> => {
    if (!auth.currentUser) throw new Error("User not authenticated");

    const storageRef = ref(storage, `profilePictures/${auth.currentUser.uid}`);
    try {
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      
      return downloadURL;
    } catch (error) {
      console.error("Error uploading profile picture:", error);
      throw error;
    }
  };

  const handleChangePictureClick = (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent form submission
    fileInputRef.current?.click();
  };

  const handleOrganizerToggle = (checked: boolean) => {
    // Simply update the UI state, actual changes will be made on form submission
    setEditedUser(prev => prev ? { 
      ...prev, 
      isOrganizer: checked,
    } : null);
  };

  if (!editedUser) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <Card>
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-purple-800">Edit Profile</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex space-x-2 mb-6">
            <Button
              variant={activeSection === 'personal' ? 'default' : 'outline'}
              onClick={() => setActiveSection('personal')}
            >
              Personal Information
            </Button>
            <Button
              variant={activeSection === 'account' ? 'default' : 'outline'}
              onClick={() => setActiveSection('account')}
            >
              Account Settings
            </Button>
            {editedUser.isOrganizer && (
              <Button
                variant={activeSection === 'financial' ? 'default' : 'outline'}
                onClick={() => setActiveSection('financial')}
              >
                Financial Information
              </Button>
            )}
          </div>

          <form onSubmit={handleSubmit}>
            {activeSection === 'personal' && (
              <div className="space-y-6">
                <div className="flex items-center space-x-4">
                  <Avatar className="w-24 h-24">
                    <AvatarImage src={editedUser.profilePicture} alt={editedUser.name} />
                    {/* <AvatarFallback>
                      <img 
                        src={editedUser.gender === 'female' ? fallbackFemale : fallbackMale} 
                        alt={editedUser.gender === 'female' ? 'Female avatar' : 'Male avatar'}
                      />
                    </AvatarFallback> */}
                  </Avatar>
                  <Button type="button" variant="outline" onClick={handleChangePictureClick}>
                    <CameraIcon className="w-4 h-4 mr-2" />
                    Change Picture
                  </Button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handlePictureChange}
                    accept="image/*"
                    style={{ display: 'none' }}
                  />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <Label htmlFor="name">Name</Label>
                    <Input id="name" name="name" value={editedUser.name} onChange={handleInputChange} />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="email">Email</Label>
                    <Input id="email" name="email" type="email" value={editedUser.email} onChange={handleInputChange} />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="phone">Phone</Label>
                    <Input id="phone" name="phone" value={editedUser.phone} onChange={handleInputChange} />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="gender">Gender</Label>
                    <select
                      id="gender"
                      name="gender"
                      value={editedUser.gender}
                      onChange={handleGenderChange}
                      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
                    >
                      <option value="">Select gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="dateOfBirth">Date of Birth</Label>
                    <Input 
                      id="dateOfBirth" 
                      name="dateOfBirth" 
                      type="date" 
                      value={editedUser.dateOfBirth} 
                      onChange={(e) => {
                        setEditedUser(prev => prev ? { ...prev, dateOfBirth: e.target.value } : null);
                      }} 
                    />
                  </div>
                </div>
              </div>
            )}

            {activeSection === 'account' && (
              <div className="space-y-4">
                <div className="flex items-center space-x-2">
                  <Switch
                    checked={editedUser.isOrganizer}
                    onCheckedChange={handleOrganizerToggle}
                  />
                  <Label htmlFor="isOrganizer">I want to organize events</Label>
                </div>
                <p className="text-sm text-gray-500">
                  Enabling this option will allow you to create and manage events on our platform.
                </p>
              </div>
            )}

            {activeSection === 'financial' && editedUser.isOrganizer && (
              <div className="space-y-4">
                <div className="flex items-center space-x-2 mb-4">
                  <Label htmlFor="paymentMethod">Payment Method:</Label>
                  <Select 
                    value={paymentMethod} 
                    onValueChange={(value: string) => setPaymentMethod(value as 'upi' | 'bank')}
                  >
                    <SelectTrigger className="w-[180px]">
                      <SelectValue placeholder="Select payment method" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="upi">UPI</SelectItem>
                      <SelectItem value="bank">Bank Account</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                {paymentMethod === 'upi' && (
                  <div className="space-y-2">
                    <Label htmlFor="upiDetails">UPI ID</Label>
                    <div className="flex space-x-2">
                      <Input 
                        id="upiDetails" 
                        name="upiDetails" 
                        value={editedUser.upiDetails} 
                        onChange={handleInputChange} 
                      />
                      <Button 
                        onClick={handleVerifyUPI}
                        disabled={editedUser.upiVerified}
                        className={editedUser.upiVerified ? "bg-green-500" : "bg-purple-600 hover:bg-purple-700"}
                      >
                        {editedUser.upiVerified ? (
                          <>
                            <CheckCircleIcon className="w-4 h-4 mr-2" />
                            Verified
                          </>
                        ) : (
                          'Verify UPI'
                        )}
                      </Button>
                    </div>
                  </div>
                )}

                {paymentMethod === 'bank' && (
                  <div className="space-y-4">
                    <div className="space-y-2">
                      <Label htmlFor="accountNumber">Account Number</Label>
                      <Input 
                        id="accountNumber" 
                        name="accountNumber" 
                        value={editedUser.bankAccountDetails.accountNumber} 
                        onChange={handleBankDetailsChange} 
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="bankName">Bank Name</Label>
                      <Input 
                        id="bankName" 
                        name="bankName" 
                        value={editedUser.bankAccountDetails.bankName} 
                        onChange={handleBankDetailsChange} 
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="ifscCode">IFSC Code</Label>
                      <Input 
                        id="ifscCode" 
                        name="ifscCode" 
                        value={editedUser.bankAccountDetails.ifscCode} 
                        onChange={handleBankDetailsChange} 
                      />
                    </div>
                    <Button 
                      onClick={handleVerifyBank}
                      disabled={editedUser.bankAccountDetails.isVerified}
                      className={editedUser.bankAccountDetails.isVerified ? "bg-green-500" : "bg-purple-600 hover:bg-purple-700"}
                    >
                      {editedUser.bankAccountDetails.isVerified ? (
                        <>
                          <CheckCircleIcon className="w-4 h-4 mr-2" />
                          Verified
                        </>
                      ) : (
                        'Verify Bank Account'
                      )}
                    </Button>
                  </div>
                )}
              </div>
            )}

            <CardFooter className="flex justify-end space-x-2 mt-6">
              <Button variant="outline" onClick={() => navigate('/profile')}>Cancel</Button>
              <Button type="submit" className="bg-purple-600 hover:bg-purple-700 text-white">Save Changes</Button>
            </CardFooter>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};
