import React from 'react';
import { Card, CardContent } from "../../../components/ui/Card";
import { MapPinIcon, GlobeIcon, LockIcon } from "../../../utils/icons";
import { Event } from '../../../models/Event';

interface EventLocationInfoProps {
  event: Event;
  isAttendee?: boolean;
}

export const EventLocationInfo: React.FC<EventLocationInfoProps> = ({ event, isAttendee = false }) => {
  const handleLocationClick = (e: React.MouseEvent<HTMLAnchorElement>, location: string) => {
    e.preventDefault();
    
    // Clean the location string
    const cleanLocation = location.replace(/^(https?:\/\/)?(www\.)?/, '');
    let mapsUrl: string;

    // Handle different location formats
    if (cleanLocation.startsWith('google.com/maps') || cleanLocation.startsWith('maps.google.com')) {
      mapsUrl = `https://${cleanLocation}`;
    } else {
      mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(location)}`;
    }

    // Check if mobile device
    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
      if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        // iOS: Try Apple Maps first
        window.location.href = `maps://?q=${encodeURIComponent(location)}`;
        // Fallback to Google Maps after a delay
        setTimeout(() => {
          window.location.href = mapsUrl;
        }, 1000);
      } else {
        // Android: Open Google Maps directly
        window.location.href = mapsUrl;
      }
    } else {
      // Desktop: Open in new tab
      window.open(mapsUrl, '_blank', 'noopener,noreferrer');
    }
  };

  const getLocationString = (event: Event): string => {
    if (event.location) return event.location;
    if (event.locationLink) return event.locationLink;
    return "Location not specified";
  };

  const cardContent = event.isOnline ? (
    <>
      <div className="flex items-center mb-2">
        <GlobeIcon className="w-6 h-6 text-purple-600 mr-2" />
        <h3 className="text-lg font-semibold text-purple-800">Online Event</h3>
      </div>
      {isAttendee && event.meetingLink ? (
        <>
          <p className="text-sm text-gray-600 mb-2">Meeting Link:</p>
          <a 
            href={event.meetingLink}
            target="_blank"
            rel="noopener noreferrer"
            className="text-purple-600 hover:text-purple-800 underline break-all text-sm"
          >
            {event.meetingLink}
          </a>
        </>
      ) : (
        <div className="flex items-center text-sm text-gray-600">
          <LockIcon className="w-4 h-4 mr-1 text-gray-400" />
          <p>Register to view meeting link</p>
        </div>
      )}
    </>
  ) : (
    <>
      <div className="flex items-center mb-2">
        <MapPinIcon className="w-6 h-6 text-purple-600 mr-2" />
        <h3 className="text-lg font-semibold text-purple-800">Location</h3>
      </div>
      {isAttendee ? (
        <>
          <p className="text-sm text-gray-600">{event.location}</p>
          {event.locationLink && (
            <a 
              href={event.locationLink}
              onClick={(e) => handleLocationClick(e, getLocationString(event))}
              className="text-purple-600 hover:text-purple-800 underline mt-2 block text-sm"
            >
              View on Maps
            </a>
          )}
        </>
      ) : (
        <div className="flex items-center text-sm text-gray-600">
          <LockIcon className="w-4 h-4 mr-1 text-gray-400" />
          <p>Register to view venue details</p>
        </div>
      )}
    </>
  );

  return (
    <Card className="h-full bg-gradient-to-br from-purple-50 to-purple-100">
      <CardContent className="p-4">{cardContent}</CardContent>
    </Card>
  );
};