// src/pages/organizer/ManageAttendeesPage.tsx

import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/Card";
import { Button } from "../../components/ui/Button";
import { Input } from "../../components/ui/Input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../components/ui/Table";
import { Badge } from "../../components/ui/Badge";
import { Checkbox } from "../../components/ui/Checkbox";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../components/ui/DropdownMenu";
import { SearchIcon, MoreVerticalIcon, MessageCircleIcon, UserCheckIcon, UserXIcon, DownloadIcon } from "lucide-react";
import { useParams } from 'react-router-dom';
import { Event } from '../../models/Event';
import { Attendee } from '../../types/Attendee';
import { getEventAttendees, updateAttendeeCheckInStatus } from '../../services/eventService';
import { useToast } from '../../hooks/useToast';

interface ManageAttendeesPageProps {
  eventTitle: string;
  attendees: Attendee[];
}

export const ManageAttendeesPage: React.FC<ManageAttendeesPageProps> = ({ eventTitle, attendees }) => {
  const { id: eventId } = useParams<{ id: string }>();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAttendees, setSelectedAttendees] = useState<string[]>([]);
  const { showToast } = useToast();

  useEffect(() => {
    const fetchAttendees = async () => {
      if (eventId) {
        await getEventAttendees(eventId);
      }
    };
    fetchAttendees();
  }, [eventId]);

  const filteredAttendees = attendees.filter((attendee) =>
    (attendee.displayName ?? '').toLowerCase().includes(searchTerm.toLowerCase()) ||
    (attendee.email ?? '').toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelectAll = () => {
    if (selectedAttendees.length === filteredAttendees.length) {
      setSelectedAttendees([]);
    } else {
      setSelectedAttendees(filteredAttendees.map((a) => a.uid));
    }
  };

  const handleSelectAttendee = (id: string) => {
    if (selectedAttendees.includes(id)) {
      setSelectedAttendees(selectedAttendees.filter((a) => a !== id));
    } else {
      setSelectedAttendees([...selectedAttendees, id]);
    }
  };

  const handleCheckIn = async (userId: string) => {
    if (eventId) {
      try {
        await updateAttendeeCheckInStatus(eventId, userId);
        showToast({
          title: "Success",
          description: `Attendee has been ${attendees.find((a) => a.uid === userId)?.checkedIn ? 'checked in' : 'unchecked'}.`,
          variant: "default",
        });
      } catch (error) {
        console.error("Error updating check-in status:", error);
        showToast({
          title: "Error",
          description: "Failed to update check-in status. Please try again.",
          variant: "destructive",
        });
      }
    }
  };

  const handleSendMessage = () => {
    // Implement message sending functionality
  };

  const handleDownloadCSV = () => {
    // Implement CSV export functionality
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <Card>
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-purple-800">Manage Attendees</CardTitle>
          <p className="text-gray-600">Event: {eventTitle}</p>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-4 space-y-4 md:space-y-0">
            <div className="flex items-center space-x-2">
              <Input
                placeholder="Search attendees..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-64"
              />
              <Button variant="outline">
                <SearchIcon className="w-4 h-4 mr-2" />
                Search
              </Button>
            </div>
            <div className="flex space-x-2">
              <Button onClick={handleSendMessage} disabled={selectedAttendees.length === 0}>
                <MessageCircleIcon className="w-4 h-4 mr-2" />
                Message Selected
              </Button>
              <Button onClick={handleDownloadCSV}>
                <DownloadIcon className="w-4 h-4 mr-2" />
                Export CSV
              </Button>
            </div>
          </div>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>
                  <Checkbox
                    checked={selectedAttendees.length === filteredAttendees.length && filteredAttendees.length > 0}
                    onChange={handleSelectAll}
                  />
                </TableHead>
                <TableHead>Name</TableHead>
                <TableHead>Email</TableHead>
                <TableHead>Registration Type</TableHead>
                <TableHead>Checked In</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredAttendees.length > 0 ? (
                filteredAttendees.map((attendee) => (
                  <TableRow key={attendee.uid}>
                    <TableCell>
                      <Checkbox
                        checked={selectedAttendees.includes(attendee.uid)}
                        onChange={() => handleSelectAttendee(attendee.uid)}
                      />
                    </TableCell>
                    <TableCell>{attendee.displayName}</TableCell>
                    <TableCell>{attendee.email}</TableCell>
                    <TableCell>
                      <Badge>{attendee.registrationType}</Badge>
                    </TableCell>
                    <TableCell>
                      {attendee.checkedIn ? (
                        <Badge variant="default" className="bg-green-500">Yes</Badge>
                      ) : (
                        <Badge variant="secondary">No</Badge>
                      )}
                    </TableCell>
                    <TableCell>
                      <DropdownMenu>
                        <DropdownMenuTrigger>
                          <Button variant="outline">
                            <MoreVerticalIcon className="w-4 h-4" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                          <DropdownMenuItem onClick={() => handleCheckIn(attendee.uid)}>
                            {attendee.checkedIn ? (
                              <>
                                <UserXIcon className="w-4 h-4 mr-2" />
                                Undo Check-In
                              </>
                            ) : (
                              <>
                                <UserCheckIcon className="w-4 h-4 mr-2" />
                                Check-In
                              </>
                            )}
                          </DropdownMenuItem>
                          <DropdownMenuItem onClick={() => {/* Handle message */}}>
                            <MessageCircleIcon className="w-4 h-4 mr-2" />
                            Send Message
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} className="text-center text-gray-500">
                    No attendees found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
};
