import { useCallback } from 'react';
import { Event } from '../types/Event';

export const useEventValidation = () => {
  const validateEvent = useCallback((event: Partial<Event>): string[] => {
    const errors: string[] = [];

    if (!event.title) {
      errors.push('Event title is required.');
    }

    if (!event.description) {
      errors.push('Event description is required.');
    }

    if (event.isOnline === undefined) {
      errors.push('Please select whether the event is online or in-person.');
    }

    if (event.isSingleDay === undefined) {
      errors.push('Please select whether the event is single day or multi-day.');
    }

    if (event.isSingleDay) {
      if (!event.date || !event.startTime || !event.endTime) {
        errors.push('Please fill in all date and time fields for single day event.');
      }
    } else {
      if (!event.fromDate || !event.toDate) {
        errors.push('Please fill in from and to dates for multi-day event.');
      }
    }

    if (event.isRepeatedEvent && (!event.repeatedDaysOfWeek || event.repeatedDaysOfWeek.length === 0)) {
      errors.push('Please select at least one day for repeated event.');
    }

    if (event.isFree === undefined) {
      errors.push('Please select whether the event is free or paid.');
    }

    if (!event.isFree && !event.price) {
      errors.push('Please enter a price for the paid event.');
    }

    if (!event.skill) {
      errors.push('Please enter a skill level for the event.');
    }

    return errors;
  }, []);

  return { validateEvent };
};