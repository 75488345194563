import React from 'react';

interface EventDescriptionProps {
  description: string;
}

export const EventDescription: React.FC<EventDescriptionProps> = ({ description }) => (
  <div className="flex-grow">
    <h2 className="text-2xl font-semibold mb-2 text-purple-800">About this event</h2>
    <p className="text-gray-700 whitespace-pre-wrap">{description}</p>
  </div>
);