import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'default' | 'primary' | 'outline' | 'destructive';
  size?: 'sm' | 'md' | 'lg';
  as?: React.ElementType;
  to?: string;
  children: React.ReactNode;
  icon?: React.ReactNode;
  disabled?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  variant = 'default',
  size = 'md',
  as: Component = 'button',
  className = '',
  icon,
  to,
  disabled,
  ...props
}) => {
  const baseClasses = 'px-4 py-2 rounded-md font-semibold transition-colors duration-200 flex items-center justify-center';
  
  const variantClasses = {
    primary: 'bg-purple-500 text-white hover:bg-purple-600 disabled:bg-purple-300',
    default: 'bg-purple-500 text-white hover:bg-purple-600 disabled:bg-purple-300',
    outline: 'bg-white text-purple-500 border border-purple-500 hover:bg-purple-50 disabled:bg-gray-100 disabled:border-gray-300 disabled:text-gray-500',
    destructive: 'bg-red-500 text-white hover:bg-red-600 disabled:bg-red-300'
  }[variant];

  const sizeStyles = {
    sm: 'px-3 py-1.5 text-sm',
    md: 'px-4 py-2',
    lg: 'px-6 py-3 text-lg'
  }[size];

  const disabledClasses = disabled ? 'cursor-not-allowed opacity-60' : '';

  if (to) {
    return (
      <Link
        to={to}
        className={`${baseClasses} ${variantClasses} ${sizeStyles} ${className} ${disabledClasses}`}
        {...(props as Omit<LinkProps, 'to'>)}
      >
        {icon && <span className="mr-2">{icon}</span>}
        {children}
      </Link>
    );
  }

  return (
    <Component
      className={`${baseClasses} ${variantClasses} ${sizeStyles} ${className} ${disabledClasses}`}
      disabled={disabled}
      {...props}
    >
      {icon && <span className="mr-2">{icon}</span>}
      {children}
    </Component>
  );
};
