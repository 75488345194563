import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/Card";

export const RefundPolicyPage = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <Card>
        <CardHeader>
          <CardTitle>Refund and Cancellation Policy</CardTitle>
        </CardHeader>
        <CardContent className="prose max-w-none">
          <h2>Refund Timeline</h2>
          <p>All refunds will be processed within 5-7 working days from the date of approval. The refunded amount will be credited to the original payment method used for the transaction.</p>

          <h2>Cancellation Policy</h2>
          <ul>
            <li>Full refund if cancelled 7 days before the event</li>
            <li>50% refund if cancelled 3-7 days before the event</li>
            <li>No refund for cancellations within 72 hours of the event</li>
          </ul>

          <h2>Processing Time</h2>
          <p>Once approved, refunds will be initiated within 24-48 hours. The credit to your bank account may take 5-7 working days, depending on your bank's processing time.</p>
        </CardContent>
      </Card>
    </div>
  );
};
