import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../Dialog';
import { Button } from '../Button';
import { CheckCircle2, Circle, AlertCircle, X } from 'lucide-react';

// Extend the base Dialog component types
interface ExtendedDialogContentProps extends React.ComponentProps<typeof DialogContent> {
  className?: string;
}

interface ExtendedDialogHeaderProps extends React.ComponentProps<typeof DialogHeader> {
  className?: string;
}

interface ExtendedDialogTitleProps extends React.ComponentProps<typeof DialogTitle> {
  className?: string;
}

interface MultiSelectDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  title: string;
  options: string[];
  selectedValues: string[];
  onChange: (values: string[]) => void;
  defaultValue?: string;
  onConfirm?: () => void;
  error?: string | null;
}

export const MultiSelectDialog: React.FC<MultiSelectDialogProps> = ({
  isOpen,
  onOpenChange,
  title,
  options,
  selectedValues,
  onChange,
  defaultValue,
  onConfirm,
  error
}) => {
  const handleChange = (option: string) => {
    const newSelectedValues = selectedValues.includes(option)
      ? selectedValues.filter((value) => value !== option)
      : [...selectedValues, option];
    onChange(newSelectedValues);
  };

  const DialogContentExtended = DialogContent as React.FC<ExtendedDialogContentProps>;
  const DialogHeaderExtended = DialogHeader as React.FC<ExtendedDialogHeaderProps>;
  const DialogTitleExtended = DialogTitle as React.FC<ExtendedDialogTitleProps>;

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContentExtended className="sm:max-w-md bg-white/95 backdrop-blur-sm border border-purple-100">
        <DialogHeaderExtended className="border-b border-purple-100 pb-4">
          <div className="flex items-center justify-between">
            <DialogTitleExtended className="text-xl font-bold bg-gradient-to-r from-purple-600 to-purple-400 bg-clip-text text-transparent">
              {title}
            </DialogTitleExtended>
            <button
              onClick={() => onOpenChange(false)}
              className="p-1.5 rounded-full hover:bg-purple-50 transition-colors"
            >
              <X className="w-5 h-5 text-purple-500" />
            </button>
          </div>
        </DialogHeaderExtended>
        
        {error && (
          <div className="text-red-500 mb-4 flex items-center p-3 bg-red-50 rounded-lg">
            <AlertCircle className="w-5 h-5 mr-2 flex-shrink-0" />
            <span className="text-sm">{error}</span>
          </div>
        )}

        <div className="py-3 max-h-[60vh] overflow-y-auto custom-scrollbar">
          <div className="space-y-1">
            {options.map((option) => {
              const isSelected = selectedValues.includes(option);
              return (
                <button
                  key={option}
                  onClick={() => handleChange(option)}
                  className={`w-full flex items-center px-4 py-3 rounded-lg text-left transition-all
                    ${isSelected 
                      ? 'bg-purple-50 hover:bg-purple-100/80' 
                      : 'hover:bg-purple-50/50'
                    }
                    group focus:outline-none focus:ring-2 focus:ring-purple-200`}
                >
                  <div className="flex items-center justify-center mr-3 flex-shrink-0">
                    {isSelected ? (
                      <CheckCircle2 className="w-5 h-5 text-purple-500" />
                    ) : (
                      <Circle className="w-5 h-5 text-purple-300 group-hover:text-purple-400" />
                    )}
                  </div>
                  <span className={`text-sm font-medium ${
                    isSelected ? 'text-purple-700' : 'text-gray-600 group-hover:text-gray-700'
                  }`}>
                    {option}
                  </span>
                </button>
              );
            })}
          </div>
        </div>

        {onConfirm && (
          <div className="pt-4 border-t border-purple-100">
            <Button
              onClick={onConfirm}
              className="w-full bg-purple-600 text-white hover:bg-purple-700 
                       focus:ring-purple-200 py-2.5 rounded-lg font-medium
                       transition-all duration-200 transform hover:scale-[1.02]"
            >
              Confirm Selection
            </Button>
          </div>
        )}
      </DialogContentExtended>
    </Dialog>
  );
};

export default MultiSelectDialog;