import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ManageAttendeesPage } from './ManageAttendeesPage';
import { Event } from '../../models/Event';
import { Attendee } from '../../types/Attendee';
import { getEventAttendees } from '../../services/eventService';

export const ManageAttendeesPageWrapper: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [eventTitle, setEventTitle] = useState('');
  const [attendees, setAttendees] = useState<Attendee[]>([]);

  useEffect(() => {
    const fetchEventAndAttendees = async () => {
      if (id) {
        const event = await Event.findById(id);
        if (event) {
          setEventTitle(event.title);
          const attendeesData = await getEventAttendees(event.id);
          setAttendees(attendeesData);
        }
      }
    };
    fetchEventAndAttendees();
  }, [id]);

  return <ManageAttendeesPage eventTitle={eventTitle} attendees={attendees} />;
};
