import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useSearchContext } from '../contexts/SearchContext';

export const Greeting: React.FC = () => {
  const { user } = useAuth();
  const { state } = useSearchContext();

  if (state.isLocationChanged) {
    return null;
  }

  return (
    <div className="text-2xl font-bold mb-4 text-purple-600 text-center">
      {user ? `Welcome, ${user.displayName || 'User'}!` : 'Welcome to EventAtEase!'}
    </div>
  );
};