import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Event } from '../../types/Event';
import { useAuth } from '../../contexts/AuthContext';
import { Button } from "../../components/ui/Button";
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/Card";
import { v4 as uuidv4 } from 'uuid';
import { ref, uploadBytesResumable, getDownloadURL, uploadString } from 'firebase/storage';
import { storage } from '../../config/firebase';
import { FirebaseError } from 'firebase/app';
import { Timestamp, addDoc, collection, updateDoc, doc, arrayUnion, setDoc } from 'firebase/firestore';
import { CalendarService } from '../../services/calendarService';
import { db } from '../../config/firebase';

import { EventBasicInfo } from '../EventBasicInfo';
import { EventTypeSelection } from '../EventTypeSelection';
import { EventVisibilitySelection } from '../EventVisibilitySelection';
import { EventLocationInput } from '../EventLocationInput';
import { EventLinkInput } from '../common/EventLinkInput'; // Import the new component
import { EventDurationSelection } from '../EventDurationSelection';
import { EventDateTimeInputs } from '../EventDateTimeInputs';
import { EventFrequencySelection } from '../EventFrequencySelection';
import { EventPriceSelection } from '../EventPriceSelection';
import { EventSkillInput } from '../EventSkillInput';
import { EventImageUpload } from '../EventImageUpload';
import { useEventValidation } from '../../hooks/useEventValidation';
import { useModalMessage } from '../../hooks/useModalMessage';
import { ConfirmationModal } from '../common/ConfirmationModal';
import { EventDailySchedules } from '../EventDailySchedules';

// Update Event type to include googleCalendarEventId
type UpdatedEvent = Event & { googleCalendarEventId?: string; imageData?: string };

export const CreateEventForm: React.FC = () => {
  const [event, setEvent] = useState<Partial<UpdatedEvent>>({
    title: '',
    description: '',
    isOnline: false,
    isSingleDay: true,
    isRepeatedEvent: false,
    isFree: true,
    skill: '',
    isPublic: true,
    date: null,
    status: 'upcoming',
    attendeeCount: 0,
    attendees: [],
    tags: [],
    categories: [],
    maxCapacity: 100,
    feedback: [],
    imageData: undefined, // Add this line
    dailySchedules: [], // Add this line to initialize dailySchedules
    price: 0,
  });
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { validateEvent } = useEventValidation();
  const { modalMessage, isModalOpen, showModal, closeModal } = useModalMessage();
  const { user: currentUser } = useAuth();

  const handleChange = (field: string, value: any) => {
    if (field === 'imageFile') {
      setImageFile(value);
    } else {
      setEvent((prevEvent) => ({ ...prevEvent, [field]: value }));
    }
  };

  const handleImageUpload = (fileOrData: File | string) => {
    if (typeof fileOrData === 'string') {
      // Handle base64 image data
      setEvent(prevEvent => ({ ...prevEvent, imageData: fileOrData }));
    } else {
      // Handle File object
      setImageFile(fileOrData);
    }
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      
      if (!currentUser) {
        console.log('User not logged in');
        showModal('You must be logged in to create an event.');
        return;
      }

      console.log('Starting event creation process');
      const eventId = uuidv4();
      console.log('Generated eventId:', eventId);

      let imageUrl = '';
      if (imageFile) {
        console.log('Uploading image file');
        imageUrl = await uploadImage(imageFile);
        console.log('Image uploaded successfully, URL:', imageUrl);
      } else if (event.imageData) {
        console.log('Uploading base64 image data');
        imageUrl = await uploadImage(event.imageData);
        console.log('Base64 image uploaded successfully, URL:', imageUrl);
      }

      const now = Timestamp.now();
      console.log('Current timestamp:', now.toDate());

      const newEvent: Partial<UpdatedEvent> = {
        id: eventId,
        urlFriendlyId: eventId,
        title: event.title || '',
        description: event.description || '',
        isOnline: event.isOnline || false,
        isSingleDay: false,
        isRepeatedEvent: event.isRepeatedEvent || false,
        isFree: event.isFree ?? (event.price === 0 || !event.price),
        skill: event.skill || '',
        isPublic: event.isPublic || true,
        status: 'upcoming',
        attendeeCount: 0,
        attendees: [],
        organizerId: currentUser.uid,
        createdAt: now,
        updatedAt: now,
        tags: [],
        categories: [],
        maxCapacity: event.maxCapacity || 100,
        feedback: [],
        imageUrl,
        isCancelled: false,
        startTime: event.startTime || now.toDate().toISOString(),
        endTime: event.endTime || now.toDate().toISOString(),
        dailySchedules: event.dailySchedules || [],
        fromDate: event.fromDate || event.date || now,
        toDate: event.toDate || event.date || now,
        price: event.isFree ? 0 : Number(event.price || 0),
      };

      if (event.location) newEvent.location = event.location;
      if (event.locationLink) newEvent.locationLink = event.locationLink;
      if (event.fromDate) newEvent.fromDate = event.fromDate;
      if (event.toDate) newEvent.toDate = event.toDate;
      if (event.repeatedDaysOfWeek) newEvent.repeatedDaysOfWeek = event.repeatedDaysOfWeek;
      if (event.price) newEvent.price = event.price;

      if (!validateEvent(newEvent)) {
        console.log('Event validation failed');
        showModal('Invalid event data. Please check all fields.');
        return;
      }
      console.log('Event validation passed');

      console.log('Creating Google Calendar event');
      try {
        const calendarResult = await CalendarService.createEvent({
          title: newEvent.title || '',
          description: newEvent.description || '',
          startTime: (newEvent.isSingleDay 
            ? newEvent.date?.toDate().toISOString() 
            : newEvent.fromDate?.toDate().toISOString()) || new Date().toISOString(),
          endTime: (newEvent.isSingleDay 
            ? newEvent.date?.toDate().toISOString() 
            : newEvent.toDate?.toDate().toISOString()) || new Date().toISOString(),
          attendees: [], // Add attendees if needed
        });
        
        console.log("Google Calendar event created:", calendarResult);

        if (calendarResult.meetLink) {
          newEvent.meetingLink = calendarResult.meetLink;
          console.log('Meet link added to event:', calendarResult.meetLink);
        }
        if (calendarResult.eventId) {
          newEvent.googleCalendarEventId = calendarResult.eventId;
          console.log('Google Calendar Event ID added to event:', calendarResult.eventId);
        }
      } catch (error) {
        console.error('Error creating Google Calendar event:', error);
        // Continue with Firestore save even if Calendar creation fails
        showModal('Event will be created without Google Calendar integration.');
      }

      const eventToAdd: Partial<UpdatedEvent> = Object.entries(newEvent).reduce((acc, [key, value]) => {
        if (value !== undefined) {
          (acc as any)[key] = value;
        }
        return acc;
      }, {} as Partial<UpdatedEvent>);
      console.log('Final event object to be added to Firestore:', eventToAdd);

      try {
        console.log('Adding event to Firestore');
        const docRef = doc(db, 'events', eventId);
        console.log('Document reference created:', docRef.path);

        await setDoc(docRef, eventToAdd);
        console.log('Event added successfully to Firestore');
        
        console.log('Updating user document');
        await updateDoc(doc(db, 'users', currentUser.uid), {
          eventsCreated: arrayUnion(eventId)
        });
        console.log('User document updated successfully');

        showModal('Event created successfully!');
        navigate('/organizer/dashboard');
      } catch (error) {
        console.error('Error adding event to Firestore:', error);
        if (error instanceof FirebaseError) {
          console.error('Firebase error code:', error.code);
          console.error('Firebase error message:', error.message);
          showModal(`Failed to save event: ${error.message}`);
        } else {
          console.error('Non-Firebase error:', error);
          showModal('An unexpected error occurred while saving the event. Please try again.');
        }
        throw error;
      }
    } catch (error) {
      console.error("Detailed error in onSubmit:", error);
      if (error instanceof FirebaseError) {
        console.error("Firebase error details:", error.code, error.message);
        showModal(`Failed to create event: ${error.message}`);
      } else if (error instanceof Error) {
        showModal(`Failed to create event: ${error.message}`);
      } else {
        showModal('Failed to create event. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const uploadImage = async (fileOrData: File | string): Promise<string> => {
    const uniqueId = uuidv4();
    const storageRef = ref(storage, `images/${uniqueId}.jpg`);
    
    if (typeof fileOrData === 'string') {
      // It's base64 data
      await uploadString(storageRef, fileOrData, 'base64', { contentType: 'image/jpeg' });
    } else {
      // It's a File object
      await uploadBytesResumable(storageRef, fileOrData);
    }
    
    return getDownloadURL(storageRef);
  };

  return (
    <Card className="max-w-2xl mx-auto bg-purple-50">
      {/* <CardHeader>
        <CardTitle className="text-2xl font-bold text-center text-purple-800">Create New Event</CardTitle>
      </CardHeader> */}
      <CardContent>
        <form onSubmit={(e) => { e.preventDefault(); onSubmit(); }} className="space-y-6">
          <EventBasicInfo event={event} onChange={handleChange} />
          <EventTypeSelection event={event} onChange={handleChange} />
          <EventVisibilitySelection event={event} onChange={handleChange} />
          {event.isOnline ? (
            <EventLinkInput event={event} onChange={handleChange} />
          ) : (
            <EventLocationInput event={event} onChange={handleChange} />
          )}
          <EventDurationSelection event={event} onChange={handleChange} />
          <EventDateTimeInputs event={event} onChange={handleChange} />
          <EventDailySchedules event={event} onChange={handleChange} />
          <EventFrequencySelection event={event} onChange={handleChange} />
          <EventPriceSelection event={event} onChange={handleChange} />
          <EventSkillInput event={event} onChange={handleChange} />
          <EventImageUpload 
            onUpload={handleImageUpload} 
            eventTitle={event.title || ''} 
            eventDescription={event.description || ''}
          />

          <Button type="submit" className="w-full bg-purple-600 hover:bg-purple-700 text-white" disabled={loading}>
            {loading ? 'Creating...' : 'Create Event'}
          </Button>
        </form>
      </CardContent>
      {isModalOpen && modalMessage && <ConfirmationModal message={modalMessage} onClose={closeModal} />}
    </Card>
  );
};
