// src/components/common/EventMetaTags.tsx
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Event } from '../../types/Event';
import { formatDate } from '../../utils/dateUtils';
import { Timestamp } from 'firebase/firestore';

interface EventMetaTagsProps {
  event: Event;
  baseUrl?: string;
}

const EventMetaTags: React.FC<EventMetaTagsProps> = ({ 
  event, 
  baseUrl = 'https://eventatease.com'
}) => {
  // Helper function to convert Timestamp to ISO string
  const getISOString = (timestamp: Timestamp | null | undefined): string | undefined => {
    if (!timestamp) return undefined;
    return timestamp.toDate().toISOString();
  };

  const eventUrl = `${baseUrl}/event/${event.id}`;
  const imageUrl = event.imageUrl || `${baseUrl}/default-event-image.jpg`;
  
  // Enhanced date formatting with null checks and proper Timestamp handling
  const dateInfo = event.isSingleDay
    ? (event.date ? formatDate(event.date.toDate()) : 'Date TBD')
    : (event.fromDate && event.toDate 
       ? `${formatDate(event.fromDate.toDate())} - ${formatDate(event.toDate.toDate())}`
       : 'Dates TBD');

  // Format the event description, truncate if too long
  const description = event.description
    ? event.description.slice(0, 160) + (event.description.length > 160 ? '...' : '')
    : `Join us for ${event.title} on ${dateInfo}${event.location ? ` at ${event.location}` : ''}`;

  // Add price data to schema
  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Event",
    "name": event.title,
    "description": description,
    "image": imageUrl,
    "url": eventUrl,
    "startDate": event.isSingleDay ? getISOString(event.date) : getISOString(event.fromDate),
    "endDate": event.isSingleDay ? getISOString(event.date) : getISOString(event.toDate),
    ...(event.location && {
      "location": {
        "@type": "Place",
        "name": event.location,
        "address": {
          "@type": "PostalAddress",
          "addressLocality": event.location
        }
      }
    }),
    "organizer": {
      "@type": "Organization",
      "name": "EventAtEase"
    }
  };

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{event.title} | EventAtEase</title>
      <meta name="description" content={description} />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={event.title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:url" content={eventUrl} />
      <meta property="og:type" content="event" />
      <meta property="og:site_name" content="EventAtEase" />

      {/* Additional Event-specific Meta Tags */}
      <meta 
        property="event:start_time" 
        content={event.isSingleDay ? getISOString(event.date) : getISOString(event.fromDate)} 
      />
      {event.location && <meta property="event:location" content={event.location} />}
      
      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={event.title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
      
      {/* Schema.org Event Markup */}
      <script type="application/ld+json">
        {JSON.stringify(schemaData)}
      </script>
    </Helmet>
  );
};

export default EventMetaTags;