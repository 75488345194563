import React from 'react';
import { Calendar, Grid, CreditCard, MapPin, X, Bookmark } from 'lucide-react';

interface EnhancedFilterBadgeProps {
  label: string;
  value: string;
  onClick: () => void;
  onClear: () => void;
  isActive: boolean;
  loading?: boolean;
  error?: string | null;
  spacing: {
    height: string;
    padding: string;
    fontSize: string;
    iconSize: string;
  };
}

export const EnhancedFilterBadge: React.FC<EnhancedFilterBadgeProps> = ({
  label,
  value,
  onClick,
  onClear,
  isActive,
  loading,
  error,
  spacing,
}) => {
  const getIcon = (label: string) => {
    switch (label.toLowerCase()) {
      case 'date':
        return <Calendar className="w-4 h-4" />;
      case 'category':
        return <Grid className="w-4 h-4" />;
      case 'price':
        return <CreditCard className="w-4 h-4" />;
      case 'location':
        return <MapPin className="w-4 h-4" />;
      case 'bookmarked':
        return <Bookmark className="w-4 h-4" />;
      default:
        return null;
    }
  };

  return (
    <div className="relative inline-flex group">
      <button
        onClick={onClick}
        style={{
          height: spacing.height,
          padding: spacing.padding,
          fontSize: spacing.fontSize,
        }}
        className={`
          flex items-center rounded-xl font-medium
          transform transition-all duration-300 gap-2.5
          ${isActive 
            ? 'bg-purple-100 text-purple-700 hover:bg-purple-200 shadow-sm' 
            : 'bg-white text-gray-600 hover:bg-gray-50 hover:text-purple-600 border border-gray-200'
          }
          ${loading ? 'animate-pulse' : ''}
          hover:shadow-md focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2
          active:scale-95
        `}
      >
        <div style={{ width: spacing.iconSize, height: spacing.iconSize }}>
          {getIcon(label)}
        </div>
        <span className="font-medium">{label}</span>
        {value && (
          <span className="ml-1 text-xs bg-purple-200/80 text-purple-700 px-2.5 py-1 
                        rounded-full max-w-[120px] truncate font-normal">
            {value}
          </span>
        )}
      </button>
      {isActive && (
        <button
          onClick={onClear}
          className="absolute -top-2 -right-2 bg-purple-600 text-white rounded-full p-1.5
                     shadow-lg opacity-0 group-hover:opacity-100 transition-all duration-300
                     hover:bg-purple-700 hover:scale-110 focus:outline-none focus:ring-2 
                     focus:ring-purple-500 focus:ring-offset-2 transform"
        >
          <X className="w-3 h-3" />
          <span className="sr-only">Clear {label}</span>
        </button>
      )}
    </div>
  );
}; 