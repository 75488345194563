import { format, isValid } from 'date-fns';
import { Event } from '../types/Event';
import { Timestamp } from 'firebase/firestore';

export const formatDate = (date: Date | string) => {
  const parsedDate = new Date(date);
  return isValid(parsedDate) ? format(parsedDate, "do MMMM yyyy") : '';
};

export const formatDay = (date: Date | string) => {
  const parsedDate = new Date(date);
  return isValid(parsedDate) ? format(parsedDate, 'EEEE') : '';
};

export const formatDateRange = (dateRange: { start: Date; end: Date }) => {
  const startDate = formatDate(dateRange.start);
  const endDate = formatDate(dateRange.end);
  return `${startDate} - ${endDate}`;
};

export const isEventInDateRange = (event: Event, start: Date, end: Date): boolean => {
  const getDateFromTimestamp = (timestamp: Timestamp | null | undefined): Date => {
    return timestamp ? timestamp.toDate() : new Date();
  };

  const eventStart = event.isSingleDay ? getDateFromTimestamp(event.date) : getDateFromTimestamp(event.fromDate);
  const eventEnd = event.isSingleDay ? getDateFromTimestamp(event.date) : getDateFromTimestamp(event.toDate);

  // Normalize dates to start of day
  const normalizeDate = (date: Date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());

  const rangeStart = normalizeDate(start);
  const rangeEnd = normalizeDate(end);
  const normalizedEventStart = normalizeDate(eventStart);
  const normalizedEventEnd = normalizeDate(eventEnd);

  return (
    (normalizedEventStart >= rangeStart && normalizedEventStart <= rangeEnd) ||
    (normalizedEventEnd >= rangeStart && normalizedEventEnd <= rangeEnd) ||
    (normalizedEventStart <= rangeStart && normalizedEventEnd >= rangeEnd)
  );
};

// This empty export makes the file a module
export {};