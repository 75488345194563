import React from 'react';
import { Button } from "./ui/Button";
import { Label } from "./ui/Label";
import { MapPinIcon, GlobeIcon } from "lucide-react";
import { Event } from '../types/Event';

interface EventTypeSelectionProps {
  event: Partial<Event>;
  onChange: (field: string, value: any) => void;
}

export const EventTypeSelection: React.FC<EventTypeSelectionProps> = ({ event, onChange }) => {
  return (
    <div className="space-y-2">
      <Label>Event Type</Label>
      <div className="flex items-center space-x-2">
        <Button
          type="button"
          variant={!event.isOnline ? "default" : "outline"}
          onClick={() => onChange('isOnline', false)}
          className={!event.isOnline ? "bg-purple-600 text-white" : "border-purple-600 text-purple-600"}
        >
          <MapPinIcon className="w-4 h-4 mr-2" />
          In-person
        </Button>
        <Button
          type="button"
          variant={event.isOnline ? "default" : "outline"}
          onClick={() => onChange('isOnline', true)}
          className={event.isOnline ? "bg-purple-600 text-white" : "border-purple-600 text-purple-600"}
        >
          <GlobeIcon className="w-4 h-4 mr-2" />
          Online
        </Button>
      </div>
    </div>
  );
};