import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../config/firebase';
import defaultEventImage from '../assets/images/default-event-image.png';

export const getImageUrl = async (imagePath: string): Promise<string> => {
  if (!imagePath) {
    console.log('No image path provided, using default image');
    return defaultEventImage;
  }

  try {
    const imageRef = ref(storage, imagePath);
    return await getDownloadURL(imageRef);
  } catch (error) {
    console.error("Error fetching image:", error);
    console.log('Using default image due to error');
    return defaultEventImage;
  }
};