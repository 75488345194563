import React from 'react';

interface AlertProps {
  variant?: 'default' | 'destructive';
  className?: string;
  children: React.ReactNode;
}

export const Alert: React.FC<AlertProps> = ({ 
  variant = 'default', 
  className = '', 
  children 
}) => {
  const baseClasses = "rounded-lg border p-4";
  const variantClasses = variant === 'destructive' 
    ? 'bg-red-50 border-red-200 text-red-800' 
    : 'bg-blue-50 border-blue-200 text-blue-800';

  return (
    <div
      className={`${baseClasses} ${variantClasses} ${className}`}
      role="alert"
    >
      {children}
    </div>
  );
};

export const AlertTitle: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <h3 className="font-medium mb-1">{children}</h3>
);

export const AlertDescription: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="text-sm">{children}</div>
);
