import React from 'react';

interface ScrollAreaProps extends React.HTMLAttributes<HTMLDivElement> {
  maxHeight?: string;
}

export const ScrollArea: React.FC<ScrollAreaProps> = ({ className = '', maxHeight = '400px', children, ...props }) => {
  return (
    <div className={`overflow-auto ${className}`} style={{ maxHeight }} {...props}>
      {children}
    </div>
  );
};