import React from 'react';
import { Link } from 'react-router-dom';
import { CalendarIcon } from '../../utils/icons';

export const SimpleHeader: React.FC = () => (
  <header className="bg-white shadow-sm">
    <div className="container mx-auto px-4 py-4 flex justify-between items-center">
      <Link to="/" className="flex items-center space-x-2">
        <CalendarIcon className="h-8 w-8 text-purple-500" />
        <span className="text-2xl font-bold text-purple-500">EventAtEase</span>
      </Link>
    </div>
  </header>
);
