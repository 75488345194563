import React from 'react';

interface AvatarProps {
  src?: string;
  alt?: string;
  fallback?: string;
  className?: string;
  children?: React.ReactNode;
}

export const Avatar: React.FC<AvatarProps> = ({ src, alt, fallback, className, children }) => {
  return (
    <div className={`relative inline-block ${className}`}>
      {children ? children : (
        src ? (
          <img src={src} alt={alt} className="w-full h-full object-cover rounded-full" />
        ) : (
          <div className="w-full h-full flex items-center justify-center bg-gray-200 rounded-full text-gray-600 font-semibold">
            {fallback || alt?.charAt(0) || '?'}
          </div>
        )
      )}
    </div>
  );
};

export const AvatarImage: React.FC<React.ImgHTMLAttributes<HTMLImageElement>> = (props) => {
  return <img {...props} className={`w-full h-full object-cover rounded-full ${props.className || ''}`} />;
};

export const AvatarFallback: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => {
  return (
    <div {...props} className={`w-full h-full flex items-center justify-center bg-gray-200 rounded-full text-gray-600 font-semibold ${props.className || ''}`}>
      {children}
    </div>
  );
};
