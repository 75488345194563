import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { LandingHeader } from './headers/LandingHeader';
import { AuthenticatedHeader } from './headers/AuthenticatedHeader';
import { SimpleHeader } from './headers/SimpleHeader';

export const HeaderManager: React.FC = () => {
  const { user, customUser } = useAuth();
  const location = useLocation();

  const renderHeader = () => {
    // Always show LandingHeader for the landing page and specific routes
    if (['/features', '/how-it-works', '/pricing', '/contact', '/'].includes(location.pathname)) {
      return <LandingHeader />;
    } else if (user && !['/login', '/signup', '/first-signin'].includes(location.pathname)) {
      const userProfile = {
        id: user.uid,
        name: user.displayName || 'User',
        email: user.email || '',
        profilePicture: user.photoURL || '',
        isOrganizer: customUser?.isOrganizer || false,
      };
      return <AuthenticatedHeader user={userProfile} />;
    } else {
      return <SimpleHeader />;
    }
  };

  return renderHeader();
};
