import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../components/ui/Button';
import { Card, CardContent } from '../components/ui/Card';
import { CalendarIcon, MapPinIcon, CreditCardIcon, UsersIcon, BookOpenIcon, CheckCircleIcon, ArrowRightIcon } from 'lucide-react';

const HowItWorksPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState('organizers');

  const steps = {
    organizers: [
      { icon: CalendarIcon, title: "Create Your Event", description: "Set event details, pricing, and capacity with our user-friendly interface." },
      { icon: MapPinIcon, title: "Add Location & Schedule", description: "Specify the venue and sync with your calendar for seamless planning." },
      { icon: CreditCardIcon, title: "Set Up Payments", description: "Configure secure payment options for hassle-free ticket sales." },
      { icon: UsersIcon, title: "Manage Attendees", description: "Track registrations, send updates, and analyze event success." }
    ],
    attendees: [
      { icon: BookOpenIcon, title: "Discover Events", description: "Browse and find local events that match your interests and schedule." },
      { icon: CreditCardIcon, title: "Register Securely", description: "Sign up and pay for events using your preferred payment method." },
      { icon: MapPinIcon, title: "Prepare for the Event", description: "Get directions and add events to your personal calendar effortlessly." },
      { icon: CheckCircleIcon, title: "Enjoy & Provide Feedback", description: "Attend the event and easily share your experience afterwards." }
    ]
  };

  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-1">
        {/* Hero Section */}
        <section className="w-full py-20 md:py-32 bg-purple-100 text-[#3498db]">
          <div className="container px-4 md:px-6 mx-auto text-center">
            <h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl md:text-6xl mb-6">
              How EventAtEase Works
            </h1>
            <p className="mx-auto max-w-[700px] text-xl mb-10 text-gray-600">
              Simplifying event management for organizers and attendees alike.
            </p>
          </div>
        </section>

        {/* How It Works Section */}
        <section className="w-full py-20 bg-white">
          <div className="container px-4 md:px-6 mx-auto text-center">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl md:text-5xl mb-12 text-[rgb(124,58,237)]">
              Your Journey with EventAtEase
            </h2>
            <div className="w-full max-w-4xl mx-auto">
              <div className="flex justify-center mb-8">
                <button
                  className={`px-6 py-3 text-lg font-semibold ${activeTab === 'organizers' ? 'bg-purple-500 text-white' : 'bg-white text-purple-500 border border-purple-500'} rounded-l-md transition-colors duration-300`}
                  onClick={() => setActiveTab('organizers')}
                >
                  For Organizers
                </button>
                <button
                  className={`px-6 py-3 text-lg font-semibold ${activeTab === 'attendees' ? 'bg-purple-500 text-white' : 'bg-white text-purple-500 border border-purple-500'} rounded-r-md transition-colors duration-300`}
                  onClick={() => setActiveTab('attendees')}
                >
                  For Attendees
                </button>
              </div>
              <div className="grid gap-8 md:grid-cols-2">
                {steps[activeTab as keyof typeof steps].map((step, index) => (
                  <Card key={index} className="bg-purple-50 shadow-lg hover:shadow-xl transition-all duration-300">
                    <CardContent className="p-6 flex flex-col items-center text-center">
                      <div className="w-16 h-16 rounded-full bg-purple-500 text-white flex items-center justify-center mb-4">
                        <step.icon className="w-8 h-8" />
                      </div>
                      <h3 className="text-xl font-semibold mb-2 text-[rgb(124,58,237)]">{step.title}</h3>
                      <p className="text-gray-600">{step.description}</p>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Benefits Section */}
        <section className="w-full py-20 bg-purple-100">
          <div className="container px-4 md:px-6 mx-auto text-center">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl md:text-5xl mb-12 text-[rgb(124,58,237)]">
              Benefits of Using EventAtEase
            </h2>
            <div className="grid gap-8 md:grid-cols-3">
              {[
                { title: "Time-Saving", description: "Streamlined processes for both organizers and attendees." },
                { title: "User-Friendly", description: "Intuitive interface for easy navigation and event management." },
                { title: "Secure Payments", description: "Safe and reliable transaction processing for peace of mind." },
                { title: "Increased Visibility", description: "Expand your reach and attract more attendees to your events." },
                { title: "Detailed Analytics", description: "Gain insights to improve your events and attendee experience." },
                { title: "24/7 Support", description: "Our team is always here to assist you with any questions or issues." },
              ].map((benefit, index) => (
                <Card key={index} className="bg-white shadow-md hover:shadow-lg transition-shadow duration-300">
                  <CardContent className="p-6 flex flex-col items-center text-center">
                    <h3 className="text-xl font-semibold mb-2 text-[rgb(124,58,237)]">{benefit.title}</h3>
                    <p className="text-gray-600">{benefit.description}</p>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="w-full py-20 bg-white">
          <div className="container px-4 md:px-6 mx-auto text-center">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl md:text-5xl mb-6 text-[rgb(124,58,237)]">
              Ready to Get Started?
            </h2>
            <p className="mx-auto max-w-[600px] text-xl mb-10 text-gray-600">
              Join EventAtEase today and experience the easiest way to manage and attend local events.
            </p>
            <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
              <Link to="/signup">
                <Button variant="primary" className="w-full sm:w-auto">
                  Sign Up Now <ArrowRightIcon className="ml-2 h-5 w-5" />
                </Button>
              </Link>
              <Link to="/contact">
                <Button variant="outline" className="w-full sm:w-auto">
                  Contact Us
                </Button>
              </Link>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default HowItWorksPage;
