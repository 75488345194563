import { useState, useEffect } from 'react';
import axios from 'axios';

interface UserCityState {
  city: string | null;
  loading: boolean;
  error: string | null;
}

export const useUserCity = (): UserCityState => {
  const [city, setCity] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCity = async () => {
      try {
        const cachedCity = localStorage.getItem('userCity');
        if (cachedCity) {
          setCity(cachedCity);
          setLoading(false);
          return;
        }

        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              const { latitude, longitude } = position.coords;
              console.log('Coordinates:', latitude, longitude);
              try {
                const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
                console.log('Geocoding API URL:', url);
                
                const response = await axios.get(url);
                
                console.log('Google Maps API response status:', response.status);
                console.log('Google Maps API response data:', JSON.stringify(response.data, null, 2));

                if (response.data.status === 'OK' && response.data.results.length > 0) {
                  const result = response.data.results[0];
                  let detectedCity = null;

                  for (const component of result.address_components) {
                    console.log('Checking component:', component);
                    if (component.types.includes('locality') || 
                        component.types.includes('administrative_area_level_2') || 
                        component.types.includes('administrative_area_level_1')) {
                      detectedCity = component.long_name;
                      console.log('Detected city:', detectedCity);
                      break;
                    }
                  }

                  if (detectedCity) {
                    setCity(detectedCity);
                    localStorage.setItem('userCity', detectedCity);
                  } else {
                    console.log('No city detected in address components');
                    // Fallback: use the formatted address
                    detectedCity = result.formatted_address.split(',')[0];
                    console.log('Fallback city:', detectedCity);
                    setCity(detectedCity);
                    localStorage.setItem('userCity', detectedCity);
                  }
                } else {
                  console.error('Geocoding API error:', response.data.status, response.data.error_message);
                  throw new Error(`Geocoding API error: ${response.data.status}`);
                }
              } catch (error) {
                console.error('Error in geocoding:', error);
                if (axios.isAxiosError(error)) {
                  console.error('Axios error:', error.response?.data);
                }
                throw new Error('Failed to determine city from coordinates');
              }
            },
            (err) => {
              console.error('Geolocation error:', err);
              throw new Error('Geolocation permission denied or unavailable');
            },
            { timeout: 10000, maximumAge: 0 }
          );
        } else {
          throw new Error('Geolocation is not supported by this browser');
        }
      } catch (error) {
        console.error('Error fetching city data:', error);
        if (error instanceof Error) {
          setError(error.message || 'Unable to determine your location. Please select a city manually.');
        } else {
          setError('An unexpected error occurred. Please select a city manually.');
        }
        setCity(null);
      } finally {
        setLoading(false);
      }
    };

    fetchCity();
  }, []);

  return { city, loading, error };
};