import { db } from '../config/firebase';
import { 
  doc, 
  getDoc, 
  updateDoc, 
  arrayUnion, 
  Timestamp 
} from 'firebase/firestore';
import { FirebaseError } from 'firebase/app';

interface User {
  id: string;
  name?: string;
  email: string;
  eventsCreated: string[];
  eventsRegistered: string[];
  displayName?: string; // Add this line
  // Add other user fields as needed
}

export const getUserDetails = async (userId: string): Promise<User | null> => {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      return { id: userDoc.id, ...userDoc.data() } as User;
    } else {
      return null;
    }
  } catch (error) {
    if (error instanceof FirebaseError) {
      console.error('Firebase Error:', error.code, error.message);
    } else {
      console.error('Error fetching user details:', error);
    }
    throw error;
  }
};

export const getUserName = async (userId: string): Promise<string> => {
  try {
    const user = await getUserDetails(userId);
    if (user) {
      return user.displayName || user.name || 'Unknown User';
    }
    return 'Unknown User';
  } catch (error) {
    console.error('Error fetching user name:', error);
    return 'Unknown User';
  }
};

export const addEventToUserRegistrations = async (userId: string, eventId: string): Promise<void> => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      eventsRegistered: arrayUnion(eventId),
      updatedAt: Timestamp.now()
    });
  } catch (error) {
    console.error('Error adding event to user registrations:', error);
    throw error;
  }
};
