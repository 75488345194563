import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AnimatedBackground from '../../components/AnimatedBackground';

export const SignupPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/login', { replace: true });
    }, 3000); // Redirect after 3 seconds

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <>
      <AnimatedBackground />
      <div className="auth-container flex flex-col items-center justify-center min-h-screen relative z-10">
        <div className="bg-white bg-opacity-80 p-8 rounded-lg shadow-md w-full max-w-md">
          <h2 className="text-2xl font-bold mb-6 text-center text-purple-800">Sign Up</h2>
          <p className="text-center text-gray-600">
            Redirecting to login page...
          </p>
        </div>
      </div>
    </>
  );
};