import React, { createContext, useContext, useState } from 'react';

interface SearchState {
  searchTerm: string;
  dateRange: { start: Date; end: Date } | undefined;
  selectedCategories: string[];
  selectedPrices: string[];
  selectedLocations: string[];
  showBookmarked: boolean;
  isLocationChanged: boolean; // Add this line
}

interface DialogState {
  isDateDialogOpen: boolean;
  isCategoryDialogOpen: boolean;
  isPriceDialogOpen: boolean;
  isLocationDialogOpen: boolean;
}

interface SearchContextType {
  state: SearchState;
  setState: React.Dispatch<React.SetStateAction<SearchState>>;
  dialogState: DialogState;
  setDialogState: React.Dispatch<React.SetStateAction<DialogState>>;
}

const SearchContext = createContext<SearchContextType | undefined>(undefined);

export const SearchProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, setState] = useState<SearchState>({
    searchTerm: '',
    dateRange: undefined,
    selectedCategories: [],
    selectedPrices: [],
    selectedLocations: [],
    showBookmarked: false,
    isLocationChanged: false, // Add this line
  });

  const [dialogState, setDialogState] = useState<DialogState>({
    isDateDialogOpen: false,
    isCategoryDialogOpen: false,
    isPriceDialogOpen: false,
    isLocationDialogOpen: false,
  });

  return (
    <SearchContext.Provider value={{ state, setState, dialogState, setDialogState }}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = () => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error('useSearchContext must be used within a SearchProvider');
  }
  return context;
};