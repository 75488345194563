import { useState, useEffect } from 'react';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../../../config/firebase';
import { AIEventCategory } from '../../../types/EventCategories';

export const useEventCategories = () => {
  const [categories, setCategories] = useState<AIEventCategory[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesRef = collection(db, 'aiEventCategories');
        const categoriesQuery = query(categoriesRef, orderBy('priority', 'asc'));
        const snapshot = await getDocs(categoriesQuery);
        
        setCategories(
          snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }) as AIEventCategory)
        );
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  return { categories, loading };
};
