import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../components/ui/Card";
import { Button } from "../../components/ui/Button";
import { Input } from "../../components/ui/Input";
import { Label } from "../../components/ui/Label";
import { RadioGroup, RadioGroupItem } from "../../components/ui/RadioGroup";
import { useToast } from "../../components/ui/use-toast";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../components/ui/Accordion";
import { Wallet, Trash2, Building } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { razorpayService } from '../../services/razorpayService';
import { User, isBankAccountDetails, isVPADetails, BankAccountDetails, VPADetails, FundAccount } from '../../types/User';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { httpsCallable } from 'firebase/functions';
import { getFunctions } from 'firebase/functions';
import { AlertDialog } from '../../components/ui/AlertDialog';

export const OrganizerAccountPage: React.FC = () => {
  const { user, updateCustomUser } = useAuth();
  const [userData, setUserData] = useState<User | null>(null);
  const [accountType, setAccountType] = useState<'bank_account' | 'vpa'>('bank_account');
  const [newAccount, setNewAccount] = useState<{
    name?: string;
    ifsc?: string;
    account_number?: string;
    address?: string;
  }>({});
  const { toast } = useToast();
  const [deletingId, setDeletingId] = useState<string | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [accountToDelete, setAccountToDelete] = useState<string | null>(null);
  const [processingPayoutId, setProcessingPayoutId] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user?.uid) {
        try {
          const userDocRef = doc(db, 'users', user.uid);
          const userDocSnap = await getDoc(userDocRef);
          
          if (userDocSnap.exists()) {
            const fetchedData = userDocSnap.data();
            setUserData({
              ...user,
              razorpayContactCreated: fetchedData.razorpayContactCreated || false,
              razorpayContactId: fetchedData.razorpayContactId || '',
              fundAccounts: fetchedData.fundAccounts || [],
              maxFundAccounts: fetchedData.maxFundAccounts || 3,
            } as User);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          toast({
            title: "Error",
            description: "Failed to load user data",
            variant: "destructive",
          });
        }
      }
    };

    fetchUserData();
  }, [user]);

  const handleAddAccount = async () => {
    console.log('Starting handleAddAccount function');
    console.log('Current user:', userData);
    console.log('Account type:', accountType);
    console.log('New account details:', newAccount);

    if (!userData) {
      toast({
        title: "Error",
        description: "User data not loaded",
        variant: "destructive",
      });
      return;
    }

    try {
      if (!userData?.razorpayContactId) {
        console.log('Creating Razorpay contact...');
        const createContact = httpsCallable(getFunctions(), 'createRazorpayContact');
        const result = await createContact();
        console.log('Contact creation result:', result.data);

        const contactId = (result.data as any).contactId;
        if (!contactId) {
          throw new Error('Failed to create contact: No contact ID received');
        }

        await updateCustomUser({
          razorpayContactCreated: true,
          razorpayContactId: contactId
        });

        setUserData(prev => prev ? {
          ...prev,
          razorpayContactCreated: true,
          razorpayContactId: contactId
        } : null);
      }

      if (!userData?.razorpayContactId) {
        throw new Error('Contact ID not available');
      }

      if (userData.fundAccounts?.length >= (userData.maxFundAccounts || 3)) {
        console.log('Error: Maximum fund accounts limit reached');
        toast({
          title: "Maximum limit reached",
          description: "You can only have up to 3 fund accounts.",
          variant: "destructive",
        });
        return;
      }
      console.log('Current fund accounts count:', userData.fundAccounts?.length || 0);

      if (accountType === 'bank_account') {
        console.log('Validating bank account details');
        if (!newAccount.name || !newAccount.ifsc || !newAccount.account_number) {
          console.log('Missing bank account details:', {
            name: !!newAccount.name,
            ifsc: !!newAccount.ifsc,
            account_number: !!newAccount.account_number
          });
          toast({
            title: "Missing Information",
            description: "Please fill in all bank account details.",
            variant: "destructive",
          });
          return;
        }
      } else if (!newAccount.address) {
        console.log('Missing UPI address');
        toast({
          title: "Missing Information",
          description: "Please enter your UPI address.",
          variant: "destructive",
        });
        return;
      }

      const details: BankAccountDetails | VPADetails = accountType === 'bank_account' 
        ? {
            name: newAccount.name!,
            ifsc: newAccount.ifsc!,
            account_number: newAccount.account_number!
          }
        : {
            address: newAccount.address!
          };
      console.log('Created typed details object:', details);

      const accountData = {
        accountType,
        details,
        contactId: userData.razorpayContactId
      };
      console.log('Sending account data to service:', accountData);

      const result = await razorpayService.createFundAccount(accountData);
      console.log('Received result from service:', result);
      
      if (!result?.fundAccountId) {
        console.error('Invalid result from service:', result);
        throw new Error('Failed to create fund account: No fund account ID received');
      }

      const newFundAccount: FundAccount = {
        id: result.fundAccountId,
        type: accountType,
        active: true,
        details
      };
      console.log('Created new fund account object:', newFundAccount);

      const updatedFundAccounts: FundAccount[] = [
        ...(userData?.fundAccounts || []),
        newFundAccount
      ];
      console.log('Updated fund accounts array:', updatedFundAccounts);

      await updateCustomUser({ fundAccounts: updatedFundAccounts });
      console.log('Firestore update successful');
      
      setUserData(prevData => {
        if (!prevData) return null;
        return {
          ...prevData,
          fundAccounts: updatedFundAccounts
        };
      });

      setNewAccount({});
      setAccountType('bank_account');
      
      toast({
        title: "Success",
        description: "Fund account added successfully",
      });
    } catch (error: any) {
      console.error('Fund Account Creation Error:', error);
      toast({
        title: "Error",
        description: error.message || "Failed to add fund account",
        variant: "destructive",
      });
    }
  };

  const handleDeleteAccount = async (id: string) => {
    if (!userData || !userData.fundAccounts) {
      toast({
        title: "Error",
        description: "User data not loaded",
        variant: "destructive",
      });
      return;
    }

    setDeletingId(id);
    
    try {
      console.log('Deactivating fund account:', id);
      
      // Call Razorpay service to deactivate the account
      await razorpayService.deactivateFundAccount(id);
      
      // Remove the deactivated account from the list
      const updatedFundAccounts = userData.fundAccounts.filter(account => account.id !== id);

      // Update Firestore
      await updateCustomUser({ fundAccounts: updatedFundAccounts });
      
      // Update local state immediately
      setUserData(prevData => {
        if (!prevData) return null;
        return {
          ...prevData,
          fundAccounts: updatedFundAccounts
        };
      });
      
      toast({
        title: "Success",
        description: "Fund account removed successfully",
      });
    } catch (error: any) {
      console.error('Fund Account Deletion Error:', error);
      toast({
        title: "Error",
        description: error.message || "Failed to remove fund account",
        variant: "destructive",
      });
    } finally {
      setDeletingId(null);
      setAccountToDelete(null);
    }
  };

  const handlePayout = async (fundAccountId: string) => {
    setProcessingPayoutId(fundAccountId);
    
    try {
      console.log('Initiating payout for fund account:', fundAccountId);
      const result = await razorpayService.createPayout(fundAccountId);
      
      console.log('Payout result:', result);
      
      toast({
        title: "Payout Initiated",
        description: `Status: ${result.status}. ${result.message}`,
      });
    } catch (error: any) {
      console.error('Payout Error:', error);
      
      toast({
        title: "Payout Failed",
        description: error.message || "Failed to process payout",
        variant: "destructive",
      });
    } finally {
      setProcessingPayoutId(null);
    }
  };

  const handleDeleteClick = (accountId: string) => {
    setAccountToDelete(accountId);
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (accountToDelete) {
      await handleDeleteAccount(accountToDelete);
    }
  };

  return (
    <div className="space-y-6">
      <Card className="w-full max-w-2xl mx-auto">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-purple-800">Fund Accounts</CardTitle>
          <CardDescription>Manage your fund accounts for payouts</CardDescription>
        </CardHeader>
        <CardContent>
          <Accordion type="single" collapsible className="w-full">
            {userData?.fundAccounts?.map((account) => (
              <AccordionItem key={account.id} value={account.id}>
                <AccordionTrigger>
                  <div className="flex items-center space-x-2">
                    {account.type === 'bank_account' ? (
                      <Building className="h-5 w-5 text-purple-600" />
                    ) : (
                      <Wallet className="h-5 w-5 text-purple-600" />
                    )}
                    <span>
                      {account.type === 'bank_account' && isBankAccountDetails(account.details)
                        ? `${account.details.name} (Bank)`
                        : isVPADetails(account.details)
                        ? `${account.details.address} (UPI)`
                        : 'Unknown Account Type'}
                    </span>
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  <div className="space-y-2">
                    {account.type === 'bank_account' && isBankAccountDetails(account.details) ? (
                      <>
                        <p><strong>Name:</strong> {account.details.name}</p>
                        <p><strong>IFSC:</strong> {account.details.ifsc}</p>
                        <p><strong>Account Number:</strong> {account.details.account_number}</p>
                      </>
                    ) : isVPADetails(account.details) ? (
                      <p><strong>UPI Address:</strong> {account.details.address}</p>
                    ) : null}
                    <Button 
                      variant="destructive" 
                      size="sm"
                      onClick={() => handleDeleteClick(account.id)}
                      disabled={deletingId === account.id}
                    >
                      {deletingId === account.id ? (
                        <div className="flex items-center">
                          <div className="animate-spin mr-2">•</div>
                          Removing...
                        </div>
                      ) : (
                        <>
                          <Trash2 className="h-4 w-4 mr-2" />
                          Delete Account
                        </>
                      )}
                    </Button>
                  </div>
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </CardContent>
        {(!userData?.fundAccounts || userData.fundAccounts.length < (userData.maxFundAccounts || 3)) && (
          <CardFooter>
            <div className="w-full space-y-4">
              <RadioGroup 
                value={accountType} 
                onChange={(value: string) => setAccountType(value as 'bank_account' | 'vpa')}
              >
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="bank_account" />
                  <Label>Bank Account</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="vpa" />
                  <Label>UPI</Label>
                </div>
              </RadioGroup>

              {accountType === 'bank_account' ? (
                <div className="space-y-2">
                  <Input
                    placeholder="Account Holder Name"
                    value={newAccount.name || ''}
                    onChange={(e) => setNewAccount({ ...newAccount, name: e.target.value })}
                  />
                  <Input
                    placeholder="IFSC Code"
                    value={newAccount.ifsc || ''}
                    onChange={(e) => setNewAccount({ ...newAccount, ifsc: e.target.value })}
                  />
                  <Input
                    placeholder="Account Number"
                    value={newAccount.account_number || ''}
                    onChange={(e) => setNewAccount({ ...newAccount, account_number: e.target.value })}
                  />
                </div>
              ) : (
                <Input
                  placeholder="UPI Address"
                  value={newAccount.address || ''}
                  onChange={(e) => setNewAccount({ ...newAccount, address: e.target.value })}
                />
              )}

              <Button 
                onClick={handleAddAccount} 
                className="w-full"
                disabled={
                  accountType === 'bank_account' 
                    ? !newAccount.name || !newAccount.ifsc || !newAccount.account_number
                    : !newAccount.address
                }
              >
                Add Fund Account
              </Button>
            </div>
          </CardFooter>
        )}
      </Card>

      <Card className="w-full max-w-2xl mx-auto">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-purple-800">Payouts</CardTitle>
          <CardDescription>Request payouts to your registered fund accounts</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {userData?.fundAccounts && userData.fundAccounts.length > 0 ? (
              userData.fundAccounts.map((account) => (
                <div 
                  key={account.id} 
                  className="flex items-center justify-between p-4 border rounded-lg hover:bg-gray-50"
                >
                  <div className="flex items-center space-x-4">
                    {account.type === 'bank_account' ? (
                      <Building className="h-5 w-5 text-purple-600" />
                    ) : (
                      <Wallet className="h-5 w-5 text-purple-600" />
                    )}
                    <div>
                      <p className="font-medium">
                        {account.type === 'bank_account' && isBankAccountDetails(account.details)
                          ? `${account.details.name} (Bank)`
                          : isVPADetails(account.details)
                          ? `${account.details.address} (UPI)`
                          : 'Unknown Account Type'}
                      </p>
                      <p className="text-sm text-gray-500">
                        {account.type === 'bank_account' && isBankAccountDetails(account.details)
                          ? `IFSC: ${account.details.ifsc}`
                          : 'UPI Payment'}
                      </p>
                    </div>
                  </div>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => handlePayout(account.id)}
                    disabled={processingPayoutId === account.id}
                    className="ml-4"
                  >
                    {processingPayoutId === account.id ? (
                      <div className="flex items-center">
                        <div className="animate-spin mr-2">•</div>
                        Processing...
                      </div>
                    ) : (
                      <>
                        <Wallet className="h-4 w-4 mr-2" />
                        Request Payout (₹3)
                      </>
                    )}
                  </Button>
                </div>
              ))
            ) : (
              <div className="text-center py-6 text-gray-500">
                No fund accounts found. Add a fund account above to enable payouts.
              </div>
            )}
          </div>
        </CardContent>
        <CardFooter>
          <p className="text-sm text-gray-500 w-full text-center">
            Payouts are processed within 24-48 hours. Contact support for any issues.
          </p>
        </CardFooter>
      </Card>

      <AlertDialog
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={handleConfirmDelete}
        title="Delete Fund Account"
        description="Are you sure you want to delete this fund account? This action cannot be undone."
        confirmText="Delete"
        cancelText="Cancel"
      />
    </div>
  );
};
